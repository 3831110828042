import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { MdAccessTime } from 'react-icons/md';
import * as yup from 'yup';
import { Device } from '../../../API';
import errorMessages from '../../../config/errorMessages';
import { useUpdateDeviceShadow } from '../../../hooks/mutations';
import { useDeviceShadow } from '../../../hooks/queries';
import { DeviceShadowAliases } from '../../../screens/MeterId/types';
import Alert from '../../Alert/Alert';
import FormControl from '../../Forms/FormControl/FormControl';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import Select from '../../Forms/Select/Select';
import Range from '../../Forms/_Range/Range';
import MenuList from '../../MenuList/MenuList';
import Spinner from '../../Spinner/Spinner';
import TimePickerCard, {
  OnOkBtnClickTimePicker,
} from '../../TimePickerCard/TimePickerCard';
import Typography from '../../Typography/Typography';
import WithPadding from '../../WithPadding/WithPadding';
import Modal, {
  ModalContext,
  ModalContextI,
  ModalOpeningProps,
  ModalStep,
} from '../Modal';
import { DeviceShadowData, DeviceShadowRaw } from './classes/RangeAsset';
import ATPR from './classes/atPr';
import BPR from './classes/bPr';
import GCO2 from './classes/gCO2';
import GN2 from './classes/gN2';
import GSG from './classes/gsG';
import PRHL from './classes/prHL';
import PRLL from './classes/prLL';
import TEMPHL from './classes/tempHL';
import TEMPLL from './classes/tempLL';
import UFHL from './classes/ufHL';
import UFLL from './classes/ufLL';
import { GasDayContainer } from './style';

type Props = {
  device: Device;
};

type DeviceShadowDataBrighLyncOnly = Pick<
  DeviceShadowData,
  'wk1' | 'wk2' | 'wk3' | 'wk4' | 'tT'
>;

type BrightLyncData = {
  label: string;
  alias: keyof DeviceShadowDataBrighLyncOnly;
  value?: string;
};

const stringTypeKeys = ['tT', 'wk1', 'wk2', 'wk3', 'wk4', 'MID'];
const deviceShadowDataKeys: DeviceShadowAliases[] = [
  'cVD',
  'cVUt',
  'cV',
  'uVD',
  'uVUt',
  'uV',
  'atPr',
  'bPr',
  'tempHL',
  'tempLL',
  'prHL',
  'prLL',
  'uFHL',
  'uFLL',
  'MID',
  'gCO2',
  'gN2',
  'gsG',
  'tT',
  'wk1',
  'wk2',
  'wk3',
  'wk4',
];

const schema = yup.object().shape({
  tempHL: yup
    .number()
    .moreThan(
      yup.ref('tempLL'),
      errorMessages.moreThanField('Temperature Low Limit')
    ),
  tempLL: yup
    .number()
    .lessThan(
      yup.ref('tempHL'),
      errorMessages.lessThanField('Temperature High Limit')
    ),
  prHL: yup
    .number()
    .moreThan(
      yup.ref('prLL'),
      errorMessages.moreThanField('Pressure Low Limit')
    ),
  prLL: yup
    .number()
    .lessThan(
      yup.ref('prHL'),
      errorMessages.lessThanField('Pressure High Limit')
    ),
  uFHL: yup
    .number()
    .moreThan(yup.ref('uFLL'), errorMessages.moreThanField('Flow Low Limit')),
  uFLL: yup
    .number()
    .lessThan(yup.ref('uFHL'), errorMessages.lessThanField('Flow High Limit')),
  MID: yup
    .string()
    .required(errorMessages.required)
    .max(16, errorMessages.maxCharacters(16)),
});

const AssetConfigurationModal: React.FC<ModalOpeningProps & Props> = ({
  device,
  closeModalFunc,
  open,
  ...props
}) => {
  const deviceShadow = useDeviceShadow({
    variables: {
      deviceId: device.id,
      region: device.group?.utility.company?.region!,
      prettify: false,
    },
    additionalOptions: {
      enabled: !!device,
    },
  });

  const updateDeviceShadowMutation = useUpdateDeviceShadow();
  const contextValue = useRef<ModalContextI | null>(null);

  const [gdstComponents, setGdstComponents] = useState<[number, number, number]>([0, 0, 0]);
  const [gdstMeridian, setGdstMeridian] = useState<"AM" | "PM">("AM");

  const [timePickersOn, setTimePickersOn] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState(
    'Configuration may take up to 24 hours to get updated and reflected'
  );

  const {
    formState: { errors },
    register,
    setValue,
    getValues,
    setError,
    trigger,
  } = useForm<DeviceShadowData>({
    resolver: yupResolver(schema),
  });
  const data = useMemo(
    () =>
      JSON.parse(deviceShadow.data?.getDeviceShadow || '{}') as DeviceShadowRaw,
    [deviceShadow.data?.getDeviceShadow]
  );

  const valuesFromShadow = data?.state?.reported;

  const volumeData = {
    imperial: {
      data: [
        {
          value: 97,
          label: 0.1,
        },
        {
          value: 3,
          label: 1,
        },
        {
          value: 4,
          label: 10,
        },
        {
          value: 5,
          label: 100,
        },
        {
          value: 6,
          label: 1000,
        },
        {
          value: 14,
          label: 10000,
        },
      ],
      unit: 'FT³',
    },
    metric: {
      data: [
        {
          value: 98,
          label: 0.0001,
        },
        {
          value: 99,
          label: 0.01,
        },
        {
          value: 9,
          label: 0.1,
        },
        {
          value: 10,
          label: 1,
        },
        {
          value: 11,
          label: 10,
        },
        {
          value: 12,
          label: 100,
        },
      ],
      unit: 'M³',
    },
  };

  useEffect(() => {
    if (valuesFromShadow) {
      const entries = Object.entries(valuesFromShadow);

      entries.forEach(([key, value]) => {
        if (!stringTypeKeys.includes(key)) {
          setValue(key as any, value.toString());
        }
      });

      const [gdstHour, gdstMinute, gdstSecond] = valuesFromShadow.GDST.split(' ').map(Number);
      const gdstMeridian = (gdstHour < 12 || gdstHour === 24) ? "AM" : "PM";
      const gdstHourAdjusted = gdstHour % 12 || 12;

      setGdstComponents([gdstHourAdjusted, gdstMinute, gdstSecond]);
      setGdstMeridian(gdstMeridian);
    }
  }, [valuesFromShadow]);

  const alarmParameters = useMemo(
    () =>
      valuesFromShadow
        ? [
            new TEMPHL({ deviceShadow: data!, register }),
            new TEMPLL({ deviceShadow: data!, register }),
            new PRHL({ deviceShadow: data!, register }),
            new PRLL({ deviceShadow: data!, register }),
            new UFHL({ deviceShadow: data!, register }),
            new UFLL({ deviceShadow: data!, register }),
          ]
        : [],
    [data, register, valuesFromShadow]
  );

  const firstParameters = valuesFromShadow
    ? [
        new ATPR({ deviceShadow: data!, register }),
        new BPR({ deviceShadow: data!, register }),
      ]
    : [];

  const submitForm = useCallback(
    async (close: boolean) => {
      const formatTime = (time: string | undefined) => {
        if (!time) return time;

        const [hour, minute, second, meridien] = time.split(/:|\s/);
        let newHour: number = parseInt(hour);
        if (newHour === 12) {
          newHour = 0;
        }

        newHour = meridien === 'PM' ? newHour + 12 : newHour;

        let newHourWithZero = newHour < 10 ? '0' + newHour : newHour;
        return `${newHourWithZero} ${minute} ${second}`;
      };

      const fixTypes = (values: DeviceShadowData) => {
        const fixedTypes = Object.entries(values).reduce(
          (acc, [key, value]) => {
            if (stringTypeKeys.includes(key)) {
              return Object.assign(acc, {
                [key]: value,
              });
            }

            return Object.assign(acc, {
              [key]: Number(value),
            });
          },
          {} as DeviceShadowData
        );

        return fixedTypes;
      };

      const fixCustomerID = (deviceShadowData: DeviceShadowData) => {
        const data = deviceShadowData;

        if (deviceShadowData.MID) {
          const customerID = deviceShadowData.MID;
          const padded = customerID.padStart(16, ' ');
          const onlySixteenLength = padded.slice(0, 16);

          data.MID = onlySixteenLength;
        }

        return data;
      };

      const fixDecimalPoints = (data: DeviceShadowData) => {
        const allParameters = [...alarmParameters, ...firstParameters];

        const entries = Object.entries(data);

        const fixed = entries.map(([key, value]) => {
          const parameter = allParameters.find(
            (parameter) => parameter.deviceShadowAlias === key
          );

          if (!parameter) return [key, value];

          const fixedValue = parameter.correctValue(Number(value));

          return [key, fixedValue];
        });

        const fixedObject = Object.fromEntries(fixed);

        return fixedObject;
      };

      const formatValues = (values: DeviceShadowData) => {
        const formattedTimes = Object.assign(values, {
          wk1: formatTime(values.wk1),
          wk2: formatTime(values.wk2),
          wk3: formatTime(values.wk3),
          wk4: formatTime(values.wk4),
          tT: formatTime(values.tT),
        });

        const onlyModifiedValues = Object.fromEntries(
          Object.entries(formattedTimes).filter(([key, value]) => {
            return (
              valuesFromShadow[key as keyof DeviceShadowData] != value &&
              deviceShadowDataKeys.includes(key as any)
            );
          })
        ) as DeviceShadowData;

        const typesFixed = fixTypes(onlyModifiedValues);

        const fixMID = fixCustomerID(typesFixed);

        const decimalPointsFixed = fixDecimalPoints(fixMID);

        return decimalPointsFixed;
      };

      const error = await trigger();

      if (!error) return;

      const values = getValues();

      const formattedValues = formatValues(values);

      const ancientShadow = data!;

      const newShadow = { ...ancientShadow };

      newShadow.state.desired = formattedValues as any;

      const stringifiedShadow = JSON.stringify(newShadow);

      try {
        await updateDeviceShadowMutation.mutateAsync({
          deviceId: device.id,
          region: device.group?.utility.company?.region!,
          payload: stringifiedShadow,
        });
      } catch (e) {}

      if (close) closeModalFunc(false);
    },
    [
      closeModalFunc,
      data,
      device,
      getValues,
      updateDeviceShadowMutation,
      valuesFromShadow,
      setError,
    ]
  );

  const onSaveAndContinueBtnClick = useCallback(async () => {
    const hasNoErrors = await trigger();

    if (!hasNoErrors) return;

    contextValue.current?.increaseStep();
  }, [trigger]);

  const onSaveAndCloseBtnClick = useCallback(() => {
    submitForm(true);
  }, [submitForm]);

  const getVolumeMetricData = () => {
    const volume = valuesFromShadow.cVUt;

    if (!volume) return;

    const volumeMetricIds = volumeData.metric.data.map(
      (metric) => metric.value
    );
    const imperialMetricIds = volumeData.imperial.data.map(
      (imperial) => imperial.value
    );

    if (volumeMetricIds.includes(volume)) {
      return volumeData.metric;
    }

    if (imperialMetricIds.includes(volume)) {
      return volumeData.imperial;
    }
  };

  const otherParameters = valuesFromShadow
    ? [
        new GCO2({ deviceShadow: data!, register }),
        new GN2({ deviceShadow: data!, register }),
        new GSG({ deviceShadow: data!, register }),
      ]
    : [];

  if (deviceShadow.isIdle) return null;

  const brightLyncData = valuesFromShadow ? getBrightLyncData() : [];

  const onOkBtnClick: OnOkBtnClickTimePicker = ({ date, name }) => {
    const dateHour = date.getHours();

    let meridiem = dateHour >= 12 ? 'PM' : 'AM';

    if (dateHour > 12) {
      date.setHours(dateHour - 12);
    }

    if (dateHour === 0) {
      date.setHours(12);
    }

    const formattedDate = moment(date).format('HH:mm:ss') + ' ' + meridiem;

    setValue(name as keyof DeviceShadowData, formattedDate);
    closeTimePicker(name);
  };

  const closeTimePicker = (name: string) => {
    setTimePickersOn(
      timePickersOn.filter((timePickerName) => timePickerName !== name)
    );
  };

  const onTimeInputTxtInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const inputName = e.currentTarget.name;

    const timePickersOnCopy = [...timePickersOn];

    if (timePickersOnCopy.includes(inputName)) {
      closeTimePicker(inputName);
    } else {
      setTimePickersOn([...timePickersOnCopy, inputName]);
    }
  };

  function getBrightLyncData() {
    const formatHour = (str?: string) => {
      if (!str) return;

      const splitted = str.split(' ');
      const [hour, minute, second] = splitted;

      let newHour = Number(hour) > 12 ? Number(hour) - 12 : Number(hour);

      if (newHour === 0) newHour = 12;

      let newHourWithZero = newHour < 10 ? '0' + newHour : newHour;

      let meridien = Number(hour) >= 12 ? 'PM' : 'AM';
      return `${newHourWithZero}:${minute}:${second} ${meridien}`;
    };
    const baseData: BrightLyncData[] = [
      {
        alias: 'tT',
        label: 'Log Report Time',
      },
      {
        alias: 'wk1',
        label: 'Wake Up Time 1',
      },
      {
        alias: 'wk2',
        label: 'Wake Up Time 2',
      },
      {
        alias: 'wk3',
        label: 'Wake Up Time 3',
      },
      {
        alias: 'wk4',
        label: 'Wake Up Time 4',
      },
    ];

    const data = baseData.map(({ alias, label }, index) => {
      const value = formatHour(valuesFromShadow![alias]);

      return {
        alias,
        label,
        value,
      };
    });

    return data;
  }

  return (
    <Modal
      title="Asset Configuration"
      open={open}
      closeModalFunc={closeModalFunc}
      buttonLoading={updateDeviceShadowMutation.isLoading}
      totalStep={2}
      currentStep={1}
      plusContent={
        <div
          style={{
            width: 175,
            marginRight: 32,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <ModalContext.Consumer>
            {(value) => (
              <MenuList
                items={[
                  {
                    text: 'AdEM',
                    onClick: (e) => {
                      e.preventDefault();
                      value?.setCurrentStep(1);
                    },
                    active: value?.currentStep === 1,
                  },
                  {
                    text: 'BrightLync',
                    onClick: (e) => {
                      e.preventDefault();
                      value?.setCurrentStep(2);
                    },
                    active: value?.currentStep === 2,
                  },
                ]}
              />
            )}
          </ModalContext.Consumer>
          <div>
            <Alert variant="danger">
              <AiOutlineInfoCircle size={14} /> {errorMsg}
            </Alert>
          </div>
        </div>
      }
    >
      {deviceShadow.isLoading && <Spinner variant="primary" />}
      {!valuesFromShadow && !deviceShadow.isLoading && (
        <Alert variant="danger">No data found</Alert>
      )}
      {valuesFromShadow && !deviceShadow.isLoading && (
        <>
          <ModalContext.Consumer>
            {(value) => {
              contextValue.current = value;
              return (
                <ModalStep
                  step={1}
                  button={[
                    [
                      {
                        block: true,
                        onClick: onSaveAndCloseBtnClick,
                        loading: updateDeviceShadowMutation.isLoading,
                        variant: 'light-primary',
                        text: 'Save & Close',
                      },
                      {
                        block: true,
                        onClick: onSaveAndContinueBtnClick,
                        text: 'Save & Continue',
                      },
                    ],
                  ]}
                  title="Asset Configuration"
                >
                  {/* <FormGroup>
                    <FormControl
                      id="assetGroup"
                      label="Asset Group"
                      type={'text'}
                    />
                  </FormGroup> */}

                  <WithPadding title="Index Read Parameters">
                    <FormGroup>
                      <Select
                        register={register}
                        name="cVD"
                        defaultValue={valuesFromShadow.cVD}
                        label="Corrected Index Read Length"
                      >
                        {[8, 7, 6, 5].map((num, index) => (
                          <option key={num} value={index}>
                            {num} Digits
                          </option>
                        ))}
                      </Select>
                    </FormGroup>
                    <FormGroup>
                      <Select
                        register={register}
                        defaultValue={valuesFromShadow.cVUt}
                        name="cVUt"
                        label="Corrected Index Read Units"
                      >
                        {getVolumeMetricData()?.data!.map(
                          ({ value, label }) => (
                            <option key={label} value={value}>
                              {label} {getVolumeMetricData()?.unit}
                            </option>
                          )
                        )}
                      </Select>
                    </FormGroup>
                    <FormGroup>
                      <FormControl
                        register={register}
                        value={valuesFromShadow.cV}
                        name="cV"
                        label="Corrected Index Read Value"
                        min={0}
                        max={99999999}
                        type={'number'}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Select
                        register={register}
                        defaultValue={valuesFromShadow.uVD}
                        name="uVD"
                        label="Uncorrected Index Read Length"
                      >
                        {[8, 7, 6, 5].map((num, index) => (
                          <option key={num} value={index}>
                            {num} Digits
                          </option>
                        ))}
                      </Select>
                    </FormGroup>
                    <FormGroup>
                      <Select
                        register={register}
                        name="uVUt"
                        defaultValue={valuesFromShadow.uVUt}
                        label="Uncorrected Index Read Units"
                      >
                        {getVolumeMetricData()?.data!.map(
                          ({ value, label }) => (
                            <option key={label} value={value}>
                              {label} {getVolumeMetricData()?.unit}
                            </option>
                          )
                        )}
                      </Select>
                    </FormGroup>
                    <FormGroup>
                      <FormControl
                        register={register}
                        name="uV"
                        label="Uncorrected Index Read Value"
                        min={0}
                        max={99999999}
                        value={valuesFromShadow.uV || 0}
                        type={'number'}
                      />
                    </FormGroup>

                    {firstParameters.map((parameter) => (
                      <FormGroup key={parameter.deviceShadowAlias}>
                        <Range {...parameter.getRangeProps()} />
                      </FormGroup>
                    ))}
                  </WithPadding>

                  <WithPadding title="Alarm Parameters">
                    {alarmParameters.map((parameter) => {
                      return (
                        <FormGroup key={parameter.deviceShadowAlias}>
                          <Range
                            {...parameter.getRangeProps()}
                            error={errors[parameter.deviceShadowAlias]?.message}
                          />
                        </FormGroup>
                      )
                    })}
                  </WithPadding>

                  <WithPadding title="Other Parameters">
                    <FormGroup>
                      <FormControl
                        label="Customer ID"
                        register={register}
                        value={valuesFromShadow.MID}
                        name="MID"
                        type={'text'}
                        supportText="16 character alphanumeric"
                        error={errors.MID?.message}
                      />
                    </FormGroup>
                    {otherParameters.map((parameter) => (
                      <FormGroup key={parameter.deviceShadowAlias}>
                        <Range {...parameter.getRangeProps()} />
                      </FormGroup>
                    ))}
                  </WithPadding>
                </ModalStep>
              );
            }}
          </ModalContext.Consumer>
          <ModalStep
            step={2}
            title="Asset Configuration"
            buttonText="Save"
            onSubmitBtnClick={() => submitForm(true)}
            button={{ text: 'Save', onClick: () => submitForm(true) }}
          >
            <GasDayContainer>
              <Typography fontWeight={700} fontSize={14} lineHeight={'24px'}>
                Gas Day Start Time
              </Typography>
              <Typography fontWeight={400} fontSize={14} lineHeight={'32px'}>
                {`${gdstComponents[0].toString().padStart(2, "0")}:${gdstComponents[1].toString().padStart(2, "0")}:${gdstComponents[2].toString().padStart(2, "0")} ${gdstMeridian}`} Asset Local Time
              </Typography>
            </GasDayContainer>
            {brightLyncData.map(({ label, alias, value }, index) => {
              if (index === 0) {
                console.log({label, alias, value});
              }
              return (
                <>
                  <FormGroup>
                    <FormControl
                      inputStyle={{
                        cursor: 'pointer',
                      }}
                      register={register}
                      onClick={onTimeInputTxtInputClick}
                      label={label}
                      name={alias}
                      value={value}
                      rowReverse={true}
                      startIcon={<MdAccessTime />}
                      type="text"
                    />
                  </FormGroup>
                  {timePickersOn.includes(alias) && (
                    <TimePickerCard
                      originalValue={value ?? '09:00:00 AM'}
                      forceMeridian={index === 0}
                      forceMeridianValue={index === 0 ? gdstMeridian : undefined}
                      showMinuteInput={index === 0}
                      minMinute={index === 0 ? 7 : 0}
                      maxMinute={index === 0 ? 15 : 59}
                      minHour={index === 0 ? gdstComponents[0] : 1}
                      maxHour={index === 0 ? gdstComponents[0] : 12}
                      onCancelBtnClick={() => closeTimePicker(alias)}
                      name={alias}
                      onOkBtnClick={onOkBtnClick}
                    />
                  )}
                </>
              );
            })}
          </ModalStep>
        </>
      )}
    </Modal>
  );
};

export default AssetConfigurationModal;
