import RangeAsset, { ConstructorParams, DeviceShadowAliasesAcceptedByRangeAsset } from "./RangeAsset";


export default class GCO2 extends RangeAsset {


    protected label: string = 'Gas Mole % CO2';
    public deviceShadowAlias: DeviceShadowAliasesAcceptedByRangeAsset = 'gCO2';


    constructor({ deviceShadow, register }: ConstructorParams) {
        super({ deviceShadow, register });

        this.setMeterInfo();
    }

    setMeterInfo() {

        const isImperial = this.deviceShadow.state.reported.sxFT === 0 && this.deviceShadow.state.reported.sxFA === 0;

        if (isImperial) {
            this.min = 0;
            this.max = 15;
            this.unitType = 'imperial';
            this.meterUnit = 'all';
        } else {
            this.min = 0;
            this.max = 30;
            this.unitType = 'metric';
            this.meterUnit = 'all';
        }

    }

    isConfigurable(): boolean {
        if (this.deviceShadow.state.reported.sxFT === 0 && this.deviceShadow.state.reported.sxFA === 0) return true;
        if (this.deviceShadow.state.reported.sxFT === 0 && this.deviceShadow.state.reported.sxFA === 4) return true;

        return false;
    }



}