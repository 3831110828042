import RangeAsset, {
  ConstructorParams,
  DeviceShadowAliasesAcceptedByRangeAsset,
  RangeList,
} from './RangeAsset';

let moveDecimal = require('move-decimal-point');

export default class ATPR extends RangeAsset {
  private rangeList: RangeList[] = [
    {
      min: 10,
      max: 16,
      type: 'imperial',
    },
    {
      min: 70,
      max: 110,
      type: 'metric',
      meterUnit: 'kpa',
    },
    {
      min: 0.7,
      max: 1.1,
      type: 'metric',
      meterUnit: 'bar',
    },
  ];

  protected label: string = 'Atmospheric Pressure';
  public deviceShadowAlias: DeviceShadowAliasesAcceptedByRangeAsset = 'atPr';

  constructor({ deviceShadow, register }: ConstructorParams) {
    super({ deviceShadow, register });

    this.setMeterInfo();
  }

  setMeterInfo() {
    const deviceShadowAliasData = this.getDeviceShadowAliasValue() || 0;

    const chosenFromRangeList = this.rangeList.find((range) => {
      let isFound =
        deviceShadowAliasData >= range.min &&
        deviceShadowAliasData <= range.max;
      if (!isFound) {
        const min = Math.min(...this.rangeList.map((item) => item.min));
        return min >= range.min && min <= range.max;
      }
      return isFound;
    });

    if (chosenFromRangeList?.type === 'imperial') {
      this.step = 0.01;
    }

    if (
      chosenFromRangeList?.type === 'metric' &&
      chosenFromRangeList?.meterUnit === 'kpa'
    ) {
      this.step = 0.1;
    }

    this.unitType = chosenFromRangeList!.type;
    this.meterUnit =
      chosenFromRangeList?.type === 'imperial'
        ? 'all'
        : chosenFromRangeList!.meterUnit;
    this.min = chosenFromRangeList!.min;
    this.max = chosenFromRangeList!.max;
  }

  isConfigurable(): boolean {
    if (this.meterUnit === 'bar') return false;

    return this.deviceShadow.state.reported.prFT === 0;
  }

  public _correctValue(value: number): number {
    let corrected = value;

    console.log(corrected, 'corrected');

    if (this.meterUnit === 'kpa' && this.getUnitType() === 'metric') {
      corrected = moveDecimal(corrected, -1);
      // corrected = value / 10;
    }

    return corrected;
  }
}
