import React, { PropsWithChildren } from "react";
import { Container } from "./style";

type FlexProps = Pick<React.CSSProperties, "justifyContent" | "alignItems" | "marginBottom">;

export type RowProps = {
  spacing?: number;
};

type Props = {
  style?: React.CSSProperties;
} & FlexProps &
  RowProps;

const Row: React.FC<PropsWithChildren<Props>> = ({
  spacing = 0,
  children,
  style,
  ...props
}) => {
  return (
    <Container
      spacing={spacing}
      style={{
        display: "flex",
        width: `calc(100% + ${spacing}px)`,
        ...props,
        ...style,
      }}
    >
      {children}
    </Container>
  );
};

export default Row;
