import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AuthenticateUserQueryVariables } from '../../API';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Forms/Checkbox/Checkbox';
import FormControl from '../../components/Forms/FormControl/FormControl';
import FormGroup from '../../components/Forms/FormGroup/FormGroup';
import { useUnprotectedLayout } from '../../components/UnprotectedRouteLayout/UnprotectedRouteLayout';
import errorMessages from '../../config/errorMessages';
import { useAuth } from '../../contexts/Auth';
import { ForgotPasswordMsg, FormBottom } from './style';

const schema = yup.object().shape({
  email: yup
    .string()
    .email(errorMessages.email)
    .required(errorMessages.required),
  password: yup.string().required(errorMessages.required),
});

const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    setValue,
  } = useForm<AuthenticateUserQueryVariables>({
    resolver: yupResolver(schema),
  });

  const { signIn } = useAuth();
  const navigate = useNavigate();

  const ref = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const { setBackgroundImagePath, setSideImagePath } = useUnprotectedLayout();
  const [loading, setLoading] = useState(false);
  const [totpSession, setTotpSession] = useState<string | null>(null);

  useEffect(() => {
    setSideImagePath!('/images/enbridge-logo-in-building.jpg');
    setBackgroundImagePath!('/images/earth.jpg');

    if (formRef.current) {
      const inputs = formRef.current.querySelectorAll('input');

      setTimeout(() => {
        inputs[0].focus();

        setTimeout(() => {

          inputs[1].focus()

        }, 50)

        setTimeout(() => {

          inputs[0].focus()

        }, 100)

      }, 1000)
    }

    const wasLogout = !!localStorage.getItem('logout');
    localStorage.removeItem('logout');
    if (wasLogout) navigate(0);
  }, []);

  const login = async (credentials: AuthenticateUserQueryVariables) => {
    try {
      setLoading(true);

      const { success, message, session } = await signIn(credentials);
      setLoading(false);

      if (success) {
        reset();
        navigate('/assets', {
          replace: true,
        });
      } else {
        if (message === 'Invalid code received for user') {
          setError('challengeAnswer', {
            type: 'custom',
            message: 'Invalid code',
          });
        } else if (message === 'TOTP required' && session) {
          setTotpSession(session);
        } else if (message === 'Invalid session for the user, session is expired.') {
          setError('challengeAnswer', {
            type: 'custom',
            message: 'Session expired, try again',
          });

          window.location.reload()
        } else {
          setError('email', {
            type: 'custom',
            message: 'Incorrect email or password',
          });
          setError('password', {
            type: 'custom',
            message: 'Incorrect email or password',
          });
        }
      }
    } catch (e) {
      setError('email', {
        type: 'custom',
        message: 'Something went wrong',
      });
      setError('password', {
        type: 'custom',
        message: 'Something went wrong',
      });
    }
  }

  const onSubmit: SubmitHandler<AuthenticateUserQueryVariables> = async (
    formData
  ) => {
    const credentials = {
      ...formData,
      challengeAnswer: formData.challengeAnswer
        ? formData.challengeAnswer.replaceAll(' ', '')
        : undefined,
      session: totpSession ? totpSession : undefined,
    };

    login(credentials);
  };

  const resetTotp = () => {
    setTotpSession(null);
    setValue('challengeAnswer', '');
  }

  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormControl
            type="text"
            label="Email address"
            id="email"
            name="email"
            error={errors.email?.message}
            register={register}
            onChange={() => resetTotp()}
          />
        </FormGroup>

        <FormGroup>
          <FormControl
            register={register}
            type="password"
            label="Password"
            id="password"
            name="password"
            error={errors.password?.message}
            onChange={() => resetTotp()}
          />
        </FormGroup>

        {totpSession && (
          <FormGroup>
            <FormControl
              name="challengeAnswer"
              register={register}
              label="Authentication code"
              id="challengeAnswer"
              type="text"
              error={errors.challengeAnswer?.message}
            />
          </FormGroup>
        )}

        <FormGroup>
          <Checkbox
            label="Remember Me"
            name="rememberMe"
            register={register}
            id="rememberMe"
          />
        </FormGroup>
        <FormBottom>
          <ForgotPasswordMsg to="/auth/forgot">
            Forgot Password?
          </ForgotPasswordMsg>
          <Button loading={loading} type="submit">
            Log In
          </Button>
        </FormBottom>
      </form>
    </>
  );
};

export default Login;
