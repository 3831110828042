import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DisableMfaMutationVariables } from '../../../API';
import errorMessages from '../../../config/errorMessages';
import { useAuth } from '../../../contexts/Auth';
import Alert from '../../Alert/Alert';
import FormControl from '../../Forms/FormControl/FormControl';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import Modal from '../Modal';

type FormVariables = DisableMfaMutationVariables;

const disableMfaSchema = yup.object().shape({
  groups: yup.array().min(1, errorMessages.minCharacters(1)),
});

type DisableMfaModalProps = {
  open: boolean;
  closeModalFunc: (open: boolean) => void;
}

const DisableMfaModal: React.FC<DisableMfaModalProps> = ({ open, closeModalFunc }) => {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors
  } = useForm<FormVariables>({
    resolver: yupResolver(disableMfaSchema),
  });

  const { setMfaEnabled, disableTotp } = useAuth();

  const [successMsg, setSuccessMsg] = useState('');
  const [loadingDisableMfa, setLoadingDisableMfa] = useState(false);

  // Disables MFA on the logged in account
  const handleDisableMfa = async () => {
    setLoadingDisableMfa(true);
    clearErrors();

    try {
      const { challengeAnswer } = getValues();

      if (!challengeAnswer) {
        throw "Invalid TOTP";
      }

      // Disable TOTP
      // If it fails, it will throw
      const { success } = await disableTotp(challengeAnswer.replaceAll(" ", ""));

      if (success) {
        setMfaEnabled!(false);
        closeModalFunc(false);
        setSuccessMsg('MFA Disabled');
        setValue('challengeAnswer', '');
      }
    } catch (error) {
      if (error === "Invalid TOTP") {
        setError('challengeAnswer', {
          type: 'custom',
          message: 'Invalid code',
        });
      }
    }

    setLoadingDisableMfa(false);
  };

  useEffect(() => {

    if (!open) setSuccessMsg('');

  }, [open])

  return (
    <Modal
      open={open}
      closeModalFunc={closeModalFunc}
      title="Disable MFA"
      description="Please enter your authentication code to disable MFA."
      buttonText='Disable MFA'
      buttonDisabled={loadingDisableMfa}
      onSubmitBtnClick={() => handleDisableMfa()}
      buttonLoading={loadingDisableMfa}
    >
      <FormGroup>
        <FormControl
          name="challengeAnswer"
          register={register}
          label="Authentication code"
          id="challengeAnswer"
          type="text"
          error={errors.challengeAnswer?.message}
        />
      </FormGroup>

      {successMsg && <Alert variant='success'>{successMsg}</Alert>}
    </Modal>
  );
};

export default DisableMfaModal;
