import React from "react";
import { useLocation } from "react-router-dom";

import { Container, Rest, Tab } from "./style";

export type NavTabItem = {
  to: string;
  text: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  active?: boolean;
};

type ItemSubScreenProps = {
  items: NavTabItem[];
};

const NavTabs: React.FC<ItemSubScreenProps> = ({ items }) => {
  const { pathname } = useLocation();

  const isOnURI = (uri: string) => {
    const pattern = new RegExp(`^${uri}.*`, "g");

    return pattern.test(pathname);
  };

  return (
    <Container>
      {items.map(({ text, to, onClick, active }, index) => (
        <Tab to={to} onClick={onClick} active={typeof active === 'boolean' ? active : isOnURI(to)} key={index}>
          {text}
        </Tab>
      ))}

      <Rest />
    </Container>
  );
};

export default NavTabs;
