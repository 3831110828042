import { UseFormRegister } from 'react-hook-form';

export type ConstructorParams = {
  deviceShadow: DeviceShadowRaw;
  register: UseFormRegister<any>;
};

export type MeterSizes =
  | 'RM600'
  | 'RM1000'
  | 'RM1500'
  | 'RM2000'
  | 'RM3000'
  | 'RM5000'
  | 'RM7000'
  | 'RM11000'
  | 'RM16000'
  | 'RM23000'
  | 'RM25000'
  | 'RM38000'
  | 'RM56000'
  | '1.5MLMMA'
  | '3MLMMA'
  | '5MLMMA'
  | '7MLMMA'
  | '11MLMMA'
  | '16MLMMA'
  | '23MLMMA'
  | '38MLMMA'
  | '56MLMMA'
  | '102MLMMA'
  | 'RMT600'
  | 'RMT1000'
  | 'RMT1500'
  | 'RMT2000'
  | 'RMT3000'
  | 'RMT5000'
  | 'RMT7000'
  | 'RMT11000'
  | 'RMT16000'
  | 'RMT23000'
  | '8C175I'
  | '11C175I'
  | '15C175I'
  | '2M175I'
  | '3M175I'
  | '5M175I'
  | '7M175I'
  | '11M175I'
  | '16M175I'
  | '23M175I'
  | '23M232I'
  | '38M175I'
  | '1M300I'
  | '3M300I'
  | '56M175I'
  | 'G10'
  | 'G16'
  | 'G25'
  | 'G40'
  | 'G65'
  | 'G100'
  | 'G160'
  | 'G250'
  | 'G400'
  | 'G400-150'
  | 'G650'
  | 'G1000'
  | 'RM16'
  | 'RM30'
  | 'RM40'
  | 'RM55'
  | 'RM85'
  | 'RM140'
  | 'RM200'
  | 'RM300'
  | 'RM450'
  | 'RM650'
  | 'RM700'
  | 'RM1100'
  | 'RM1600'
  | '1.5M(40)'
  | '3M(85)'
  | '5M(140)'
  | '7M(200)'
  | '11M(300)'
  | '16M(450)'
  | 'RMT16'
  | 'RMT30'
  | 'RMT40'
  | 'RMT55'
  | 'RMT85'
  | 'RMT140'
  | 'RMT200'
  | 'RMT300'
  | 'RMT450'
  | 'RMT650'
  | '8C175M'
  | '11C175M'
  | '15C175M'
  | '2M175M'
  | '3M175M'
  | '5M175M'
  | '7M175M'
  | '11M175M'
  | '16M175M'
  | '23M175M'
  | '23M232M'
  | '38M175M'
  | '1M300M'
  | '3M300M'
  | '56M175M';

export type DeviceShadowData = {
  cVD: number;
  cVUt: number;
  cV: number;
  uVD: number;
  uVUt: number;
  uV: number;
  atPr: number;
  bPr: number;
  tempHL: number;
  tempLL: number;
  prHL: number;
  prLL: number;
  uFHL: number;
  uFLL: number;
  MID: string;
  gCO2: number;
  gN2: number;
  gsG: number;
  tT: string;
  wk1: string;
  wk2: string;
  wk3: string;
  wk4: string;
};

export type DeviceShadowAliases = keyof DeviceShadowData;
export type Reported = {
  [Alias in keyof DeviceShadowData]?: DeviceShadowData[Alias];
} & {
  prFT: number;
  MtrSz: MeterSizes;
  sxFT: number;
  sxFA: number;
  GDST: string;
  FWV: string;
};

export type DeviceShadowRaw = {
  state: {
    reported: Reported;
    desired: Reported;
  };
};

export type UnitType = 'imperial' | 'metric';

export type MeterUnit = 'kpa' | 'bar' | 'all';

export type RangeList = {
  min: number;
  max: number;
} & (RangeListImperial | RangeListMetric);

type RangeListImperial = {
  type: 'imperial';
};

export type RangeListMetric = {
  type: 'metric';
  meterUnit: 'kpa' | 'bar';
};

export type DeviceShadowAliasesAcceptedByRangeAsset = keyof Omit<
  DeviceShadowData,
  'wk1' | 'wk2' | 'wk3' | 'wk4' | 'tT'
>;

export type RangeProp = {
  min: number;
  max: number;
  step: number;
  name: DeviceShadowAliasesAcceptedByRangeAsset;
  register: UseFormRegister<any>;
  disabled?: boolean;
  subLabel: string;
  label: string;
  value: number;
};
export default class RangeAsset {
  public deviceShadowAlias: DeviceShadowAliasesAcceptedByRangeAsset;
  protected deviceShadow: DeviceShadowRaw;
  protected unitType: UnitType;
  protected meterUnit: MeterUnit;
  protected min: number;
  protected max: number;
  protected register: UseFormRegister<any>;
  protected label: string;
  protected step: number = 0.001;
  constructor({ deviceShadow, register }: ConstructorParams) {
    this.deviceShadow = deviceShadow;
    this.register = register;
  }

  protected setMeterInfo() {}

  protected getDeviceDataMeterSize() {
    return this.deviceShadow.state.reported.MtrSz.trim();
  }

  protected getDeviceShadowAliasValue() {
    if (!this.deviceShadow.state.reported) return null;
    return this.deviceShadow.state.reported[this.deviceShadowAlias];
  }

  public getUnitType() {
    return this.unitType;
  }

  public getMeterUnit() {
    return this.meterUnit;
  }

  public getRangeProps(): RangeProp {
    return {
      min: this.min,
      max: this.max,
      step: this.step,
      name: this.deviceShadowAlias,
      register: this.register,
      subLabel: this.getSubLabel(),
      label: this.label,
      value: Number(this.getDeviceShadowAliasValue()) || this.min,
      disabled: !this.isConfigurable(),
    };
  }

  protected getSubLabel() {
    return `Standard Value Min=${this.min} Max=${this.max}`;
  }

  isConfigurable(): boolean {
    return true;
  }

  protected _correctValue(value: number) {
    return value;
  }

  public correctValue(value: number) {
    const correctedValue = this._correctValue(value);
    const unitType = this.getUnitType();

    // const stepDecimals = this.step.toString().split('.')[1]?.length || 0;
    // const fixed = correctedValue.toFixed(stepDecimals);

    const stepDecimals = this.step.toString().split('.')[1]?.length + 1 || 0;
    const fixed = this.toFixed_NoRounding(correctedValue, stepDecimals);

    return Number(fixed);
  }

  protected toFixed_NoRounding(value: number, decimals: number) {
    const stringValue = value.toString();

    const [integerPart, decimalPart] = stringValue.split('.');
    if (decimalPart) {
      return +[integerPart, decimalPart.slice(0, decimals)].join('.');
    } else {
      return integerPart;
    }
  }
}
