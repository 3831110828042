import React from "react";
import Button from "../Button/Button";
import { BottomContentContainer, Box, BoxItem, BoxTitle, Col, Header, Row as InfoBoxRow, Label, MultipleValue, Value } from "./style";

export type InfoBoxRow = {
    label: string;
    value: string | string[] | any[];
};

type Props = {
    rows: InfoBoxRow[];
    title?: string;
    buttonText?: string;
    containerStyle?: React.CSSProperties;
    boxStyle?: React.CSSProperties;
    onBtnClick?: () => void;
    bottomContent?: React.ReactNode;
};

const InfoBox: React.FC<Props> = ({
    title,
    rows,
    buttonText,
    containerStyle,
    onBtnClick,
    bottomContent,
    boxStyle,
}) => {

    const values = rows.map(row => row.value);
    const labels = rows.map(row => row.label);

    const showValues = (values: string[]) => {
        console.log(values)
        return (
            <MultipleValue>
                {values.map(value => <Value>{value}</Value>)}
            </MultipleValue>
        )
    }
    return (
        <BoxItem style={containerStyle}>
            <Box style={boxStyle}>
                <Header>
                    <BoxTitle>{title}</BoxTitle>
                    {buttonText && (
                        <Button
                            onClick={onBtnClick}
                            variant="light"
                            buttonStyle={{
                                padding: "10px 16px",
                            }}
                        >
                            {buttonText}
                        </Button>
                    )}
                </Header>
                <InfoBoxRow>
                    <Col>
                        {labels.map((label) => {
                            return (
                                <Label>{label}</Label>
                            );
                        })}
                    </Col>
                    <Col>
                        {values.map((value) => {
                            return (
                                <>{typeof value !== 'object' ? <Value>{value}</Value> : showValues(value)}</>
                            );
                        })}
                    </Col>
                </InfoBoxRow>
                {bottomContent && <BottomContentContainer>{bottomContent}</BottomContentContainer>}
            </Box>
        </BoxItem>
    );
};

export default InfoBox;
