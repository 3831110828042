import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

type LinkProps = {
  active?: boolean;
};

export const Nav = styled.nav`
  width: 100%;
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ListItem = styled.li``;

const getItemLinkBG = (active?: boolean) => {
  return css`
    ${(props) => (active ? props.theme.colors.lightGray : "transparent")}
  `;
};

const getItemLinkWeight = (active?: boolean) => {
  return css`
    ${(props) => (active ? "800" : "400")};
  `;
};

export const ItemLink = styled(Link)<LinkProps>`
  display: flex;
  padding: 14px 16px;
  background-color: ${(props) => getItemLinkBG(props.active)};
  font-weight: ${(props) => getItemLinkWeight(props.active)};
  border-radius: ${(props) => props.theme.borderRadius};
  color: ${(props) => props.theme.colors.black};
  font-size: 12px;
  transition: 150ms all;
  align-items: center;

  &:hover {
    font-weight: ${(props) => getItemLinkWeight(true)};
    background-color: ${(props) => getItemLinkBG(true)};
  }

  & svg {
    font-size: 15px;
    margin-right: 5px;
  }
`;
