import styled from 'styled-components';

export const RadioG = styled.div``;

export const Label = styled.label`
  display: block;
  font-size: 1rem;
  line-height: 32px;
  margin-bottom: 18px;
  color: ${(props) => props.theme.colors.black};
`;
