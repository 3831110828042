import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import {
  BatteryLifeData,
  Message,
  UpdateAlarmCommentMutation,
  UpdateAlarmCommentMutationVariables,
  UpdateAlertStatusMutation,
  UpdateAlertStatusMutationVariables,
} from '../../API';
import { useAuth } from '../../contexts/Auth';
import { getBatteryLife } from '../../graphql/queries';
import AlarmUtils from '../../utils/AlarmUtils';
import DateUtils from '../../utils/DateUtils';
import {
  AlarmButton,
  AlarmsBedge,
  Aside,
  AsideTitle,
  ContainerMeterset,
  MetersetImage,
  Card,
  CardAlarm,
  ChartGroup,
  ChartTitle,
  Container,
  ContainerAboutAsset,
  ContainerButton,
  ContainerCheckBox,
  DeviceID,
  DeviceInfo,
  DeviceTitle,
  DevicesLogo,
  DropdownItem,
  RadioGroupInline,
  RowSpace,
  Section,
  SectionFilter,
  TableDropdown,
  Bedge,
} from './style';

import Checkbox from '../../components/Forms/Checkbox/Checkbox';
import FormGroup from '../../components/Forms/FormGroup/FormGroup';
import Radio from '../../components/Forms/Radio/Radio';
import RadioGroup from '../../components/Forms/RadioGroup/RadioGroup';

import { HiDownload } from 'react-icons/hi';
import { IoMdTrash } from 'react-icons/io';
import {
  MdOutlineBatteryChargingFull,
  MdOutlineDateRange,
  MdOutlineSpeed,
  MdPhotoSizeSelectSmall,
  MdStackedLineChart,
  MdThermostat,
  MdWaves,
} from 'react-icons/md';
import { TbHeartbeat } from 'react-icons/tb';

import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { IconType } from 'react-icons';
import { BsThreeDots } from 'react-icons/bs';
import { RiMapPin2Fill } from 'react-icons/ri';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import Button from '../../components/Button/Button';
import ButtonToTop from '../../components/ButtonToTop/ButtonToTop';
import Can from '../../components/Can/Can';
import FormControl from '../../components/Forms/FormControl/FormControl';
import FormRow from '../../components/Forms/FormRow/FormRow';
import Select from '../../components/Forms/Select/Select';
import Switch from '../../components/Forms/Switch/Switch';
import TextArea from '../../components/Forms/TextArea/TextArea';
import InfoBox from '../../components/InfoBox/InfoBox';
import Col from '../../components/Layout/Col/Col';
import Row from '../../components/Layout/Row/Row';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import {
  MainTitleWrapper,
  OptionalTitleContentWrapper,
  TitleContainer,
} from '../../components/MainLayout/style';
import AssetConfigurationModal from '../../components/Modal/AssetConfigurationModal/AssetConfigurationModal';
import DeviceShadowModal from '../../components/Modal/DeviceShadowModal/DeviceShadowModal';
import ExportAssetModal from '../../components/Modal/ExportAssetModal/ExportAssetModal';
import InstallFirmwareModal from '../../components/Modal/InstallFirmwareModal/InstallFirmwareModal';
import Modal from '../../components/Modal/Modal';
import PullDataModal from '../../components/Modal/PullDataModal/PullDataModal';
import SetThresholdsModal, {
  ThresholdsFormType,
} from '../../components/Modal/SetThresholdsModal/SetThresholdsModal';
import Pill from '../../components/Pill/Pill';
import Spinner from '../../components/Spinner/Spinner';
import Typography from '../../components/Typography/Typography';
import errorMessages from '../../config/errorMessages';
import { updateAlarmComment, updateAlertStatus } from '../../graphql/mutations';
import {
  useDeviceShadow,
  useGetDeviceGroups,
  useListDevices,
  useGetMetersetImage,
  useMeterData,
} from '../../hooks/queries';
import { DeviceShadowRaw } from '../../modules/DeviceShadow/DeviceShadow';
import AdemUtils, { AdemModel } from '../../utils/AdemUtils';
import { DropdownBtn } from '../Devices/DeviceTable/style';
import MeterHealthReportModal, { QMarginData, QMarginDataList } from '../../components/MeterHealthReportModal/MeterHealthReportModal';

type Frequency = 'daily' | 'hourly';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const TYPE_MAPPING = {
  0: 'Device Inactive',
  3: 'Low Battery',
  824: 'Memory Error',
  163: 'Flow High',
  810: 'Flow Low',
  145: 'Pressure High',
  143: 'Pressure Low',
  146: 'Temperature High',
  144: 'Temperature Low',
  99: 'Battery Malfunction',
  106: 'Temperature Malfunction',
  105: 'Pressure Malfunction',
  859: 'Q Low',
  861: 'DP Malfunction',
  8888: 'Low Battery',
};

type DeviceDataFormat = {
  title: string;
  datasets: {
    title: string;
    selector: string;
  }[];
  id: string;
  scale?: string;
  button?: {
    icon: IconType;
    text: string;
    omit?: boolean;
    disabled?: boolean;
  };
  omit?: boolean;
};

// eslint-disable-next-line no-unused-vars
enum Period {
  D90,
  CURRENT_MONTH,
  THIS_VS_LAST_MONTH,
  M13,
  CUSTOM,
}

const defaultFrequency: Frequency = 'daily';

const schema = yup.object({
  comment: yup.string().required(errorMessages.required),
});

const start = new Date();
start.setDate(start.getDate() - 30);
const defaultToDate = new Date();
const defaultFromDate = start;

const deviceCompanyData = [
  {
    title: 'Max. Pressure',
    value: 'maxPr',
    selector: 'maxPr.v',
    scale: 'pressureUnit',
  },
  {
    title: 'Min. Pressure',
    value: 'minPr',
    selector: 'minPr.v',
    scale: 'pressureUnit',
  },
  {
    title: 'Max Flow Corr',
    value: 'maxFC',
    selector: 'maxFC.v',
    scale: 'flowUnit',
  },
  {
    title: 'Min Flow Corr',
    value: 'minFC',
    selector: 'minFC.v',
    scale: 'flowUnit',
  },
  {
    title: 'Max. Temp',
    value: 'maxTemp',
    selector: 'maxTemp.v',
    scale: 'temperatureUnit',
  },
  {
    title: 'Min. Temp',
    value: 'minTemp',
    selector: 'minTemp.v',
    scale: 'temperatureUnit',
  },
  {
    title: 'Remaining Battery Life',
    value: 'percentage',
    selector: 'percentage',
    scale: 'percentage',
  },
  {
    title: 'Corr. Index Read',
    value: 'dayCV',
    selector: 'dayCV',
    scale: 'volumeUnit',
  },
  {
    title: 'Uncorr. Index Read',
    value: 'dayUV',
    selector: 'dayUV',
    scale: 'volumeUnit',
  },
];

const MeterId: React.FC = () => {
  const { colors } = useTheme();

  const { register: periodRegister, getValues: periodGetValues } =
    useForm<any>();

  const theme = useTheme();

  const [thresholdsModalOpen, setThresholdsModalOpen] = useState(false);

  const [meterHealthReportModalOpen, setMeterHealthReportModalOpen] = useState(false);

  const [metersetImage, setMetersetImage] = useState<string | null>(null);

  useEffect(() => console.log({ metersetImage }), [metersetImage]);

  const navigationRef = useRef<HTMLDivElement>(null);

  const { id } = useParams();

  const { signedQueryRequest, signedMutationRequest } = useAuth();

  const [batteryLifeData, setBatteryLifeData] =
    useState<BatteryLifeData | null>(null);

  const [frequency, setFrequency] = useState<Frequency>(defaultFrequency);

  const [period, setPeriod] = useState(Period.D90);

  const deviceGroups = useGetDeviceGroups();
  const groups = deviceGroups.data?.getDeviceGroups;

  const dateToNumber = (date: Date): number => {
    return Math.floor(date.getTime() / 1000);
  };

  const getFromDate = () => {
    let start = new Date();

    switch (period) {
      case Period.D90:
        start.setDate(start.getDate() - 90);
        break;
      case Period.THIS_VS_LAST_MONTH:
        start = new Date(start.getFullYear(), start.getMonth() - 1, 0);
        break;
      case Period.M13:
        start = new Date(start.getFullYear(), start.getMonth() - 12, 1);
        break;
      case Period.CUSTOM:
        start = new Date(periodGetValues().fromDate);
        break;
      default:
        break;
    }

    start.setHours(0, 0, 0, 0);

    return dateToNumber(start);
  };

  const getToDate = () => {
    let end = new Date();
    if (period === Period.CUSTOM) {
      end = new Date(periodGetValues().toDate);
    }
    end.setHours(23, 59, 59, 999);
    return dateToNumber(end);
  };

  const [fromDate, setFromDate] = useState(getFromDate());
  const [toDate, setToDate] = useState(getToDate());

  const meterData = useMeterData({
    variables: {
      deviceId: id,
      frequency,
      fromDate,
      toDate,
    },
  });

  const targetMeterData = meterData.data?.getMeterData[0]?.data;
  const currDate = moment(new Date()).format('MM/YYYY');
  const qMarginDataList: QMarginDataList = parseQMarginData(targetMeterData);
  const currQMargin = qMarginDataList.find(({ label }) => label === currDate);

  const devices = useListDevices({
    variables: {
      date: moment().format('YYYY-M-D'),
      meterId: '',
      deviceId: id,
    },
  });
  const deviceData = devices.data?.listDevices.rows[0];
  const thisDeviceGroup = groups?.find(
    (group) => group?.name === deviceData?.group?.name
  );

  const metersetSignedUrl = useGetMetersetImage({
    variables: {
      deviceId: id!,
    },
  });

  useEffect(() => {
    const signedUrl = metersetSignedUrl.data?.getMetersetImage.signedUrl;
    if (signedUrl) {
      setMetersetImage(signedUrl);
    }
  }, [metersetSignedUrl]);

  const temperatureUnit =
    thisDeviceGroup?.temperatureUnit ||
    deviceData?.group?.utility.company?.temperatureUnit;
  const pressureUnit =
    thisDeviceGroup?.pressureUnit ||
    deviceData?.group?.utility.company?.pressureUnit;
  const voltageUnit =
    thisDeviceGroup?.voltageUnit ||
    deviceData?.group?.utility.company?.voltageUnit;
  const volumeUnit =
    thisDeviceGroup?.volumeUnit ||
    deviceData?.group?.utility.company?.volumeUnit;
  const flowUnit =
    thisDeviceGroup?.correctedFlowUnit ||
    deviceData?.group?.utility.company?.correctedFlowUnit;

  const assetShadow = useDeviceShadow({
    variables: {
      deviceId: id!,
      prettify: false,
      region: deviceData?.group?.utility.company?.region!,
    },
    additionalOptions: {
      enabled: !!deviceData && !!id,
    },
  });
  const deviceShadowParsed = JSON.parse(
    assetShadow.data?.getDeviceShadow || '{}'
  ) as DeviceShadowRaw;
  const reported = deviceShadowParsed?.state?.reported;
  const ademModel: AdemModel | null = AdemUtils.parseAdemModel(reported?.FWV);
  const omitPressure =
    ademModel === 'T' ||
    ademModel === 'UniversalT' ||
    ademModel === 'Tq' ||
    (ademModel === 'PTZ' && reported?.prFT === 1);

  const alarms = deviceData?.messages?.reduce((currentAlarms, message) => {
    if (!message?.alarms) return currentAlarms;
    const combined = currentAlarms.concat(message?.alarms as any);
    return combined;
  }, []);

  const deviceDataFormat: DeviceDataFormat[] = [
    {
      title: 'Corrected/Uncorrected Volume',
      datasets: [
        {
          title: 'Corrected Volume',
          selector: frequency === 'daily' ? 'dayCV' : 'cV',
        },
        {
          title: 'Uncorrected Volume',
          selector: frequency === 'daily' ? 'dayUV' : 'uV',
        },
      ],
      button: {
        icon: MdPhotoSizeSelectSmall,
        text: 'Volume',
      },
      id: 'volumeMinMax',
      scale: 'volumeUnit',
    },
    {
      title: 'Pressure',
      button: {
        icon: MdOutlineSpeed,
        text: 'Pressure',
        omit: frequency !== 'hourly' || omitPressure,
      },
      datasets: [
        {
          title: 'Pressure',
          selector: 'pr',
        },
      ],
      id: 'pressure',
      scale: 'pressureUnit',
      omit: omitPressure,
    },
    {
      title: 'Pressure MIN/MAX',
      button: {
        icon: MdOutlineSpeed,
        text: 'Pressure',
        omit: frequency !== 'daily' || omitPressure,
      },
      datasets: [
        {
          title: 'Min Pressure',
          selector: 'minPr',
        },
        {
          title: 'Max Pressure',
          selector: 'maxPr',
        },
      ],
      id: 'pressureMinMax',
      scale: 'pressureUnit',
      omit: omitPressure,
    },
    {
      title: 'Temperature',
      datasets: [
        {
          title: 'Temperature',
          selector: 'temp',
        },
      ],
      button: {
        icon: MdThermostat,
        text: 'Temperature',
        omit: frequency !== 'hourly',
      },
      id: 'temperature',
      scale: 'temperatureUnit',
    },
    {
      title: 'Temperature MIN/MAX',
      datasets: [
        {
          title: 'Min Temperature',
          selector: 'minTemp',
        },
        {
          title: 'Max Temperature',
          selector: 'maxTemp',
        },
      ],
      button: {
        icon: MdThermostat,
        text: 'Temperature',
        omit: frequency !== 'daily',
      },
      id: 'temperatureMinMax',
      scale: 'temperatureUnit',
    },
    {
      title: 'Flow Correction MIN/MAX',
      button: {
        icon: MdWaves,
        text: 'Flow',
        disabled: frequency !== 'daily',
      },
      datasets: [
        {
          title: 'Min Flow',
          selector: 'minFC',
        },
        {
          title: 'Max Flow',
          selector: 'maxFC',
        },
      ],
      id: 'flowCorrectionMinMax',
      scale: 'flowUnit',
    },
    {
      title: 'Remaining Battery Life',
      datasets: [
        {
          title: 'Remaining Battery Life',
          selector: 'percentage',
        },
      ],
      button: {
        icon: MdOutlineBatteryChargingFull,
        text: 'Battery Health',
        disabled: true,
      },
      id: 'batteryVoltage',
      scale: 'voltageUnit',
    },
    {
      title: 'Q Margin',
      datasets: [
        {
          title: 'Q Margin',
          selector: 'qMarg',
        },
      ],
      button: {
        icon: TbHeartbeat,
        text: 'Meter Health',
        omit: ademModel !== 'Tq',
      },
      id: 'qMargin',
      omit: ademModel !== 'Tq',
    },
  ];

  const [commentModalOpen, setCommentModalOpen] = useState(false);

  const [customDateModalOpen, setCustomDateModalOpen] = useState(false);

  const [customizeModalOpen, setCustomizeModalOpen] = useState(false);

  const [messageToBeUpdated, setMessageToBeUpdated] = useState<Message | null>(
    null
  );

  const [commentBeingChanged, setCommentBeingChanged] = useState<number | null>(
    null
  );

  const [assetConfigModalOpen, setAssetConfigModalOpen] = useState(false);

  const [isLine, setIsLine] = useState(true);

  const [customizedGraphNames, setCustomizedGraphNames] = useState<string[][]>(
    []
  );

  const customizedGraphsData = customizedGraphNames.map((names, index) => {
    const getRightData = (name: string) => {
      for (const data of deviceCompanyData) {
        if (name === data.value) return data;
      }
    };

    const colors = ['#0C2340', '#00A9E0', '#FF9900', theme.colors.danger];
    return {
      title: `Graph custom ${index + 1}`,
      data: {
        labels: targetMeterData?.map((meter) =>
          moment(new Date(meter!.time! * 1000)).format('MM/DD/YYYY H:mm:ss')
        ),
        datasets: names.map((name, index) => {
          const rightData = getRightData(name)!;
          return {
            label: rightData.title,
            data: targetMeterData?.map((meter) => {
              return (meter! as any)[rightData.value];
            }),
            borderColor: colors[index % colors.length],
            backgroundColor: colors[index % colors.length],
            pointRadius: 0,
            yAxisID: rightData.scale,
          };
        }),
      },
    };
  });

  const [installFirmwareModalOpen, setInstallFirmwareModalOpen] =
    useState(false);

  const [pullDataModalOpen, setPullDataModalOpen] = useState(false);

  const [exportDeviceModalOpen, setExportDeviceModalOpen] = useState(false);
  const [deviceShadowModalOpen, setDeviceShadowModalOpen] = useState(false);

  const {
    trigger,
    getValues,
    register,
    formState: { errors },
  } = useForm<Message>({
    resolver: yupResolver(schema),
  });

  const {
    getValues: customizeGraphGetValue,
    register: customizeGraphRegister,
    reset: customizeGraphReset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date/Time',
        },
      },
      y: {
        type: 'linear',
        display: 'auto',
        position: 'left',
      },
      volumeUnit: {
        type: 'linear',
        display: 'auto',
        position: 'left',
        title: {
          display: true,
          text: volumeUnit?.replace('3', '³'),
        },
      },
      flowUnit: {
        type: 'linear',
        display: 'auto',
        position: 'left',
        title: {
          display: true,
          text: flowUnit?.replace('3', '³'),
        },
      },
      pressureUnit: {
        type: 'linear',
        display: 'auto',
        position: 'left',
        title: {
          display: true,
          text: pressureUnit,
        },
      },
      voltageUnit: {
        type: 'linear',
        display: 'auto',
        position: 'left',
        title: {
          display: true,
          text: voltageUnit,
        },
      },
      temperatureUnit: {
        type: 'linear',
        display: 'auto',
        position: 'left',
        title: {
          display: true,
          text: temperatureUnit,
        },
      },
    },
  } as any;

  // useEffect(() => {
  //   signedQueryRequest(getBatteryLife, {
  //     deviceId: id,
  //   });
  // }, []);

  const fetchBatteryLife = async () => {
    const { data } = await signedQueryRequest(getBatteryLife, {
      deviceId: id,
    });
    setBatteryLifeData(data.getBatteryLife);
  };

  const handleCommentClick = (message: Message) => {
    setMessageToBeUpdated(message);
    setCommentModalOpen(true);
  };

  const handleChangeStatusClick = async (
    message: Message,
    newStatus: string,
    index: number
  ) => {
    setCommentBeingChanged(index);

    await signedMutationRequest<
      UpdateAlertStatusMutationVariables,
      UpdateAlertStatusMutation
    >(updateAlertStatus, {
      id: message.id,
      status: newStatus,
      time: message.time,
    });

    setCommentBeingChanged(null);
    devices.refetch();
  };

  const handleUpdateCommentsBtnClick = async () => {
    const hasNoErrors = await trigger();

    if (!hasNoErrors) return;

    const { comment } = getValues();

    await signedMutationRequest<
      UpdateAlarmCommentMutationVariables,
      {
        data: UpdateAlarmCommentMutation;
      }
    >(updateAlarmComment, {
      alarmId: messageToBeUpdated?.id!,
      comment,
      time: messageToBeUpdated?.time!,
    });

    setCommentModalOpen(false);
    setMessageToBeUpdated(null);
    devices.refetch();
  };

  const handleUpdateCustomizeBtnClick = async () => {
    const { customize } = customizeGraphGetValue();

    if (!customize) return;

    const newCustomizedGraphs = [...customizedGraphNames];
    newCustomizedGraphs.push([...customize]);

    customizeGraphReset();
    setCustomizedGraphNames(newCustomizedGraphs);
    setCustomizeModalOpen(false);
  };

  const handleInstallFirmwareForDevice = async () => {
    setInstallFirmwareModalOpen(true);
  };

  const handleExportDevicesBtn = async () => {
    setExportDeviceModalOpen(true);
  };

  const handleDeviceShadowBtn = async () => {
    setDeviceShadowModalOpen(true);
  };

  const handleCustomDateBtnClick = () => {
    const values = periodGetValues();

    const fromDate = new Date(values.fromDate);
    const toDate = new Date(values.toDate);

    setFromDate(dateToNumber(fromDate));
    setToDate(dateToNumber(toDate));

    setCustomDateModalOpen(false);
  };

  useEffect(() => {
    const mainLayoutEl = document.querySelector('#root > div > div');

    const onScroll = () => {
      if (navigationRef.current) {
        const navigationBounds = navigationRef.current.getBoundingClientRect();
        const topToNavigationSize = navigationBounds.top;
        const navigationDiv = navigationRef.current.querySelector('div')!;

        if (topToNavigationSize < 0) {
          navigationDiv.style.position = 'fixed';
        } else {
          navigationDiv.style.position = 'relative';
        }
      }
    };

    mainLayoutEl?.removeEventListener('scroll', onScroll);
    mainLayoutEl?.addEventListener('scroll', onScroll);

    return () => {
      mainLayoutEl?.removeEventListener('scroll', onScroll);
    };
  }, []);

  function defaultThresholdValues() {
    const allThresholds = deviceData?.thresholds || [];
    const withoutNull = allThresholds.filter(
      (threshold) => threshold.value !== null
    );
    const defaultValues = withoutNull.reduce<ThresholdsFormType>(
      (accumulator, threshold) => {
        const thresholdValue = threshold.value;
        const thresholdKey = threshold.threshold_key;

        (accumulator as any)[thresholdKey] = thresholdValue;

        return accumulator;
      },
      {}
    );

    return defaultValues;
  }

  /**
   * Get the average of the Q Margin data for each month.
   */
  function parseQMarginData(data: typeof targetMeterData): QMarginDataList {
    if (!data) {
      return [];
    }

    let qMarginDataList: QMarginDataList = [];

    // Split the data by month
    type QMarginBucket = { [month: string]: { value: number, length: number } };
    const buckets = data?.reduce((prev: QMarginBucket, curr) => {
      const date = moment(new Date(curr!.time! * 1000)).format('MM/YYYY');

      const qMarg = curr?.qMarg;
      if (!_.isNil(qMarg)) {
        if (!(date in prev)) {
          prev[date] = { value: 0, length: 0 };
        }

        prev[date].value += qMarg;
        prev[date].length += 1;
      }

      return prev;
    }, {});

    // Remove current month since data will not be fully populated until the next month
    const date = new Date();
    delete buckets[moment(date).format('MM/YYYY')];

    // Find the average of each month
    qMarginDataList = Object.entries(buckets).reduce((prev: QMarginDataList, curr) => {
      const [date, bucket] = curr;

      const qMarginData: QMarginData = {
        label: date,
        value: bucket.value / bucket.length,
      };

      prev.push(qMarginData);
      return prev;
    }, []);

    return qMarginDataList;
  }

  useEffect(() => {
    fetchBatteryLife();

    setFromDate(getFromDate());
    setToDate(getToDate());
  }, [frequency, period]);

  useEffect(() => {
    if (period === Period.CUSTOM) {
      setCustomDateModalOpen(true);
    }
  }, [period]);

  if (devices.isLoading) return <LoadingModal enabled />;

  return (
    <>
      <ButtonToTop />

      <SetThresholdsModal
        defaultValues={defaultThresholdValues()}
        open={thresholdsModalOpen}
        closeModalFunc={setThresholdsModalOpen}
        deviceIds={[id!]}
      />

      {qMarginDataList && qMarginDataList.length > 0 && (
        <MeterHealthReportModal
          open={meterHealthReportModalOpen}
          closeModalFunc={setMeterHealthReportModalOpen}
          meterSize={deviceData?.data?.meterSize ?? ''}
          address={deviceData?.address ?? ''}
          companyName={deviceData?.group?.utility?.company?.name ?? ''}
          deviceId={id ?? ''}
          serialNo={deviceData?.serialNo ?? ''}
          qMarginDataList={qMarginDataList}
        />
      )}

      <Modal
        closeModalFunc={setCustomDateModalOpen}
        title="Custom Date"
        description="Change meter data custom date"
        open={customDateModalOpen}
        buttonText="Set Custom Date"
        onSubmitBtnClick={handleCustomDateBtnClick}
      >
        <form>
          <FormGroup>
            <FormRow>
              <FormControl
                name="fromDate"
                label="From"
                type={'date'}
                id="fromDate"
                value={moment(
                  new Date(
                    defaultFromDate.getFullYear(),
                    defaultFromDate.getMonth()
                  )
                ).format('YYYY-MM-DD')}
                register={periodRegister}
              />
              <FormControl
                register={periodRegister}
                name="toDate"
                label="To"
                value={moment(defaultToDate).format('YYYY-MM-DD')}
                type={'date'}
                id="toDate"
              />
            </FormRow>
          </FormGroup>
        </form>
      </Modal>

      <Modal
        closeModalFunc={setCommentModalOpen}
        title="Comments"
        description="Change alarm comment"
        open={commentModalOpen}
        buttonText="Update Comment"
        onSubmitBtnClick={handleUpdateCommentsBtnClick}
      >
        <form>
          <TextArea
            id="comment"
            label="Comment"
            name="comment"
            register={register}
            error={errors.comment?.message}
            rows={10}
            value={messageToBeUpdated?.comment}
          />
        </form>
      </Modal>

      <Modal
        closeModalFunc={setCustomizeModalOpen}
        title="Customize Graph"
        description="Please select what data to include in your custom graph."
        open={customizeModalOpen}
        buttonText="Customize graph"
        onSubmitBtnClick={handleUpdateCustomizeBtnClick}
      >
        <form>
          <FormGroup>
            <RadioGroup label="Set Metrics">
              {deviceCompanyData.map((item, value) => (
                <ContainerCheckBox>
                  {item.title === 'Remaining Battery Life' && (
                    <Checkbox
                      register={customizeGraphRegister}
                      label="Remaining Battery Life"
                      value={value}
                      name="customize[]"
                      disabled={true}
                    />
                  )}
                </ContainerCheckBox>
              ))}
              {deviceCompanyData.map(({ title, value }, index) => {
                return (
                  <>
                    <div key={index}>
                      {title !== 'Remaining Battery Life' && (
                        <Checkbox
                          register={customizeGraphRegister}
                          label={title}
                          value={value}
                          name="customize[]"
                        />
                      )}
                    </div>
                  </>
                );
              })}
            </RadioGroup>
          </FormGroup>
        </form>
      </Modal>

      <InstallFirmwareModal
        open={installFirmwareModalOpen}
        closeModalFunc={setInstallFirmwareModalOpen}
        devicesToInstallFirmwares={[deviceData]}
      />
      <PullDataModal
        open={pullDataModalOpen}
        closeModalFunc={setPullDataModalOpen}
        deviceBeingPulled={deviceData?.id!}
      />

      <ExportAssetModal
        devicesToBeExported={[deviceData]}
        open={exportDeviceModalOpen}
        closeModalFunc={setExportDeviceModalOpen}
      />

      <DeviceShadowModal
        open={deviceShadowModalOpen}
        closeModalFunc={setDeviceShadowModalOpen}
        deviceBeingShadowed={deviceData}
      />

      <AssetConfigurationModal
        device={deviceData!}
        open={assetConfigModalOpen}
        closeModalFunc={setAssetConfigModalOpen}
      />

      <TitleContainer>
        <MainTitleWrapper>
          <DevicesLogo>
            <img alt="" src={'/svgs/devices-solid.svg'} />
          </DevicesLogo>
          <DeviceInfo>
            <DeviceTitle>Assets</DeviceTitle>
            {!devices.isLoading ? (
              <DeviceID>
                Customer ID - {deviceData?.meterId || 'Not found'}
              </DeviceID>
            ) : (
              <Spinner variant="primary" />
            )}
          </DeviceInfo>
          <AlarmsBedge>{alarms?.length} Alarms</AlarmsBedge>
        </MainTitleWrapper>
        <OptionalTitleContentWrapper>
          <Can
            role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}
          >
            <DropdownBtn
              renderContent={
                <TableDropdown>
                  <Can
                    role={[
                      'CompanyAdmin',
                      'UtilityAdmin',
                      'DeviceGroupAdmin',
                      'admin',
                    ]}
                  >
                    <DropdownItem
                      onClick={() => handleInstallFirmwareForDevice()}
                    >
                      Install Firmware
                    </DropdownItem>
                  </Can>
                  <Can
                    role={[
                      'CompanyAdmin',
                      'UtilityAdmin',
                      'DeviceGroupAdmin',
                      'admin',
                    ]}
                  >
                    <DropdownItem onClick={() => setPullDataModalOpen(true)}>
                      Pull Data
                    </DropdownItem>
                  </Can>
                  <Can
                    role={[
                      'CompanyAdmin',
                      'UtilityAdmin',
                      'DeviceGroupAdmin',
                      'admin',
                    ]}
                  >
                    <DropdownItem
                      onClick={() => {
                        handleExportDevicesBtn();
                      }}
                    >
                      Export Asset Data
                    </DropdownItem>
                  </Can>
                  <Can
                    role={[
                      'UtilityAdmin',
                      'admin',
                      'DeviceGroupAdmin',
                      'CompanyAdmin',
                    ]}
                  >
                    <DropdownItem
                      onClick={() => {
                        setAssetConfigModalOpen(true);
                      }}
                    >
                      Asset Configuration
                    </DropdownItem>
                  </Can>
                  <Can role={['UtilityAdmin', 'admin', 'UtilityPowerUser']}>
                    <DropdownItem
                      onClick={() => {
                        setThresholdsModalOpen(true);
                      }}
                    >
                      Set Thresholds
                    </DropdownItem>
                  </Can>
                  <Can role={'admin'}>
                    <DropdownItem onClick={() => handleDeviceShadowBtn()}>
                      Asset Shadow
                    </DropdownItem>
                  </Can>
                </TableDropdown>
              }
              shape="circle"
              variant="light"
              dropIcon={false}
            >
              <BsThreeDots />
            </DropdownBtn>
          </Can>
        </OptionalTitleContentWrapper>
      </TitleContainer>

      <Container>
        <Section>
          <SectionFilter>
            <div>
              <RadioGroupInline>
                <Radio
                  id="Hourly"
                  label="Hourly"
                  name="frequency"
                  value="hourly"
                  checked={'hourly' === frequency}
                  onChange={(e) => setFrequency(e.currentTarget.value as any)}
                />

                <Radio
                  id="Daily"
                  label="Daily"
                  name="frequency"
                  value="daily"
                  checked={'daily' === frequency}
                  onChange={(e) => setFrequency(e.currentTarget.value as any)}
                />

                {/* <DropdownButton
                  variant="light"
                  renderContent={
                    <>
                      <Button
                        variant="light"
                        onClick={() => setPeriod(Period.CUSTOM)}
                      >
                        Custom
                      </Button>
                      <Button
                        variant="light"
                        onClick={() => setPeriod(Period.THIS_VS_LAST_MONTH)}
                      >
                        Month vs Last
                      </Button>
                      <Button
                        variant="light"
                        onClick={() => setPeriod(Period.D90)}
                      >
                        90 days
                      </Button>
                    </>
                  }
                >
                  {period} days
                </DropdownButton> */}

                <Select
                  label=""
                  onChange={(e) => setPeriod(parseInt(e.currentTarget.value))}
                  name="period"
                  register={periodRegister}
                >
                  <option value={Period.D90}>90 days</option>
                  <option value={Period.THIS_VS_LAST_MONTH}>
                    Month vs Last
                  </option>
                  <option value={Period.M13}>
                    13 months
                  </option>
                  <option value={Period.CUSTOM}>Custom</option>
                </Select>
              </RadioGroupInline>
            </div>

            <div>
              {period === Period.CUSTOM && (
                <Button
                  variant="light-primary"
                  onClick={() => {
                    setCustomDateModalOpen(true);
                  }}
                  startIcon={<MdOutlineDateRange size={14} />}
                >
                  Change Date
                </Button>
              )}

              <Button
                variant="light-primary"
                onClick={() => {
                  handleExportDevicesBtn();
                }}
                startIcon={<HiDownload size={14} />}
              >
                Download
              </Button>

              <Button
                onClick={() => {
                  setCustomizeModalOpen(true);
                }}
                variant="light-primary"
                startIcon={<MdStackedLineChart size={14} />}
              >
                Customize
              </Button>

              {customizedGraphNames.length > 0 && (
                <Button
                  onClick={() => {
                    setCustomizedGraphNames([]);
                  }}
                  variant="danger"
                  startIcon={<IoMdTrash size={14} />}
                >
                  Reset
                </Button>
              )}
            </div>
          </SectionFilter>

          <Typography
            color={colors.black}
            fontWeight={700}
            fontSize={14}
            lineHeight={'24px'}
          >
            Navigate to:
          </Typography>
          <div
            ref={navigationRef}
            style={{
              height: 60,
            }}
          >
            <ContainerButton>
              {deviceDataFormat.map(({ button, id }) => {
                if (!button || button.omit) return null;

                const { icon: Icon, text } = button;

                return (
                  <Button
                    key={id}
                    onClick={function () {
                      document.querySelector('#' + id)?.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}
                    startIcon={<Icon />}
                    size="sm"
                    variant="light-primary"
                    buttonStyle={{
                      marginRight: 10,
                    }}
                    disabled={button.disabled}
                  >
                    {text}
                  </Button>
                );
              })}
            </ContainerButton>
          </div>

          {meterData.isLoading && <Spinner variant="primary" />}

          {!meterData.isLoading && (
            <>
              {!targetMeterData?.length && <h3>Data not available</h3>}

              {customizedGraphsData.map(({ title, data }) => {
                return (
                  <ChartGroup key={title}>
                    <ChartTitle>{title}</ChartTitle>
                    <Card>
                      <Switch
                        onChange={(e) => {
                          const newValue = e.currentTarget.checked;
                          setIsLine(newValue);
                        }}
                        checked={isLine}
                        label="Lines"
                        beforeLabel="Bars"
                      />

                      {isLine && <Line options={options} data={data} />}
                      {!isLine && <Bar options={options} data={data} />}
                    </Card>
                  </ChartGroup>
                );
              })}

              {deviceDataFormat.map(({ title, datasets, id, scale, omit }, index) => {
                const hasData = targetMeterData?.some((meter) => {
                  return !_.isNil((meter as any)[datasets[0].selector]);
                });

                if (!hasData || omit) return null;

                const isQMargin = id === 'qMargin';
                const labels = isQMargin
                  ? qMarginDataList.map((data) => data.label)
                  : targetMeterData?.map((meter) =>
                      moment(new Date(meter!.time! * 1000)).format('MM/DD/YYYY HH:mm:ss')
                    );

                const data = {
                  labels,
                  datasets: datasets.map(({ title, selector }, index) => {
                    let data = isQMargin
                      ? qMarginDataList.map((data) => data.value)
                      : targetMeterData?.map(
                          (meter) => (meter as any)[selector]
                        );

                    return {
                      label: title,
                      data,
                      borderColor: index === 0 ? '#00A9E0' : '#0C2340',
                      backgroundColor: index === 0 ? '#00A9E0' : '#0C2340',
                      pointRadius: 0,
                      yAxisID: scale,
                    };
                  }),
                  line: true,
                };

                return (
                  <ChartGroup id={id} key={index}>
                    <ChartTitle>{title}</ChartTitle>
                    <Card>
                      <Switch
                        onChange={(e) => {
                          const newValue = e.currentTarget.checked;
                          setIsLine(newValue);
                        }}
                        checked={isLine}
                        label="Lines"
                        beforeLabel="Bars"
                      />

                      {isLine && <Line options={options} data={data} />}
                      {!isLine && <Bar options={options} data={data} />}
                    </Card>
                  </ChartGroup>
                );
              })}
            </>
          )}
        </Section>

        <Aside>
          <AsideTitle>
            <h3 style={{ color: theme.colors.black }}>About Asset</h3>
          </AsideTitle>

          <ContainerAboutAsset>
            <div>
              <label>Customer ID</label>
              <span>{deviceData?.meterId || 'Not attached'}</span>
            </div>
            <div>
              <label>Device ID</label>
              <span>{deviceData?.id || 'Not attached'}</span>
            </div>
            <div>
              <label>BrightLync ID</label>
              <span>{deviceData?.serialNo || '-'}</span>
            </div>
            <div>
              <label>Asset Group</label>
              <span>{deviceData?.group?.name || 'Not attached'}</span>
            </div>
            <div>
              <label>Address</label>
              <span>{deviceData?.address || 'Not attached'}</span>
            </div>
            <div>
              <label>Read date/time</label>
              <span>
                {deviceData?.data?.dTm
                  ? DateUtils.formatDateWithTmZn(
                      deviceData.data.dTm * 1000,
                      deviceData.data.TmZn,
                      deviceData.data.proto
                    )
                  : '-'}
              </span>
            </div>
            <div>
              <label>Ingestion Time</label>
              <span>
                {deviceData?.data?.ingestionTime
                  ? DateUtils.formatDateWithTmZn(
                      deviceData.data.ingestionTime,
                      deviceData.data.TmZn,
                      deviceData.data.proto
                    )
                  : '-'}
              </span>
            </div>

            <div>
              <label>Full Corrected Index Read</label>
              <span>
                {_.has(deviceData, 'data.fCV')
                  ? `${deviceData?.data?.fCV} ${volumeUnit?.replace('3', '³')}`
                  : '-'}
              </span>
            </div>
            <div>
              <label>Full Uncorrected Index Read</label>
              <span>
                {_.has(deviceData, 'data.fUV')
                  ? `${deviceData?.data?.fUV} ${volumeUnit?.replace('3', '³')}`
                  : '-'}
              </span>
            </div>
            <div>
              <label>Average Uncorrected Flow Rate</label>
              <span>
                {_.has(deviceData, 'data.cFAvg')
                  ? `${deviceData?.data?.cFAvg} ${flowUnit?.replace('3', '³')}`
                  : '-'}
              </span>
            </div>
            <div>
              <label>Max. Pressure</label>
              <span>
                {_.has(deviceData, 'data.maxPr.v')
                  ? `${deviceData?.data?.maxPr?.v} ${pressureUnit}`
                  : '-'}
              </span>
            </div>
            <div>
              <label>Min Flow Corr</label>
              <span>
                {_.has(deviceData, 'data.minFC.v')
                  ? `${deviceData?.data?.minFC?.v} ${flowUnit?.replace(
                      '3',
                      '³'
                    )}`
                  : '-'}
              </span>
            </div>
            <div>
              <label>Max. Temp</label>
              <span>
                {_.has(deviceData, 'data.maxTemp.v')
                  ? `${deviceData?.data?.maxTemp?.v} ${temperatureUnit}`
                  : '-'}
              </span>
            </div>
            <div>
              <label>Remaining Battery Life</label>
              <span>
                {_.has(batteryLifeData, 'percentage')
                  ? `${batteryLifeData?.percentage}%`
                  : '-'}
              </span>
            </div>
            <div>
              <label>Corr. Index Read (Daily)</label>
              <span>
                {_.has(deviceData, 'data.dayCV')
                  ? `${deviceData?.data?.dayCV} ${volumeUnit?.replace(
                      '3',
                      '³'
                    )}`
                  : '-'}
              </span>
            </div>
            <div>
              <label>Uncorr. Index Read (Daily)</label>
              <span>
                {_.has(deviceData, 'data.dayUV')
                  ? `${deviceData?.data?.dayUV} ${volumeUnit?.replace(
                      '3',
                      '³'
                    )}`
                  : '-'}
              </span>
            </div>
            <div>
              <label>Meter Size</label>
              <span>
                {deviceData?.data?.meterSize
                  ? `${deviceData?.data?.meterSize} `
                  : '-'}
              </span>
            </div>
            {ademModel === 'Tq' && (
              <div>
                <label>Q Margin</label>
                <span>
                  {currQMargin?.value === undefined
                    ? '-'
                    : currQMargin.value > 0
                      ? 'Pass'
                      : 'Check'}

                  {qMarginDataList && qMarginDataList.length > 0 && (
                    <Bedge
                      variant="light"
                      onClick={() => setMeterHealthReportModalOpen(true)}
                    >
                      Certificate
                    </Bedge>
                  )}
                </span>
              </div>
            )}
          </ContainerAboutAsset>

          {metersetImage && (
            <>
              <AsideTitle>
                <h3>Meterset Image</h3>
              </AsideTitle>

              <ContainerMeterset>
                <MetersetImage src={metersetImage} />
              </ContainerMeterset>
            </>
          )}

          <AsideTitle>
            <h3>Alarms</h3>
            <span>
              Showing {alarms?.length} from {alarms?.length} issues
            </span>
          </AsideTitle>

          {deviceData?.messages?.map((message, index) => {
            if (!message) return null;

            return (
              <CardAlarm key={index}>
                <Pill
                  containerStyle={{
                    width: 40,
                    height: 40,
                    position: 'absolute',
                    left: 16,
                    top: 20,
                  }}
                  color={AlarmUtils.getMessageData(message).color}
                  icon={<RiMapPin2Fill />}
                />
                <InfoBox
                  boxStyle={{ padding: 0 }}
                  rows={[
                    {
                      label: 'Urgent:',
                      value: message?.urgent ? 'Yes' : 'No',
                    },
                    {
                      label: 'Status:',
                      value: message?.status || '',
                    },
                  ]}
                />
                <RowSpace />
                {message?.alarms?.map((alarm, index, array) => {
                  const type = (TYPE_MAPPING as any)[alarm?.type!]
                    ? (TYPE_MAPPING as any)[alarm?.type!]
                    : `Unknown [${alarm?.type}]`;

                  let value = alarm?.v || '';
                  let limit = alarm?.lmt;
                  let threshold = alarm?.threshold;

                  if (type === TYPE_MAPPING[3]) {
                    if (value) {
                      value = DateUtils.daysToMonths(value);
                    }
                    if (!_.isNil(limit)) {
                      limit = DateUtils.daysToMonths(limit);
                    }
                    if (!_.isNil(threshold)) {
                      threshold = DateUtils.daysToMonths(threshold);
                    }
                  }

                  const rows = [
                    {
                      label: 'Alarm Date:',
                      value: new Date(alarm?.t! * 1000).toLocaleString(),
                    },
                    {
                      label: 'Type:',
                      value: type,
                    },
                    {
                      label: 'Value:',
                      value: value,
                    },
                  ];

                  if (!_.isNil(limit)) {
                    rows.push({
                      label: 'Limit:',
                      value: limit,
                    });
                  }
                  if (!_.isNil(threshold)) {
                    rows.push({
                      label: 'Threshold:',
                      value: threshold,
                    });
                  }

                  return (
                    <>
                      <InfoBox
                        boxStyle={{ padding: 0 }}
                        rows={rows}
                      />
                      <RowSpace />
                    </>
                  );
                })}
                {message?.comment && (
                  <InfoBox
                    boxStyle={{ padding: 0 }}
                    rows={[
                      {
                        label: 'Comment:',
                        value: message?.comment.split('\n').map((item, key) => {
                          return <span key={key}>{item}</span>;
                        }),
                      },
                    ]}
                  />
                )}
                <Row>
                  <Col>
                    <Row>
                      {message!.status === 'New' && (
                        <AlarmButton
                          type="button"
                          shape="round"
                          variant="light"
                          onClick={() =>
                            handleChangeStatusClick(
                              message!,
                              'Acknowledged',
                              index
                            )
                          }
                          loading={commentBeingChanged === index}
                        >
                          Acknowledge
                        </AlarmButton>
                      )}
                      {message!.status === 'Acknowledged' && (
                        <AlarmButton
                          type="button"
                          shape="round"
                          variant="light"
                          onClick={() =>
                            handleChangeStatusClick(message!, 'Resolved', index)
                          }
                          loading={commentBeingChanged === index}
                        >
                          Resolved
                        </AlarmButton>
                      )}
                      <AlarmButton
                        type="button"
                        shape="round"
                        variant="light"
                        onClick={() => handleCommentClick(message!)}
                      >
                        Comment
                      </AlarmButton>
                    </Row>
                  </Col>
                </Row>
              </CardAlarm>
            );
          })}
        </Aside>
      </Container>
    </>
  );
};

export default MeterId;
