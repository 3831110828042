import React, { HTMLInputTypeAttribute, forwardRef, useEffect, useRef, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { IconType } from "react-icons";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { MdOutlineDateRange } from "react-icons/md";
import { useTheme } from "styled-components";
import StringUtils from "../../../utils/StringUtils";
import {
  Container, DropdownContainer, ErrorMessage,
  Input,
  InputIcon,
  InputWrapper,
  Label, SupportText
} from "./style";

export type FormControlProps = {
  label: string;
  type?: HTMLInputTypeAttribute;
  name?: string;
  id?: string;
  startIcon?: React.ReactNode;
  error?: string;
  register?: UseFormRegister<any>;
  value?: string | number;
  disabled?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  dropdown?: React.ReactNode;
  rowReverse?: boolean;
  labelWidth?: number;
  inputContainerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  supportText?: string;
  min?: number | string;
  max?: number | string;
  readOnly?: boolean;
};

const FormControl = forwardRef<any, FormControlProps>(
  (
    {
      label,
      type = 'text',
      name,
      id,
      error,
      register,
      value,
      disabled,
      onBlur,
      onFocus,
      onChange,
      dropdown,
      rowReverse,
      labelWidth,
      inputContainerStyle,
      supportText,
      startIcon,
      min,
      onClick,
      max,
      readOnly,
      inputStyle
    },
    ref
  ) => {
    const theme = useTheme();
    const containerRef = useRef<HTMLInputElement>(null)

    const [currentType, setCurrentType] =
      useState<HTMLInputTypeAttribute>(type);

    const toggleCurrentType = (): void => {
      setCurrentType((currentType) =>
        currentType === "password" ? "text" : "password"
      );
    };

    const onDateIconClick = () => {

      if (containerRef.current) {

        const inputEl = containerRef.current.querySelector('input');
        console.debug(inputEl)
        inputEl?.showPicker();

      }

    }

    const Eye: IconType = currentType === "password" ? BsEye : BsEyeSlash;

    useEffect(() => {
      setCurrentType(type)
    }, [type])

    return (
      <Container ref={containerRef} style={inputContainerStyle}>
        <InputWrapper error={!!error} rowReverse={rowReverse}>
          <Input
            data-testid="form-control"
            style={inputStyle}
            disabled={disabled}
            error={!!error}
            placeholder={label}
            type={currentType}
            min={min}
            max={max}
            id={id || StringUtils.toCamelCase(label)}
            onClick={onClick}
            defaultValue={value}
            {...(register ? register(name || '', {
              onBlur,
              onChange
            }) : {
              name
            })}
            onFocus={onFocus}
            readOnly={readOnly}
          />
          <Label error={!!error} rowReverse={rowReverse} htmlFor={id} width={labelWidth}>
            {label}
          </Label>
          {startIcon &&
            <InputIcon>
              {startIcon}
            </InputIcon>}
          {type === "password" && (
            <InputIcon onClick={toggleCurrentType}>
              <Eye data-testid="password-icon" color={theme.colors.primaryText} />
            </InputIcon>
          )}
          {type === "date" && (
            <InputIcon onClick={onDateIconClick}>
              <MdOutlineDateRange data-testid="date-icon" color={theme.colors.primaryText} />
            </InputIcon>
          )}
          {dropdown && <DropdownContainer>{dropdown}</DropdownContainer>}
        </InputWrapper>
        {supportText && <SupportText>{supportText}</SupportText>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Container>
    );
  }
);

export default FormControl;
