import styled from 'styled-components';
import breakpoints from '../../../styles/breakpoints';

export const Container = styled.div`
  max-width: 669px;
`;

export const FormBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    width: 136px;
    height: 64px;

    @media (max-width: ${breakpoints.md}) {
      width: 100%;
    }

    @media (max-width: ${breakpoints.lg}) {
      width: 100%;
    }
  }
`;

export const ContainerTitle = styled.div`
  margin: 64px 0 16px 0;

  > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    color: ${(props) => props.theme.colors.black};
  }

  > div:nth-child(2) {
    > span {
      color: ${(props) => props.theme.colors.primary};

      > b {
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

export const ContainerText = styled.div`
  display: flex;

  margin: 40px 0 16px 0;
`;

export const ContainerAuthentication = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  width: 100%;

  @media (max-width: ${breakpoints.md}) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }

  @media (max-width: ${breakpoints.lg}) {
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    margin-bottom: 20px;

    gap: 10px;
  }

  > div:nth-child(1) {
    width: 100%;

    > div {
      height: 64px;

      @media (max-width: ${breakpoints.lg}) {
        width: 100%;
      }
    }
  }

  > div:nth-child(2) {
    width: 100%;

    display: flex;
    align-items: end;
    justify-content: end;
    @media (max-width: ${breakpoints.xs}) {
      align-items: center;
      justify-content: center;
    }
    @media (max-width: ${breakpoints.md}) {
      align-items: center;
      justify-content: center;
    }
    @media (max-width: ${breakpoints.lg}) {
      align-items: center;
      justify-content: center;
    }
    > img {
      width: 120px;
      height: auto;
    }
  }
`