import { forwardRef } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Input, Label, Switch as S } from './style';

type SwitchProps = {
  label: string;
  beforeLabel?: string;
  //   name?: string;
  //   id?: string;
  //   subLabel?: string;
  //   register?: UseFormRegister<any>;
  //   error?: string;
  //   value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  //   onClick?: React.MouseEventHandler<HTMLInputElement>;
  checked?: boolean;
};

const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    // { label, name, id, subLabel, register, error, value, onChange, checked },
    { label, beforeLabel, checked, onChange },
    ref
  ) => {
    return (
      <>
        <Label>
          <span>{beforeLabel}</span>
          <Input checked={checked} onChange={onChange} type={'checkbox'} />
          <S />
          <span>{label}</span>
        </Label>
      </>
    );
  }
);

export default Switch;
