import RangeAsset, {
  ConstructorParams,
  DeviceShadowAliasesAcceptedByRangeAsset,
} from './RangeAsset';
import ATPR from './atPr';

export default class TEMPHL extends RangeAsset {
  protected label: string = 'Temperature Alarm High Limit';
  public deviceShadowAlias: DeviceShadowAliasesAcceptedByRangeAsset = 'tempHL';

  constructor({ deviceShadow, register }: ConstructorParams) {
    super({ deviceShadow, register });

    this.setMeterInfo();
  }

  setMeterInfo() {
    const atmosphericPressureConfig = new ATPR({
      deviceShadow: this.deviceShadow,
      register: this.register,
    });

    this.step = 0.1;
    if (atmosphericPressureConfig.getUnitType() === 'imperial') {
      this.min = 68;
      this.max = 161.6;
      this.unitType = 'imperial';
      this.meterUnit = 'all';
    } else {
      this.min = 20;
      this.max = 72;
      this.step = 0.1;
      this.unitType = 'metric';
      this.meterUnit = 'all';
    }
  }
}
