import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';


export const InputWrapper = styled.div`
  padding: 0 19px;
  width: 240px;
  color: ${(props) => props.theme.colors.gray};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.gray};
  display: flex;
  overflow: hidden;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};

  & > * {
    font-size: 12px;
  }

  & svg {
    font-size: 18px;
  }

  & svg:last-child {

    color: ${props => props.theme.colors.primary};
    cursor: pointer;

  }
`;

export const Input = styled.input`
  background-color: ${(props) => props.theme.colors.white};

  border: none;
  outline: none;
  margin-left: 20px;
  height: 40px;
  display: flex;
  flex: 1;
`;
