import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import awsExports from '../aws-exports';

const clientApiKey = new AWSAppSyncClient({
  url: awsExports.aws_appsync_graphqlEndpoint,
  region: awsExports.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: awsExports.aws_appsync_apiKey,
  },
});

const clientCognito = (token: string) =>
  new AWSAppSyncClient({
    url: awsExports.aws_appsync_graphqlEndpoint,
    region: awsExports.aws_appsync_region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        return token;
      },
    },
    disableOffline: true,
    offlineConfig: {
      keyPrefix: 'private',
    },
  });

export default ({ isPublic = false, token = undefined }: { isPublic: boolean; token?: string | null; }): AWSAppSyncClient<any> => {
  if (isPublic) return clientApiKey;

  if (!token) throw new Error('Token is undefined');

  return clientCognito(token);
};
