export type AdemModel = 'S' | 'T' | 'PTZ' | 'PTZR' | 'Tq' | 'UniversalT';

export default class AdemUtils {
    /**
     * PrdTp from the shadow does not always provide the correct AdEM model (Universal-T is labeled as AdEM-T).
     * The most reliable way to know what the model type is is by using the FWV value instead.
     */
    static parseAdemModel = (fwVersion?: string | undefined): AdemModel | null => {
        if (!fwVersion) {
            return null;
        }

        // Split the AdEM fw version from the CCB fw version
        fwVersion = fwVersion.split('/')[0];

        const lastChar = fwVersion.slice(-1);
    
        switch (lastChar) {
          case '3':
            // AdEM-T / Universal-T
            if (fwVersion.includes("RT")) {
              return 'T';
            } else if (fwVersion.includes("MT")) {
              return 'UniversalT'
            }
    
            break;
          case '4':
            // AdEM-PTZ
            if (
              fwVersion.includes("NM") || fwVersion.includes("AM") ||
              fwVersion.includes("GM") || fwVersion.includes("SM")
            ) {
              return 'PTZ';
            }
    
            break;
          case '5':
            // AdEM-S
            if (fwVersion.includes("RS")) {
              return 'S';
            }
    
            break;
          case '6':
            // AdEM-PTZ-R
            if (
              fwVersion.includes("NM") || fwVersion.includes("AM") ||
              fwVersion.includes("GM") || fwVersion.includes("SM")
            ) {
              return 'PTZR';
            }
    
            break;
          case '7':
            // AdEM-Tq
            if (fwVersion.includes("MQ")) {
              return 'Tq';
            }
    
            break;
          default:
            break;
        }
    
        return null;
      };
};