import {
  useEffect,
  useRef,
  useState
} from 'react';
import { useTheme } from 'styled-components';
import { Device } from '../../API';
import AlarmUtils from '../../utils/AlarmUtils';

interface MapProps extends google.maps.MapOptions {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  onDragEnd?: (map: google.maps.Map) => void;
  onDragStart?: (map: google.maps.Map) => void;
  onZoomChanged?: (map: google.maps.Map) => void;
  devices?: (Device | null)[];
  dynamicMap?: boolean;
}

const Map: React.FC<MapProps> = ({ onClick, onDragEnd, onDragStart, onZoomChanged, onIdle, devices, dynamicMap }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const theme = useTheme();
  const infowindow = useRef(new window.google.maps.InfoWindow());
  const markers = useRef<google.maps.Marker[]>([]);
  const northAmericaLatLong = {
    lat: 47.116386,
    lng: -101.299591,
  }

  const hideAllMarkers = () => {
    markers.current.forEach(marker => {
      marker.setMap(null);
    })
  }

  const deleteAllMarkers = () => {
    hideAllMarkers();
    markers.current.length = 0;
  }

  const fitBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.current.forEach(marker => {
      bounds.extend(marker.getPosition() as google.maps.LatLng);
    })
    map?.fitBounds(bounds, 20);
  }

  const showMarkers = () => {
    deleteAllMarkers();
    infowindow.current.close()
    devices?.forEach((device) => {
      if (device?.latitude && device?.longitude) {

        const alarms = device?.messages?.reduce((alarms, message) => {
          if (!message?.alarms) return alarms;
          const combined = alarms.concat(message?.alarms as any);
          return combined;
        }, []);

        const marker = new window.google.maps.Marker({
          position: {
            lng: device.longitude,
            lat: device.latitude,
          },
        });

        const { color, flashing } = AlarmUtils.getDeviceData(device)

        if (flashing) {
          marker.setAnimation(google.maps.Animation.BOUNCE);
        }

        marker.setOptions({
          icon: {
            path: 'M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z',
            fillColor: color,
            fillOpacity: 1,
            scale: 2,
            strokeWeight: 3,
            strokeColor: theme.colors.white,
            anchor: new google.maps.Point(15, 30),
          },
        });

        marker.addListener('click', () => {
          infowindow.current.setContent(`
        <div style="width:250px; height:150px; padding: 5px">
          Customer ID: <strong>${device.meterId}</strong> <br>
          Address: <strong>${device.address}</strong> <br>
          User: <strong>${device.user_device?.user?.email || 'Not Attached'
            }</strong> <br>
          Alarms: <strong>${alarms?.length}</strong> <br><br>
          <a class="btn" href="assets/${device.id}">View</a>
        </div>
        `);
          infowindow.current.open({
            anchor: marker,
            shouldFocus: false,
          });
        });

        markers.current.push(marker);
      }
    });

    markers.current.forEach(marker => {
      marker.setMap(map!);
    })

    if (!dynamicMap) {
      fitBounds();
    }
  };

  useEffect(() => {
    if (ref.current && !map) {
      const center: google.maps.LatLngLiteral = {
        ...northAmericaLatLong
      };

      const map = new window.google.maps.Map(ref.current, {
        center,
        zoom: 4,
        styles: [
          {
            elementType: 'geometry',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#bdbdbd',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
              {
                color: '#dadada',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
            ],
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#c9c9c9',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
        ],
        disableDefaultUI: true,
      })

      setMap(
        map
      );
    }
  });

  useEffect(() => {
    if (ref.current && map) {
      infowindow.current.close();
      showMarkers();
    }
  }, [devices]);

  useEffect(() => {
    if (map) {
      showMarkers();
      ['click', 'idle'].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );
      map.addListener('click', (e: google.maps.MapMouseEvent) => {
        infowindow.current.close();
        if (onClick) {
          onClick(e);
        }
      });

      if (onDragEnd) {
        map.addListener('dragend', () => onDragEnd(map));
      }

      if (onDragStart) {
        map.addListener('dragstart', () => onDragStart(map));
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }

      if (onZoomChanged) {
        map.addListener('zoom_changed', () => onZoomChanged(map));

      }

    }
  }, [map, onClick, onDragEnd, onDragStart]);

  return (
    <div
      ref={ref}
      style={{ width: '100%', height: '100%', position: 'relative' }}
    >
    </div>
  );
};

export default Map;
