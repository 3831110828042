import Button from '../../../components/Button/Button';
import FormControl from '../../../components/Forms/FormControl/FormControl';

import {
  Container,
  ContainerAuthentication,
  ContainerText,
  ContainerTitle,
  FormBottom,
} from './style';
const LoginMFA = () => {
  return (
    <form>
      <Container>
        <ContainerTitle>
          <h1> MFA </h1>

          <ContainerText>
            <span>For the login enter the authentication code.</span>
          </ContainerText>
        </ContainerTitle>

        <ContainerAuthentication>
          <FormControl label="Authentication code" id="" name="" />s
        </ContainerAuthentication>

        <FormBottom>
          <span>Enable new MFA</span>

          <Button>Enter</Button>
        </FormBottom>
      </Container>
    </form>
  );
};

export default LoginMFA;
