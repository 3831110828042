import styled from 'styled-components';
import Button from '../../components/Button/Button';
import Col from '../../components/Layout/Col/Col';
import Row from '../../components/Layout/Row/Row';
import breakpoints from '../../styles/breakpoints';

// export const Row = styled.div`
//   margin-bottom: 5px;

//   @media screen and (min-width: ${(props) => breakpoints.lg}) {
//     display: flex;
//   }
// `;

// export const Col = styled.div`
//   flex: 1 0 0%;
//   color: ${(props) => props.theme.colors.black};
// `;

export const Container = styled.div`
  margin-top: 20px;

  @media screen and (min-width: ${(props) => breakpoints.lg}) {
    display: flex;
  }
`;

export const Section = styled.div`
  @media screen and (min-width: ${(props) => breakpoints.lg}) {
    padding-right: 30px;
    width: 70%;
  }
`;

export const Aside = styled.div`
  @media screen and (min-width: ${(props) => breakpoints.lg}) {
    width: 30%;
  }
`;

export const SectionFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 50px;
  justify-content: space-between;

  > div {
    > div,
    > button {
      margin-bottom: 20px;
    }

    @media screen and (min-width: ${(props) => breakpoints.lg}) {
      display: flex;
      gap: 15px;
    }
  }

  Button {
    padding: 10px;
  }
`;

export const RadioGroupInline = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  & select {
    min-width: 135px;
  }

  > div {
    margin-bottom: 0;
  }
`;

export const ChartGroup = styled.div`
  padding-top: 50px;
`;

export const Card = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  padding: 20px;
  font-size: 12px;
  margin-bottom: 15px;
`;

export const CardAlarm = styled(Card)`
  background-repeat: no-repeat;
  background-position: 15px 15px;
  padding-left: 70px;
  position: relative;

  &:nth-child(2n + 3) {
    @media screen and (min-width: ${(props) => breakpoints.md}) {
      margin-left: 5%;
    }

    @media screen and (min-width: ${(props) => breakpoints.lg}) {
      margin-left: 0;
    }
  }

  & > ${Row as any}:last-child ${Col as any}:first-child {
    max-width: 100%;

    & button:first-child {
      margin-right: 10px;
    }
  }

  div${Col as any}:first-child {
    max-width: 37%;
  }

  @media screen and(min-width: ${(props) => breakpoints.md}) {
    width: 47.5%;
    float: left;
  }

  @media screen and(min-width: ${(props) => breakpoints.lg}) {
    width: 100%;
    float: initial;
  }

  @media screen and(min-width: ${(props) => breakpoints.lg}) {
    width: 100%;
  }

  div.space {
    margin-top: 30px;
  }
`;

export const ChartTitle = styled.div`
  margin-bottom: 15px;
  color: ${(props) => props.theme.colors.black};
`;

export const RowSpace = styled.div`
  height: 1px;
  width: 100%;
  margin: 20px 0;
  background-color: ${(props) => props.theme.colors.gray};
`;

export const AsideTitle = styled.div`
  font-size: 13px;
  margin-bottom: 15px;

  h3 {
    font-size: 16px;
    font-weight: 500;
  }

  span {
    font-size: 12px;
    color: #666;
  }
`;

export const Label = styled.label`
  color: #666;
`;

export const DevicesLogo = styled.div`
  background-color: #00a9e01a;
  padding: 26px 21px;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;

  & img {
    filter: invert(57%) sepia(60%) saturate(5366%) hue - rotate(164deg)
      brightness(101%) contrast(101%);
    font-size: 22px;
  }
`;

export const DeviceInfo = styled.div`
  margin-right: 24px;
`;

export const DeviceTitle = styled.h1`
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.black};
`;

export const DeviceID = styled.h2`
  font-size: 24px;
  line-height: 40px;
  color: ${(props) => props.theme.colors.black};
`;

export const AlarmsBedge = styled.span`
  background-color: rgba(221, 27, 27, 0.1);
  border-radius: 58px;
  padding: 14px 6px;
  color: #dd1b1b;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  display: inline - block;

  @media screen and(min-width: ${(props) => breakpoints.lg}) {
    text-align: left;
    margin-top: 0;
  }
`;

export const AlarmButton = styled(Button)`
  padding: 6px 8px;
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
  border-radius: ${(props) => props.theme.borderRadius};
  margin-right: 10px;
`;

export const TableDropdown = styled.div`
  overflow: visible !important;

  display: flex;
  flex-direction: column;
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.neutral};
  padding: 16px;
  z-index: 5;
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.white};
`;

export const DropdownItem = styled.span`
  font-size: 12px;
  line-height: 20px;
  padding: 8px 0;
  cursor: pointer;
  color: ${(props) => props.theme.colors.black};

  &:last-child {
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }
`;

export const WantToRemoveMsg = styled.span`
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 64px;
  display: block;
  overflow: visible !important;
  display: flex;
  flex-direction: column;
`;

export const ContainerMeterset = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  padding: 20px;
  height: auto;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MetersetImage = styled.img`
  width: 165px;
  height: auto;
  border-radius: 5px;
`;


export const ContainerAboutAsset = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  padding: 20px;
  padding-top: 10px;
  font-size: 12px;
  height: auto;

  margin-bottom: 28px;

  display: flex;
  flex-direction: column;

  div {
    display: flex;
    justify-content: space-between;
    & + * {
      margin-bottom: 5px;
    }

    label {
      font-size: 12px;
      width: 100%;
      color: ${(props) => props.theme.colors.black};
    }
    span {
      font-size: 12px;
      width: 100%;
      padding: 0 0 0 15px;
      color: ${(props) => props.theme.colors.black};
    }
  }
`;

export const Bedge = styled(Button)`
  padding: 6px 8px !important;
  font-size: 12px;
  line-height: 20px;
  min-height: 32px;
  height: 32px;
  border-radius: ${(props) => props.theme.borderRadius};
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  white-space: break-spaces;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  top: 0;
  padding: 10px 0;
  background-color: ${(props) => props.theme.colors.lightlyGray};
  z-index: 5;
`;

export const ContainerCheckBox = styled.div`
  input {
    cursor: not-allowed;
    opacity: 0.5;
    color: red;
  }

  label {
    color: ${(props) => props.theme.colors.lightGray};
    cursor: not-allowed;
  }
`;
