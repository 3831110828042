import gql from 'graphql-tag';
import api from '../api';
import Auth from './Auth';

export default class API {

    static async query<Variables = any, ReturnType = any>(
        query: string,
        variables: Variables
    ): Promise<{
        data: ReturnType
    }> {
        const token = Auth.getToken();
        const data = (await api({ isPublic: false, token }).query({
            query: gql(query),
            variables: { ...variables },
        })) as {
            data: ReturnType
        };

        return data;
    }

    static async mutate<Variables, ReturnType = any>(
        mutation: string,
        variables: Variables
    ): Promise<ReturnType | any> {
        const token = Auth.getToken();

        const data = (await api({ isPublic: false, token }).mutate({
            mutation: gql(mutation),
            variables: { ...variables },
        })) as any;

        return data;

    }

}