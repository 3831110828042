import styled from 'styled-components';
import Button from '../../components/Button/Button';

type MapStates = {
  fullMap?: boolean;
  showingMap?: boolean;
};

export const GeneralContainer = styled.div<MapStates>`
  transition: all 300ms;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: inline-flex;
    gap: ${(props) => (props.fullMap || !props.showingMap ? '0' : '24px')};

    & > div:nth-child(2) {
      ${(props) =>
        props.fullMap
          ? 'width:  0%;'
          : !props.showingMap
          ? 'width: 100%;'
          : 'width: 50%;'}
    }
  }
`;

export const DataOptions = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  margin-bottom: 1rem;
  & button {
    font-size: 12px;

    & > svg {
      margin-right: 5px;
    }
  }

  & > button {
    white-space: break-spaces;
    margin-left: 24px;
  }

  & > *:first-child {
    margin-right: auto;
  }
`;

export const DataContainer = styled.div<MapStates>`
  ${(props) => (props.fullMap ? 'opacity: 0;' : 'opacity: 1;')}
  overflow: hidden;
  transition: all 300ms;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    ${(props) =>
      props.fullMap
        ? 'width:  0%;'
        : !props.showingMap
        ? 'width: 100%;'
        : 'width: 100%;'}
  }
`;

export const ModalButton = styled(Button)`
  padding: 6px 8px !important;
  font-size: 12px;
  line-height: 20px;
  height: 32px;
  min-height: 32px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const TableDropdown = styled.div`
  overflow: visible !important;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.neutral};
  padding: 16px;
  z-index: 5;
`;

export const DropdownItem = styled.span`
  font-size: 12px;
  line-height: 20px;
  padding: 8px 0;
  cursor: pointer;
  &:last-child {
    /* color: ${(props) => props.theme.colors.danger}; */
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }
`;

export const WantToRemoveMsg = styled.span`
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 64px;
  display: block;
`;

export const MapButtons = styled.div`
  position: absolute;
  display: flex;
  bottom: 5px;
  justify-content: space-between;
  padding: 0 5px;

  & button {
    padding: 10px 16px;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const MapRightButtons = styled.div`
  display: flex;

  button {
    margin-left: 8px;
  }
`;
