import Button from '../../../components/Button/Button';
import {
  Container,
  Content,
  FormBottom,
  HeaderTitle,
  RometLogo,
  Footer,
  ContainerContent,
  Line,
} from './style';

const VerifyEmail = () => {
  return (
    <Container>
      <Content>
        <HeaderTitle>
          <div>
            <h1>Welcome to</h1>
            <RometLogo src="/images/romet-logo-white.png" />
          </div>
        </HeaderTitle>

        <ContainerContent>
          <h1>Verify your e-mail to finish registration!</h1>

          <div>
            <p>
              You've been registered to the Romet portal, please confirm that
              <b> email@email.com</b> is your email address by clicking on the
              button below and coplete your registration.
            </p>
          </div>

          <FormBottom>
            <Button size='md' buttonStyle={{ width: ' 100%', marginBottom: 67 }}>
              Confirm Your Email
            </Button>
          </FormBottom>
        </ContainerContent>

        <Footer>
          <div>
            <h1>Need Help?</h1>
            <span>
              Contact us at <b> romet@rometlimited.com</b>
            </span>
          </div>

          <Line />

          <div>
            <div>
              <img src="/images/logo-romet.png" alt="" />
              <span>© 2020 Romet Limited</span>
            </div>

            <div>
              <span>
                5030 Timberlea Blvd Mississauga, Ontario L4W 2S5 Canada
              </span>
            </div>
          </div>
        </Footer>
      </Content>
    </Container>
  );
};

export default VerifyEmail;
