import styled from "styled-components";

type IconPillProps = {
    color: string;
}

export const IconPill = styled.div<IconPillProps>`

    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: ${props => props.color}1A;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
        color: ${props => props.color};
        font-size: 20px;
        
    }


`;