import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Device, ExportMeterDataQuery, ExportMeterDataQueryVariables } from '../../../API';
import errorMessages from '../../../config/errorMessages';
import { useAuth } from '../../../contexts/Auth';
import { exportMeterData } from '../../../graphql/queries';
import Alert from '../../Alert/Alert';
import FormControl from '../../Forms/FormControl/FormControl';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import FormRow from '../../Forms/FormRow/FormRow';
import Radio from '../../Forms/Radio/Radio';
import RadioGroup from '../../Forms/RadioGroup/RadioGroup';
import Modal, { ModalOpeningProps } from '../Modal';

type Props = {
    devicesToBeExported: Array<Device | null | undefined>;
}

const exportDataSchema = yup.object({
    format: yup.string().required(errorMessages.required),
    frequency: yup.string().required(errorMessages.required),
    fromDate: yup
        .string()
        .matches(/\d{4}-\d{2}-\d{2}/g, 'Not a valid date')
        .required(errorMessages.required),
    toDate: yup
        .string()
        .matches(/\d{4}-\d{2}-\d{2}/g, 'Not a valid date')
        .required(errorMessages.required),
});

const start = new Date();
start.setDate(start.getDate() - 30);
const defaultToDate = new Date();
const defaultFromDate = start;

const ExportAssetModal: React.FC<ModalOpeningProps & Props> = ({ open, closeModalFunc, devicesToBeExported }) => {

    const { signedQueryRequest } = useAuth();
    const [exportingDevice, setExportingDevice] = useState(false);
    const [exportMeterDataAlertMsg, setExportMeterDataAlertMsg] = useState({
        msg: '',
        variant: 'success'
    });

    const { refactoredUser } = useAuth();

    const formats = [
        {
            label: 'CSV',
            value: 'csv'
        },
        {
            label: 'JSON',
            value: 'json'
        },
    ]

    if (refactoredUser?.isAdmin() || refactoredUser?.username.includes('@onegas.com')) {
        formats.push({
            label: 'TXT',
            value: 'cfx'
        });
    }

    const {
        register,
        formState: { errors },
        trigger,
        getValues
    } = useForm<
        ExportMeterDataQueryVariables & {
            filename: string;
        }
    >({
        resolver: yupResolver(exportDataSchema),
    });

    const handleExportDevicesSubmit = async () => {
        const hasNoErrors = await trigger();

        if (!hasNoErrors) return;

        const { format, frequency, fromDate, toDate } = getValues();

        setExportingDevice(true);
        try {
            const data = await signedQueryRequest<
                ExportMeterDataQueryVariables,
                {
                    data: ExportMeterDataQuery;
                }
            >(exportMeterData, {
                deviceIds: [...devicesToBeExported.map((device) => device!.id)],
                format,
                frequency,
                fromDate: Math.floor(new Date(`${fromDate}T00:00:00`).getTime() / 1000),
                toDate: Math.floor(new Date(`${toDate}T23:59:59`).getTime() / 1000),
            });

            const success = data.data.exportMeterData.success;

            if (success) {
                const msg = data.data.exportMeterData.message || '';
                setExportMeterDataAlertMsg({
                    msg,
                    variant: 'success'
                });
            }

        } catch (e) {
            const msg = 'Maximum difference between From Date and To Date should be 31 days';
            setExportMeterDataAlertMsg({
                msg,
                variant: 'danger'
            });
        }

        setExportingDevice(false);

    };

    return <Modal
        title={`Export asset data for ${devicesToBeExported.length === 1
            ? devicesToBeExported[0]?.id
            : 'all assets'
            }`}
        description="Export asset data for CSV or JSON"
        open={open}
        closeModalFunc={closeModalFunc}
        buttonText="Export asset(s) data"
        onSubmitBtnClick={handleExportDevicesSubmit}
        buttonLoading={exportingDevice}
    >
        <form>
            <FormGroup>
                <FormRow>
                    <FormControl
                        name="fromDate"
                        label="From"
                        type={'date'}
                        id="from"
                        value={moment(defaultFromDate).format('YYYY-MM-DD')}
                        register={register}
                        error={errors.fromDate?.message}
                    />
                    <FormControl
                        register={register}
                        error={errors.toDate?.message}
                        name="toDate"
                        label="To"
                        value={moment(defaultToDate).format('YYYY-MM-DD')}
                        type={'date'}
                        id="to"
                    />
                </FormRow>
            </FormGroup>
            <FormGroup>
                <RadioGroup label="Format">
                    {formats.map(({ label, value }, index) => (
                        <Radio
                            checked={index === 0}
                            register={register}
                            error={errors.format?.message}
                            id={label}
                            label={label}
                            name="format"
                            value={value}
                        />
                    ))}
                </RadioGroup>
            </FormGroup>
            <FormGroup>
                <RadioGroup label="Interval">
                    <Radio
                        register={register}
                        error={errors.frequency?.message}
                        id="daily"
                        label="Daily"
                        name="frequency"
                        value="daily"
                        checked
                    />
                    <Radio
                        register={register}
                        id="hourly"
                        label="Hourly"
                        name="frequency"
                        value="hourly"
                    />
                </RadioGroup>
            </FormGroup>
        </form>
        {exportMeterDataAlertMsg.msg && <Alert variant={exportMeterDataAlertMsg.variant}>{exportMeterDataAlertMsg.msg}</Alert>}
    </Modal>;
};

export default ExportAssetModal;