import styled, { css } from "styled-components";

type Variant = 'danger' | 'success' | undefined | string

type ContainerProps = {
    variant?: Variant
}

const getContainerBGColor = (variant: Variant) => {

    switch (variant) {

        case 'danger':
            return css`${props => props.theme.colors.danger + 33}`;
        default:
            return css`${props => props.theme.colors.success + 33}`;

    }

}

const getTextColor = (variant: Variant) => {

    switch (variant) {
        case 'danger':
            return css`${props => props.theme.colors.danger}`;
        case 'success':
            return css`${props => props.theme.colors.success}`;
        default:
            return css`${props => props.theme.colors.success}`;

    }

}

export const Container = styled.div<ContainerProps>`
    width: 100%;
    text-align: center;
    background-color: ${props => getContainerBGColor(props.variant)};
    padding: 12px;
    border-radius: ${props => props.theme.borderRadius};
    color: ${props => getTextColor(props.variant)}
`;

export const Text = styled.span`

    font-weight: bold;
    font-size: 14px;

`;
