import RangeAsset, {
  ConstructorParams,
  DeviceShadowAliasesAcceptedByRangeAsset,
  MeterSizes,
} from './RangeAsset';
import ATPR from './atPr';

type MetricOrImperialData = {
  [key in MeterSizes]?: {
    min: number;
    max: number;
  };
};

export default class UFHL extends RangeAsset {
  protected label: string = 'Uncorrected Alarm Flow High Limit';
  public deviceShadowAlias: DeviceShadowAliasesAcceptedByRangeAsset = 'uFHL';

  constructor({ deviceShadow, register }: ConstructorParams) {
    super({ deviceShadow, register });

    this.setMeterInfo();
  }

  setMeterInfo() {
    const atmosphericPressureConfig = new ATPR({
      deviceShadow: this.deviceShadow,
      register: this.register,
    });

    const imperialData: MetricOrImperialData = {
      RM600: {
        min: 420,
        max: 720,
      },
      RM1000: {
        min: 700,
        max: 1200,
      },
      RM1500: {
        min: 1050,
        max: 1800,
      },
      RM2000: {
        min: 1400,
        max: 2400,
      },
      RM3000: {
        min: 2100,
        max: 3600,
      },
      RM5000: {
        min: 3500,
        max: 6000,
      },
      RM7000: {
        min: 4900,
        max: 8400,
      },
      RM11000: {
        min: 7700,
        max: 13200,
      },
      RM16000: {
        min: 11200,
        max: 19200,
      },
      RM23000: {
        min: 16100,
        max: 27600,
      },
      RM25000: {
        min: 17500,
        max: 30000,
      },
      RM38000: {
        min: 26600,
        max: 45600,
      },
      RM56000: {
        min: 39200,
        max: 67200,
      },
      '1.5MLMMA': {
        min: 1050,
        max: 1800,
      },
      '3MLMMA': {
        min: 2100,
        max: 3600,
      },
      '5MLMMA': {
        min: 3500,
        max: 6000,
      },
      '7MLMMA': {
        min: 4900,
        max: 8400,
      },
      '11MLMMA': {
        min: 7700,
        max: 13200,
      },
      '16MLMMA': {
        min: 11200,
        max: 19200,
      },
      '23MLMMA': {
        min: 16100,
        max: 27600,
      },
      '38MLMMA': {
        min: 26600,
        max: 45600,
      },
      '56MLMMA': {
        min: 39200,
        max: 67200,
      },
      '102MLMMA': {
        min: 78400,
        max: 134400,
      },
      RMT600: {
        min: 420,
        max: 720,
      },
      RMT1000: {
        min: 700,
        max: 1200,
      },
      RMT1500: {
        min: 1050,
        max: 1800,
      },
      RMT2000: {
        min: 1400,
        max: 2400,
      },
      RMT3000: {
        min: 2100,
        max: 3600,
      },
      RMT5000: {
        min: 3500,
        max: 6000,
      },
      RMT7000: {
        min: 4900,
        max: 8400,
      },
      RMT11000: {
        min: 7700,
        max: 13200,
      },
      RMT16000: {
        min: 11200,
        max: 19200,
      },
      RMT23000: {
        min: 16100,
        max: 27600,
      },
      '8C175I': {
        min: 560,
        max: 960,
      },
      '11C175I': {
        min: 770,
        max: 1320,
      },
      '15C175I': {
        min: 1050,
        max: 1800,
      },
      '2M175I': {
        min: 1400,
        max: 2400,
      },
      '3M175I': {
        min: 2100,
        max: 3600,
      },
      '5M175I': {
        min: 3500,
        max: 6000,
      },
      '7M175I': {
        min: 4900,
        max: 8400,
      },
      '11M175I': {
        min: 7700,
        max: 13200,
      },
      '16M175I': {
        min: 11200,
        max: 19200,
      },
      '23M175I': {
        min: 16100,
        max: 27600,
      },
      '23M232I': {
        min: 16100,
        max: 27600,
      },
      '38M175I': {
        min: 26600,
        max: 45600,
      },
      '1M300I': {
        min: 700,
        max: 1200,
      },
      '3M300I': {
        min: 2100,
        max: 3600,
      },
      '56M175I': {
        min: 39200,
        max: 67200,
      },
    };

    const metricData: MetricOrImperialData = {
      G10: {
        min: 11.2,
        max: 19.2,
      },
      G16: {
        min: 21,
        max: 36,
      },
      G25: {
        min: 28,
        max: 48,
      },
      G40: {
        min: 38.5,
        max: 66,
      },
      G65: {
        min: 59.5,
        max: 102,
      },
      G100: {
        min: 98,
        max: 168,
      },
      G160: {
        min: 175,
        max: 300,
      },
      G250: {
        min: 315,
        max: 540,
      },
      G400: {
        min: 455,
        max: 780,
      },
      'G400-150': {
        min: 490,
        max: 840,
      },
      G650: {
        min: 770,
        max: 1320,
      },
      G1000: {
        min: 1120,
        max: 1920,
      },
      RM16: {
        min: 11.2,
        max: 19.2,
      },
      RM30: {
        min: 21,
        max: 36,
      },
      RM40: {
        min: 28,
        max: 48,
      },
      RM55: {
        min: 38.5,
        max: 66,
      },
      RM85: {
        min: 59.5,
        max: 102,
      },
      RM140: {
        min: 98,
        max: 168,
      },
      RM200: {
        min: 140,
        max: 240,
      },
      RM300: {
        min: 210,
        max: 360,
      },
      RM450: {
        min: 315,
        max: 540,
      },
      RM650: {
        min: 455,
        max: 780,
      },
      RM700: {
        min: 490,
        max: 840,
      },
      RM1100: {
        min: 770,
        max: 1320,
      },
      RM1600: {
        min: 1120,
        max: 1920,
      },
      '1.5M(40)': {
        min: 28,
        max: 48,
      },
      '3M(85)': {
        min: 59.5,
        max: 10200,
      },
      '5M(140)': {
        min: 98,
        max: 168,
      },
      '7M(200)': {
        min: 140,
        max: 240,
      },
      '11M(300)': {
        min: 210,
        max: 360,
      },
      '16M(450)': {
        min: 315,
        max: 540,
      },
      RMT16: {
        min: 11.2,
        max: 19.2,
      },
      RMT30: {
        min: 21,
        max: 36,
      },
      RMT40: {
        min: 28,
        max: 48,
      },
      RMT55: {
        min: 38.5,
        max: 66,
      },
      RMT85: {
        min: 59.5,
        max: 102,
      },
      RMT140: {
        min: 98,
        max: 168,
      },
      RMT200: {
        min: 140,
        max: 240,
      },
      RMT300: {
        min: 210,
        max: 360,
      },
      RMT450: {
        min: 315,
        max: 540,
      },
      RMT650: {
        min: 455,
        max: 780,
      },
      '8C175M': {
        min: 16.1,
        max: 27.6,
      },
      '11C175M': {
        min: 21.7,
        max: 37.2,
      },
      '15C175M': {
        min: 28,
        max: 48,
      },
      '2M175M': {
        min: 38.5,
        max: 66,
      },
      '3M175M': {
        min: 59.5,
        max: 102,
      },
      '5M175M': {
        min: 98,
        max: 168,
      },
      '7M175M': {
        min: 140,
        max: 240,
      },
      '11M175M': {
        min: 210,
        max: 360,
      },
      '16M175M': {
        min: 315,
        max: 540,
      },
      '23M175M': {
        min: 455,
        max: 780,
      },
      '23M232M': {
        min: 455,
        max: 780,
      },
      '38M175M': {
        min: 770,
        max: 1320,
      },
      '1M300M': {
        min: 21,
        max: 36,
      },
      '3M300M': {
        min: 59.5,
        max: 102,
      },
      '56M175M': {
        min: 1120,
        max: 1920,
      },
    };
    const meterSize = this.deviceShadow.state.reported.MtrSz.trim().replace(
      / +/g,
      ''
    ) as MeterSizes;
    const isImperial = atmosphericPressureConfig.getUnitType() === 'imperial';

    if (isImperial) {
      this.step = 1;
    } else {
      this.step = 0.01;
    }

    const chosenData = isImperial ? imperialData : metricData;
    const { min, max } = chosenData[meterSize] ?? {
      min: isImperial ? 0 : 0.0,
      max: isImperial ? 9999999 : 99999.99,
    };

    this.unitType = isImperial ? 'imperial' : 'metric';
    this.meterUnit = 'all';
    this.min = min;
    this.max = max;
  }

  public _correctValue(value: number): number {
    let corrected = value;

    if (this.unitType === 'metric') {
      const decimalNumbersPattern = /(\.)(\d{1,2})/;
      const valueString = value.toString();

      if (!decimalNumbersPattern.test(valueString)) {
        return Number(valueString + '.55');
      }

      const [_, __, decimalNumbers] = [
        ...valueString.match(decimalNumbersPattern)!,
      ];

      if (decimalNumbers.length === 1) {
        return Number(valueString + '5');
      } else {
        return corrected;
      }
    }

    return corrected;
  }
}
