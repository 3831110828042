import { ChangeEvent, forwardRef } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { InputWrapper, Input } from './style';

type SearchInputProps = {
  placeholder: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  dropdownIcon?: boolean;
  onDropdownClick?: React.MouseEventHandler<SVGElement>;
};

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(({ placeholder, onChange, onFocus, onBlur, dropdownIcon, onDropdownClick }, ref) => {
  return (
    <>
      <InputWrapper>
        <AiOutlineSearch />
        <Input ref={ref} type="text" onFocus={onFocus} onBlur={onBlur} onChange={onChange} placeholder={placeholder} />
        {dropdownIcon && <IoIosArrowDown onClick={onDropdownClick} />}
      </InputWrapper>
    </>
  );
})

export default SearchInput;
