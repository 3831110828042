import React, { PropsWithChildren } from 'react';

import { Container, Text } from './style';

type Props = {
    variant?: 'danger' | 'success' | string
}

const Alert: React.FC<PropsWithChildren<Props>> = ({ children, variant }) => {
    return <Container variant={variant}>
        <Text>{children}</Text>
    </Container>;
}

export default Alert;