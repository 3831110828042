import RangeAsset, {
  ConstructorParams,
  DeviceShadowAliasesAcceptedByRangeAsset,
  RangeList,
} from './RangeAsset';
import ATPR from './atPr';

let moveDecimal = require('move-decimal-point');

export default class PRHL extends RangeAsset {
  protected label: string = 'Pressure Alarm High Limit';
  public deviceShadowAlias: DeviceShadowAliasesAcceptedByRangeAsset = 'prHL';

  private rangeList: RangeList[] = [
    {
      min: 0,
      max: 2030.53,
      type: 'imperial',
    },
    {
      min: 0,
      max: 14000,
      type: 'metric',
      meterUnit: 'kpa',
    },
    {
      min: 0,
      max: 140,
      type: 'metric',
      meterUnit: 'bar',
    },
  ];

  constructor({ deviceShadow, register }: ConstructorParams) {
    super({ deviceShadow, register });

    this.setMeterInfo();
  }

  setMeterInfo() {
    const atPr = new ATPR({
      deviceShadow: this.deviceShadow,
      register: this.register,
    });

    const meterUnit = atPr.getMeterUnit();

    const chosen = this.rangeList.find((range) => {
      if (meterUnit === 'all') {
        return range.type === 'imperial';
      } else {
        return range.type === 'metric' && range.meterUnit === meterUnit;
      }
    });

    console.log(chosen);

    if (chosen?.type === 'imperial') {
      this.step = 0.01;
    }

    if (chosen?.type === 'metric' && chosen?.meterUnit === 'kpa') {
      this.step = 0.1;
    }

    if (chosen?.type === 'metric' && chosen?.meterUnit === 'bar') {
      this.step = 0.001;
    }

    this.unitType = chosen!.type;
    this.meterUnit = chosen?.type === 'imperial' ? 'all' : chosen!.meterUnit;
    this.min = chosen!.min;
    this.max = chosen!.max;
  }

  isConfigurable(): boolean {
    return this.deviceShadow.state.reported.prFT === 0;
  }

  public _correctValue(value: number): number {
    let corrected = value;

    console.log(corrected, 'corrected');
    switch (this.meterUnit) {
      case 'kpa':
        corrected = moveDecimal(corrected, -1);
        // corrected = corrected / 10;
        break;
      case 'bar':
        corrected = moveDecimal(corrected, 1);
        //  corrected = corrected * 10;
        break;
    }

    return corrected;
  }

  protected toFixed_NoRounding(value: number, decimals: number) {
    const stringValue = value.toString();

    const [integerPart, decimalPart] = stringValue.split('.');
    if (decimalPart) {
      return +[integerPart, decimalPart.slice(0, decimals)].join('.');
    } else {
      return integerPart;
    }
  }
}
