import styled from 'styled-components';
import { RowProps } from './Row';

export const Container = styled.div<RowProps>`
    margin-left: ${({ spacing = 0 }) => `-${spacing / 2}px`};
    flex-wrap: wrap;
  & > * {
    padding: ${({ spacing = 0 }) => spacing != 0 && (`0 ${spacing / 2}px  ${spacing / 2}px ${spacing / 2}px`)}
  }
`;
