import React from 'react';
import { NavItem, ItemLink, LinkText } from './style';

type MainNavItemProps = {
  link: string;
  active?: boolean;
  Icon: any;
  text: string;
  disabled?: boolean;
};

const MainNavItem: React.FC<MainNavItemProps> = ({
  link,
  active,
  Icon,
  text,
  disabled,
}) => {
  return (
    <NavItem active={active} disabled={disabled}>
      <ItemLink to={link}>
        {Icon}
        <LinkText>{text}</LinkText>
      </ItemLink>
    </NavItem>
  );
};

export default MainNavItem;
