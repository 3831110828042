import { MouseEventHandler, PropsWithChildren } from "react";
import { To } from "react-router-dom";
import { useTheme } from "styled-components";
import Spinner from "../Spinner/Spinner";
import { Btn, LeftIcon, Link, RightIcon } from "./style";

export type ButtonVariants = "light" | "light-primary" | "danger" | "danger-2" | "standard";
export type ButtonShapes = "circle" | "round";
export type ButtonSizes = "xs" | 'sm' | 'md' | 'lg' | 'xl';

export type ButtonProps = {
  inverted?: boolean;
  block?: boolean;
  type?: "button" | "submit" | "reset" | "link";
  onClick?: MouseEventHandler<HTMLButtonElement>;
  shape?: ButtonShapes;
  variant?: ButtonVariants;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  to?: To;
  disabled?: boolean;
  loading?: boolean;
  buttonStyle?: React.CSSProperties;
  size?: ButtonSizes;
};

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  startIcon,
  type,
  to,
  disabled,
  loading,
  buttonStyle,
  size = 'lg',
  endIcon,
  ...props
}) => {
  const BtnNode: any = type === "link" ? Link : Btn;

  const { isDark } = useTheme();

  return (
    <BtnNode style={buttonStyle} dark={isDark} size={size} disabled={disabled} to={to || ""} {...props}>
      {loading && <Spinner />}
      {startIcon && !loading && <LeftIcon data-testid="start-icon">{startIcon}</LeftIcon>}
      {!loading && children}
      {endIcon && !loading && <RightIcon data-testid="end-icon">{endIcon}</RightIcon>}
    </BtnNode>
  );
};

export default Button;
