import gql from 'graphql-tag';
import jwtDecode from 'jwt-decode';
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import {
  AuthenticateUserQueryVariables,
  ConfirmForgotPasswordMutationVariables,
  DisableMfaMutation,
  DisableMfaMutationVariables,
  EnableMfaMutation,
  EnableMfaMutationVariables,
  GetAssociatedSecretCodeQuery,
  GetAssociatedSecretCodeQueryVariables,
  VerifyTotpQuery,
  VerifyTotpQueryVariables,
} from '../API';
import {
  confirmForgotPassword as confirmForgotPass,
  disableMfa,
  enableMfa,
  forgotPassword as forgotPass,
} from '../graphql/mutations';
import { authenticateUser, getAssociatedSecretCode, verifyTotp } from '../graphql/queries';
import api from '../services/api';
import API from '../services/classes/API';
import User from '../services/classes/User';
import UserSettingsUtils from '../utils/UserSettingsUtils';
import { useTheme } from './Theme';

type EnableTotpReturn = { success: boolean };
type DisableTotpReturn = { success: boolean };
type GetSecretCodeReturn = { secretCode: string };
type SignInReturn = { success: boolean; message?: string; session?: string };
type ForgotPasswordReturn = { success: boolean };
type ConfirmForgotPasswordReturn = { success: boolean };

type AuthCtx = {
  refactoredUser?: User;
  signed: boolean;
  loading: boolean;
  logo: string | null;
  setLogo: React.Dispatch<React.SetStateAction<string | null>> | null;
  mfaEnabled: boolean | null;
  setMfaEnabled: React.Dispatch<React.SetStateAction<boolean | null>> | null;
  disableTotp: (challengeAnswer: string) => Promise<DisableTotpReturn>;
  enableTotp: (password: string, challengeAnswer: string) => Promise<EnableTotpReturn>;
  getSecretCode: (password: string) => Promise<GetSecretCodeReturn>;
  signIn: (credentials: AuthenticateUserQueryVariables) => Promise<SignInReturn>;
  forgotPassword: (username: string) => Promise<ForgotPasswordReturn>;
  confirmForgotPassword: (
    data: ConfirmForgotPasswordMutationVariables
  ) => Promise<ConfirmForgotPasswordReturn>;
  signedQueryRequest: <Variables, ReturnType = any>(
    query: string,
    variables: Variables
  ) => Promise<ReturnType>;
  signedMutationRequest: <Variables, ReturnType = any>(
    mutation: string,
    variables: Variables
  ) => Promise<ReturnType>;
  user?: any;
  logout: () => void;
  isAdmin: () => boolean;
};

const AuthContext = createContext<AuthCtx | null>(null);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [logo, setLogo] = useState<string | null>(null);
  const [mfaEnabled, setMfaEnabled] = useState<boolean | null>(null);
  const { setTheme } = useTheme() as any;
  const refactoredUser = new User(user);

  useEffect(() => {
    const userConfig = localStorage.getItem('userConfig');
    const userToken = JSON.parse(userConfig!)
      ? JSON.parse(userConfig!)['access-token']
      : null;
    const theme = JSON.parse(userConfig!)
      ? JSON.parse(userConfig!)['theme']
      : null;
    const logo = JSON.parse(userConfig!)
      ? JSON.parse(userConfig!)['logo']
      : null;
    const mfaEnabled = JSON.parse(userConfig!)
      ? JSON.parse(userConfig!)['mfa-enabled']
      : null;
    if (userToken) {
      setTheme(theme);
      setLogo(logo);
      setMfaEnabled(mfaEnabled);
      setUser(jwtDecode(userToken));
    }

    setLoading(false);
  }, []);

  // Disables MFA for the user
  const disableTotp = async (challengeAnswer: string) => {
    try {
      const data = await signedMutationRequest<
        DisableMfaMutationVariables,
        {
          data: DisableMfaMutation
        }
      >(disableMfa, {
        challengeAnswer
      });

      if (!data.data.disableMfa.success) {
        throw 'Invalid TOTP';
      }
    } catch (error) {
      throw 'Invalid TOTP';
    }

    return { success: true };
  };

  // Enables MFA for the user in 2 steps:
  //   1. Verifies the passed in auth code
  //   2. Enables TOTP
  const enableTotp = async (password: string, challengeAnswer: string) => {
    try {
      const data = await signedQueryRequest<
        VerifyTotpQueryVariables,
        {
          data: VerifyTotpQuery
        }
      >(verifyTotp, {
        challengeAnswer
      });

      if (!data.data.verifyTotp.success) {
        throw 'Invalid TOTP';
      }
    } catch (error) {
      throw 'Invalid TOTP';
    }

    try {
      const data = await signedMutationRequest<
        EnableMfaMutationVariables,
        {
          data: EnableMfaMutation
        }
      >(enableMfa, {
        password
      });

      if (!data.data.enableMfa.success) {
        throw 'Invalid password';
      }
    } catch (error) {
      throw 'Invalid password';
    }

    return { success: true };
  };

  const getSecretCode = async (password: string) => {
    const data = await signedQueryRequest<
      GetAssociatedSecretCodeQueryVariables,
      {
        data: GetAssociatedSecretCodeQuery
      }
    >(getAssociatedSecretCode, {
      password
    });

    const { secretCode } = data.data.getAssociatedSecretCode;

    return { secretCode };
  };

  const signIn = async (credentials: any) => {

    const { data } = (await api({ isPublic: true }).query({
      query: gql(authenticateUser),
      variables: { ...credentials },
    })) as any;

    const userConfigStr: string = data.authenticateUser;
    const userConfig = JSON.parse(userConfigStr);

    if (
      userConfig.message === 'TOTP required' ||
      userConfig.message === 'Invalid code received for user' ||
      userConfig.message === 'Invalid session for the user, session is expired.'
    ) {
      return {
        success: false,
        message: userConfig.message,
        session: userConfig.session,
      };
    }

    const accessToken = userConfig['access-token'];
    const theme = userConfig.theme;
    const logo = userConfig.logo;
    const mfaEnabled = userConfig['mfa-enabled'];


    if (accessToken) {
      localStorage.setItem('userConfig', userConfigStr);
      setTheme(theme);
      setUser(jwtDecode(accessToken));
      setLogo(logo);
      setMfaEnabled(mfaEnabled);
      return {
        success: true,
      };
    } else {
      return {
        success: false,
      };
    }
  };

  const isAdmin = () => {
    if (!user) return false;

    const allGroups = user['cognito:groups'] as any;

        const isAdmin = allGroups.some((group: any) => {
            const [_, role] = group.split('/');

            return role === 'admin';
        });

        return isAdmin;
  };

  const logout = async () => {
    localStorage.clear();
    localStorage.setItem('logout', 'true');
    UserSettingsUtils.clearSettings();
    setUser(null);
    setTheme('light');
    setLogo(null);
    setMfaEnabled(null);
  };

  async function signedQueryRequest<Variables, ReturnType = any>(
    query: string,
    variables: Variables
  ): Promise<ReturnType | any> {
    try {
      const data = await API.query<Variables, ReturnType>(query, variables);

      return data;
    } catch (e: any) {
      console.debug(e);
      console.debug(query, variables);
      if (e.networkError?.statusCode === 401) {
        logout();
        return;
      }

      throw new Error(e);
    }
  }

  async function signedMutationRequest<Variables, ReturnType = any>(
    mutation: string,
    variables: Variables
  ): Promise<ReturnType | any> {
    try {
      const data = await API.mutate<Variables, ReturnType>(mutation, variables);

      return data;
    } catch (e: any) {
      if (e.networkError?.statusCode === 401) {
        logout();
        return;
      }
      throw new Error(e);
    }
  }

  const forgotPassword = async (username: string) => {
    const { data } = (await api({ isPublic: true }).mutate({
      mutation: gql(forgotPass),
      variables: { username },
    })) as any;

    return data.forgotPassword.success;
  };

  const confirmForgotPassword = async (
    formData: ConfirmForgotPasswordMutationVariables
  ) => {
    const { data } = (await api({ isPublic: true }).mutate({
      mutation: gql(confirmForgotPass),
      variables: { ...formData },
    })) as any;

    return data.confirmForgotPassword.success;
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        logo,
        setLogo,
        mfaEnabled,
        setMfaEnabled,
        signed: !!user,
        disableTotp,
        enableTotp,
        getSecretCode,
        signIn,
        forgotPassword,
        confirmForgotPassword,
        signedQueryRequest,
        signedMutationRequest,
        logout,
        refactoredUser,
        isAdmin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext) as AuthCtx;

export default AuthProvider;
