import { useState } from 'react';
import Button from '../../../components/Button/Button';
import FormControl from '../../../components/Forms/FormControl/FormControl';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EnableMfaMutationVariables, GetAssociatedSecretCodeQueryVariables, VerifyTotpQueryVariables } from '../../../API';
import { useAuth } from '../../../contexts/Auth';
import {
  ContainerTitle,
  FormBottom,
  List,
  GenerateQrCodeButton
} from './style';
// import AppleStore from '../../../../public/images/AppStore.jpg';

import { MdOutlineQrCode } from 'react-icons/md';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormGroup from '../../../components/Forms/FormGroup/FormGroup';
import Col from '../../../components/Layout/Col/Col';
import Row from '../../../components/Layout/Row/Row';
import errorMessages from '../../../config/errorMessages';
import UserSettingsUtils from '../../../utils/UserSettingsUtils';

const enableMfaSchema = yup.object().shape({
  groups: yup.array().min(1, errorMessages.minCharacters(1)),
});

type FormVariables = EnableMfaMutationVariables & GetAssociatedSecretCodeQueryVariables & VerifyTotpQueryVariables;


const EnableMfa = () => {

  const [secretCode, setSecretCode] = useState<string | null>(null);
  const { refactoredUser, setMfaEnabled } = useAuth()

  const navigate = useNavigate();

  const { enableTotp, getSecretCode } = useAuth();

  const [loadingSecretCode, setLoadingSecretCode] = useState(false);
  const [loadingEnableMfa, setLoadingEnableMfa] = useState(false);

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    setError,
    handleSubmit,
    clearErrors
  } = useForm<FormVariables>({
    resolver: yupResolver(enableMfaSchema),
  });

  const handleGenerateTotpQrCode = async () => {
    setLoadingSecretCode(true);
    clearErrors();
    setSecretCode(null);

    try {
      const { password } = getValues();

      if (!password) {
        throw "Invalid password";
      }

      const data = await getSecretCode(password);

      console.log({ data });
      setSecretCode(data.secretCode);
    } catch (error) {
      console.error(error);
      setError('password', {
        type: 'custom',
        message: 'Invalid password',
      });
    }

    setLoadingSecretCode(false);
  };


  const handleEnableMfa: SubmitHandler<FormVariables> = async ({ password, challengeAnswer, }) => {
    clearErrors();
    setLoadingEnableMfa(true)

    try {
      if (!password) {
        throw "Invalid password";
      }

      if (!challengeAnswer) {
        throw "Invalid TOTP";
      }


      // Enable TOTP
      // If it fails, it will throw
      const { success } = await enableTotp(password, challengeAnswer.replaceAll(" ", ""));

      if (success) {
        UserSettingsUtils.saveItem('mfa-enabled', true);
        if (setMfaEnabled) setMfaEnabled(true);
        navigate('/');
      }
    } catch (error) {
      console.log('error', error);
      if (error === "Invalid password") {
        setError('password', {
          type: 'custom',
          message: 'Invalid password',
        });
      }
      if (error === "Invalid TOTP") {
        setError('challengeAnswer', {
          type: 'custom',
          message: 'Invalid code',
        });
      }
    }
    setLoadingEnableMfa(false)

  };

  return (
    <form onSubmit={handleSubmit(handleEnableMfa)}>
      <ContainerTitle>
        <h1>Enable MFA </h1>

        <div>
          <span>Please create an <b>Authentication Code</b> for MFA by downloading the <b>Google Authenticator</b> app on your mobile device. This app is available for download in the App store or Google Play Store.</span>
        </div>

        <img src="/images/google-authenticator.jpg" alt="Google Authenticator" />

      </ContainerTitle>
      <div>
        <span><b>Note:</b><i>Romet's default MFA App is Google Authenticator, however, your company may use a different App, contact your System Administrator if not sure.</i></span>
      </div>


      <List>

        <h1>Steps:</h1>

        <li>Download the <b>Google Authenticator</b> app from the Apple App store for iOS mobile devices or the Google Play Store for Android on your mobile device, if not already installed.</li>
        <li>Enter your login <b>Password</b> and then select the "Generate QR Code" button.</li>
        <li>Scan the generated QR Code that appears on your screen with your mobile device using the <b>Google Authenticator</b> app.</li>
        <li>Enter the 6-digit token (XXX XXX) generated by your <b>Google Authenticator</b> app in the <b>Authentication Code</b> field below your password.</li>
        <li>Click on Enable MFA.</li>

      </List>

      <Row>

        <Col xs={12} lg={6}>
          <FormGroup>
            <FormControl register={register} error={errors.password?.message} label="Password" name="password" type='password' />
          </FormGroup>

          <FormGroup>
            <FormControl register={register} label="Authentication Code" error={errors.challengeAnswer?.message} name="challengeAnswer" />
          </FormGroup>
        </Col>
        <Col xs={12} lg={6} style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

        }}>
          {secretCode && (
            <QRCode
              value={`otpauth://totp/${refactoredUser?.username}?secret=${secretCode}&issuer=BrightLync`}
              size={128}
              viewBox={`0 0 128 128`}
            />
          )}

          {!secretCode && <GenerateQrCodeButton
            startIcon={<MdOutlineQrCode size={14} />}
            variant="light-primary"
            onClick={() => handleGenerateTotpQrCode()}
            loading={loadingSecretCode}
            disabled={loadingSecretCode}
          >
            Generate QR Code
          </GenerateQrCodeButton>}
        </Col>
      </Row>

      <FormBottom>
        <Button block type='submit' loading={loadingEnableMfa}>Enable MFA</Button>
      </FormBottom>
    </form>
  );
};

export default EnableMfa;
