import { Link as RouterLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { ButtonShapes, ButtonSizes, ButtonVariants } from "./Button";


type ButtonProps = {
  inverted?: boolean;
  block?: boolean;
  shape?: ButtonShapes;
  size: ButtonSizes;
  variant?: ButtonVariants;
  dark: boolean;
};

const getBorderRadius = (shape?: ButtonShapes) => {
  switch (shape) {
    case "circle":
      return "50%";
    case "round":
      return "40px";
    default:
      return css`
        ${(props) => props.theme.borderRadius}
      `;
  }
};

const getBackgroundColor = (variant?: ButtonVariants) => {
  switch (variant) {
    case "light":
      return css`
        ${(props) => props.theme.colors.lightGray}
      `;
    case "light-primary":
      return css`
        ${(props) => props.theme.colors.lightGray}
      `;
    case "danger":
      return css`
        ${(props) => props.theme.colors.danger}
      `;
    case "danger-2":
      return css`
          ${(props) => props.theme.colors.danger + '1A'}
        `;
    case "standard":
      return css`
        ${(props) => props.theme.colors.primary}
      `;
    default:
      return css`
        ${(props) => props.theme.colors.primary}
      `;
  }
};

const getColor = (variant?: ButtonVariants) => {
  switch (variant) {
    case "light":
      return css`
        ${(props) => props.theme.colors.lightBlack}
      `;
    case "light-primary":
      return css`
        ${(props) => props.theme.colors.primaryText}
      `;
    case "danger":
      return css`
        ${(props) =>
          props.theme.isDark
            ? props.theme.colors.black
            : props.theme.colors.white}
      `;
    case "danger-2":
      return css`
            ${(props) => props.theme.colors.danger}
          `;
    case "standard":
      return css`
        ${(props) =>
          props.theme.isDark
            ? props.theme.colors.black
            : props.theme.colors.white}
      `;
    default:
      return css`
        ${(props) =>
          props.theme.isDark
            ? props.theme.colors.black
            : props.theme.colors.white}
      `;
  }
};

const getPadding = (size: ButtonSizes, shape?: ButtonShapes) => {
  switch (shape) {
    case "circle":
      return "0";
    case "round":
      return "8px";
    default:
      switch (size) {
        case "xs":
          return "6px 8px";
        case "sm":
          return "10px 16px";
        case "md":
          return "12px 24px";
        case "lg":
          return "16px 24px";
        case "xl":
          return "20px 24px";
      }
  }
};

const getDisabledStyle = (variant?: ButtonVariants) => {


  switch (variant) {
    case 'light':
      return css`
        background-color: '#EFEFEF';
        color: ${props => props.theme.colors.black}66;
      `;
    case 'light-primary':
      return css`
        opacity: 0.5;
      `;
    default:
      return css`
        color: ${(props) => props.theme.colors.primaryText};
        background-color: ${(props) => props.theme.colors.lightGray}; 
      `;
  }

}

const getFontSize = (size: ButtonSizes) => {
  switch (size) {
    case "xs":
      return "12px";
    case "sm":
      return "12px";
    case "md":
      return "14px";
    case "lg":
      return "16px";
    case "xl":
      return "24px";
  }
}

const btnCSS = css<ButtonProps>`
  white-space: nowrap;

  color: ${(props) =>
    props.inverted
      ? getBackgroundColor(props.variant)
      : getColor(props.variant)};

  background-color: ${(props) =>
    props.inverted
      ? getColor(props.variant)
      : getBackgroundColor(props.variant)};

  ${(props) =>
    props.block &&
    css`
      width: 100%;
    `}

  outline: 0;
  border: none;
  border-radius: ${(props) => getBorderRadius(props.shape)};
  padding: ${(props) => getPadding(props.size, props.shape)};
  font-size: ${(props) => getFontSize(props.size)};
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    
    ${props => getDisabledStyle(props.variant)}
    transition: all 200ms;
    cursor: default;
  }

  & svg {
    color: ${(props) =>
    props.inverted
      ? getBackgroundColor(props.variant)
      : getColor(props.variant)};
  }

  & img {
    ${(props) =>
    props.dark &&
    "filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(137deg) brightness(101%) contrast(101%);"}
  }
`;

export const Btn = styled.button<ButtonProps>`
  ${btnCSS}
`;

export const Link = styled(RouterLink) <ButtonProps>`
  ${btnCSS}
`;

export const LeftIcon = styled.span`
  display: inherit;
  margin-right: 6px;
`;

export const RightIcon = styled.span`
  display: inherit;
  margin-left: 6px;
`;
