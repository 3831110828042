import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  MainTitleWrapper,
  Title,
  TitleContainer,
} from '../../components/MainLayout/style';
import Modal from '../../components/Modal/Modal';

import {
  Background,
  Container,
  ContainerButtons,
  ContainerSubtitle,
  DragDropContainer,
  DragDropMsg,
  FloatingButtonItem as FloatinButtonItem,
  FloatingButtons,
  Image,
  Table,
} from './style';

import { useDropzone } from 'react-dropzone';
import {
  Company,
  UpdateCompanyMutationVariables
} from '../../API';
import Button from '../../components/Button/Button';
import Can from '../../components/Can/Can';
import FormGroup from '../../components/Forms/FormGroup/FormGroup';
import FormRow from '../../components/Forms/FormRow/FormRow';
import Radio from '../../components/Forms/Radio/Radio';
import RadioGroup from '../../components/Forms/RadioGroup/RadioGroup';
import EditDeviceGroupSettingsModal from '../../components/Modal/EditDeviceGroupSettingsModal/EditDeviceGroupSettingsModal';
import { useAuth } from '../../contexts/Auth';
import { useTheme } from '../../contexts/Theme';
import { useUpdateCompany } from '../../hooks/mutations';
import {
  useGetCompaniesByGroups,
  useGetDeviceGroups,
} from '../../hooks/queries';

const CompanySettings: React.FC = () => {
  const { register, handleSubmit, getValues } =
    useForm<UpdateCompanyMutationVariables>();

  const { setTheme } = useTheme() as any;

  const { setLogo, refactoredUser } = useAuth();
  const [editCompanyModalOpen, setEditCompanyModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState<Company | null>(null);

  const [fileData, setFileData] = useState<any>({});

  const [editDeviceGroupModalOpen, setEditDeviceGroupModalOpen] =
    useState(false);


  const userGroups = refactoredUser!.getGroups();
  const company = useGetCompaniesByGroups({
    variables: {
      groups: userGroups.filter(
        (group) => group.split('/')[1] === userGroups[0].split('/')[1]
      ),
    },
  });
  console.debug(userGroups);

  useEffect(() => {
    if (company.data) {
      setCompanyData(company.data.getCompaniesByGroups.companies[0]);
    }
  }, [company.data]);

  const deviceGroups = useGetDeviceGroups();
  const updateCompany = useUpdateCompany();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/png': [],
      'image/jpg': [],
      'image/jpeg': [],
    },
    onDrop: (files) => {
      const fileReader = new FileReader();
      const file = files[0];

      fileReader.onload = () => {
        const binaryStr = fileReader.result;

        const [, type] = file.type.split('/');
        setFileData({ file: binaryStr!.toString(), type });
      };

      if (!file) return;

      fileReader.readAsDataURL(file);
    },
  });
  const file = acceptedFiles[0];

  const setCompanySettings = async () => {
    const formData = getValues();

    await updateCompany.mutateAsync({
      ...formData,
      id: companyData?.id!,
      ...fileData,
    });

    company.refetch();

    const { theme } = formData;
    const oldUserConfig = localStorage.getItem('userConfig');

    const parsedOldUserConfig = oldUserConfig ? JSON.parse(oldUserConfig) : {};

    parsedOldUserConfig.theme = theme;

    localStorage.setItem('userConfig', JSON.stringify(parsedOldUserConfig));

    setLogo!(fileData.file);
    setEditCompanyModalOpen(false);
  };
  useEffect(() => {
    setIsImperial(companyData?.temperatureUnit === 'F' ? true : false);
  }, [companyData]);

  const [isImperial, setIsImperial] = useState(
    companyData?.temperatureUnit === 'F' ? true : false
  );

  const toggleImperialAndMetric = (ImperialAndMetric: boolean) => {
    setIsImperial(ImperialAndMetric);
  };

  return (
    <>
      <Modal
        closeModalFunc={setEditCompanyModalOpen}
        open={editCompanyModalOpen}
        buttonText="Save Changes"
        title="Edit Company"
        description="Please select your company settings."
        onSubmitBtnClick={setCompanySettings}
        buttonLoading={updateCompany.isLoading}
      >
        <FormGroup>
          <FormRow>
            <ContainerButtons>
              <Button
                buttonStyle={{ borderRadius: '5px 0px 0px 5px' }}
                variant={isImperial ? undefined : 'light'}
                onClick={() => toggleImperialAndMetric(true)}
                block
              >
                Imperial
              </Button>
              <Button
                buttonStyle={{ borderRadius: '0px 5px 5px 0px' }}
                variant={!isImperial ? undefined : 'light'}
                onClick={() => toggleImperialAndMetric(false)}
                block
              >
                Metric
              </Button>
            </ContainerButtons>
          </FormRow>
        </FormGroup>

        <form>
          {!isImperial && (
            <>
              <FormGroup>
                <Background>
                  <RadioGroup label="Temperature unit">
                    <Radio
                      id="F"
                      label="F"
                      value="F"
                      name="temperatureUnit"
                      register={register}
                      disabled={true}
                      checked
                    />
                    <Radio
                      id="C"
                      value="C"
                      label="C"
                      name="temperatureUnit"
                      register={register}
                      checked
                    />
                  </RadioGroup>

                  <RadioGroup label="Pressure unit">
                    <Radio
                      id="PSIG"
                      value="PSIG"
                      label="PSIG"
                      name="pressureUnit"
                      register={register}
                      disabled={true}
                      checked
                    />
                    <Radio
                      id="PSIA"
                      value="PSIA"
                      label="PSIA"
                      name="pressureUnit"
                      register={register}
                      disabled={true}
                      checked
                    />
                    {/* <Radio
                      id="N/A"
                      label="N/A"
                      name="pressureUnit"
                      register={register}
                      checked={
                        imperial?.pressureUnit === 'N/A' ||
                        !company?.pressureUnit
                      }
                    /> */}
                    <Radio
                      id="KPA"
                      label="KPA"
                      value="KPA"
                      name="pressureUnit"
                      register={register}
                      checked
                    />
                  </RadioGroup>

                  <RadioGroup label="Index read unit">
                    <Radio
                      id="FT3"
                      value="FT3"
                      label=""
                      name="volumeUnit"
                      register={register}
                      disabled={true}
                      checked
                    >
                      <label>
                        FT
                        <sup>3</sup>
                      </label>
                    </Radio>
                    <Radio
                      id="M3"
                      value="M3"
                      label=""
                      name="volumeUnit"
                      register={register}
                      checked
                    >
                      <label>
                        M<sup>3</sup>
                      </label>
                    </Radio>
                  </RadioGroup>

                  <RadioGroup label="Flow Rate unit">
                    <Radio
                      id="FT3/H"
                      value="FT3/h"
                      label=""
                      name="correctedFlowUnit"
                      register={register}
                      disabled={true}
                      checked
                    >
                      <label>
                        FT<sup>3</sup>/h
                      </label>
                    </Radio>
                    <Radio
                      id="M3/H"
                      value="M3/h"
                      label=""
                      name="correctedFlowUnit"
                      register={register}
                      checked
                    >
                      <label>
                        M<sup>3</sup>/h
                      </label>
                    </Radio>
                  </RadioGroup>

                  {/* <RadioGroup label="Set voltage unit">
                <Radio
                  id="V"
                  value="V"
                  label="V"
                  name="voltageUnit"
                  register={register}
                  checked={imperial?.voltageUnit === 'V'}
                />
              </RadioGroup> */}
                </Background>
                <RadioGroup label="UI Theme">
                  <Radio
                    id="theme"
                    value="light"
                    label="Light"
                    name="theme"
                    register={register}
                    checked={companyData?.theme === 'light'}
                    onChange={(e) => {
                      setTheme(e.currentTarget.value);
                    }}
                  />
                  <Radio
                    id="theme"
                    value="dark"
                    label="Dark"
                    name="theme"
                    register={register}
                    checked={companyData?.theme === 'dark'}
                    onChange={(e) => {
                      setTheme(e.currentTarget.value);
                    }}
                  />
                </RadioGroup>

                <DragDropContainer {...getRootProps()}>
                  <input {...getInputProps()} />
                  <DragDropMsg>
                    {file
                      ? file.name
                      : 'Drop logo here or click to select files'}
                  </DragDropMsg>
                </DragDropContainer>
              </FormGroup>
            </>
          )}
          {isImperial && (
            <FormGroup>
              <Background>
                <RadioGroup label="Temperature unit">
                  <Radio
                    id="F"
                    label="F"
                    value="F"
                    name="temperatureUnit"
                    register={register}
                    checked
                  />
                  <Radio
                    id="C"
                    value="C"
                    label="C"
                    name="temperatureUnit"
                    register={register}
                    disabled={true}
                  // checked={imperial?.temperatureUnit !== 'C'}
                  />
                </RadioGroup>

                <RadioGroup label="Pressure unit">
                  <Radio
                    id="PSIG"
                    value="PSIG "
                    label="PSIG"
                    name="pressureUnit"
                    register={register}
                    checked
                  />
                  <Radio
                    id="PSIA"
                    value="PSIA"
                    label="PSIA"
                    name="pressureUnit"
                    register={register}
                    checked={companyData?.pressureUnit === 'PSIA'}
                  />
                  {/* <Radio
                    id="N/A"
                    label="N/A"
                    name="pressureUnit"
                    register={register}
                    checked={
                      imperial?.pressureUnit === 'N/A' || !imperial?.pressureUnit
                    }
                  /> */}
                  <Radio
                    id="KPA"
                    label="KPA"
                    value="KPA"
                    name="pressureUnit"
                    register={register}
                    //checked={imperial?.pressureUnit === 'KPA'}
                    disabled={true}
                  />
                </RadioGroup>

                <RadioGroup label="Index read unit">
                  <Radio
                    id="FT3"
                    value="FT3"
                    label=""
                    name="volumeUnit"
                    register={register}
                    checked
                  >
                    <label>
                      FT
                      <sup>3</sup>
                    </label>
                  </Radio>
                  <Radio
                    id="M3"
                    value="M3"
                    label=""
                    name="volumeUnit"
                    register={register}
                    // checked={imperial?.volumeUnit === 'M3'}
                    disabled={true}
                  >
                    <label>
                      M<sup>3</sup>
                    </label>
                  </Radio>
                </RadioGroup>

                <RadioGroup label="Flow Rate unit">
                  <Radio
                    id="FT3/H"
                    value="FT3/h"
                    label=""
                    name="correctedFlowUnit"
                    register={register}
                    checked
                  >
                    <label>
                      FT<sup>3</sup>/h
                    </label>
                  </Radio>
                  <Radio
                    id="M3/H"
                    value="M3/h"
                    label=""
                    name="correctedFlowUnit"
                    register={register}
                    disabled={true}
                  // checked={imperial?.voltageUnit === 'M3/h'}
                  >
                    <label>
                      M<sup>3</sup>/h
                    </label>
                  </Radio>
                </RadioGroup>

                {/* <RadioGroup label="Set voltage unit">
                <Radio
                  id="V"
                  value="V"
                  label="V"
                  name="voltageUnit"
                  register={register}
                  checked={imperial?.voltageUnit === 'V'}
                />
              </RadioGroup> */}
              </Background>
              <RadioGroup label="UI Theme">
                <Radio
                  id="theme"
                  value="light"
                  label="Light"
                  name="theme"
                  register={register}
                  checked={companyData?.theme === 'light'}
                  onChange={(e) => {
                    setTheme(e.currentTarget.value);
                  }}
                />
                <Radio
                  id="theme"
                  value="dark"
                  label="Dark"
                  name="theme"
                  register={register}
                  checked={companyData?.theme === 'dark'}
                  onChange={(e) => {
                    setTheme(e.currentTarget.value);
                  }}
                />
              </RadioGroup>

              <DragDropContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <DragDropMsg>
                  {file ? file.name : 'Drop logo here or click to select files'}
                </DragDropMsg>
              </DragDropContainer>
            </FormGroup>
          )}
        </form>
      </Modal>

      <EditDeviceGroupSettingsModal
        fetchDeviceGroups={deviceGroups.refetch}
        deviceGroups={deviceGroups.data?.getDeviceGroups || []}
        closeModalFunc={setEditDeviceGroupModalOpen}
        open={editDeviceGroupModalOpen}
      />

      <TitleContainer>
        <MainTitleWrapper>
          <Title>Company Settings</Title>
        </MainTitleWrapper>
      </TitleContainer>

      {!companyData?.logo && !fileData.file && (
        <svg
          width="96"
          height="96"
          viewBox="0 0 96 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="96" height="95.9997" rx="47.9998" fill="white" />
          <path
            d="M68 55.9998V32C68 29.8 66.2 28 64 28H40C37.8 28 36 29.8 36 32V55.9998C36 58.1998 37.8 59.9998 40 59.9998H64C66.2 59.9998 68 58.1998 68 55.9998ZM46 47.9999L50.06 53.4198L56 45.9999L64 55.9998H40L46 47.9999ZM28 35.9999V63.9998C28 66.1997 29.8 67.9997 32 67.9997H60V63.9998H32V35.9999H28Z"
            fill="white"
          />
          <path
            d="M68 55.9998V32C68 29.8 66.2 28 64 28H40C37.8 28 36 29.8 36 32V55.9998C36 58.1998 37.8 59.9998 40 59.9998H64C66.2 59.9998 68 58.1998 68 55.9998ZM46 47.9999L50.06 53.4198L56 45.9999L64 55.9998H40L46 47.9999ZM28 35.9999V63.9998C28 66.1997 29.8 67.9997 32 67.9997H60V63.9998H32V35.9999H28Z"
            fill="black"
            fill-opacity="0.4"
          />
        </svg>
      )}

      {(companyData?.logo || fileData.file) && (
        <Image src={fileData.file || companyData?.logo} />
      )}
      <Container>
        <Table>
          <tr>
            <td>Temperature Unit</td>
            <td>
              <strong>{companyData?.temperatureUnit || '-'}</strong>
            </td>
          </tr>
          <tr>
            <td>Pressure Unit</td>
            <td>
              <strong>{companyData?.pressureUnit || '-'}</strong>
            </td>
          </tr>
          <tr>
            <td>Index Read Unit</td>
            <td>
              <strong>{companyData?.volumeUnit.replace('3', '³') || '-'}</strong>
            </td>
          </tr>
          <tr>
            <td>Flow Rate Unit</td>
            <td>
              <strong>{companyData?.correctedFlowUnit?.replace('3', '³') || '-'}</strong>
            </td>
          </tr>
          <tr>
            <td>Company Theme</td>
            <td>
              <strong>{companyData?.theme || '-'}</strong>
            </td>
          </tr>
        </Table>
      </Container>

      <Can role={'DeviceGroupAdmin'}>
        {!!deviceGroups.data?.getDeviceGroups.length && (
          <Title>Device Group Settings</Title>
        )}

        {deviceGroups.data?.getDeviceGroups.map((group, index) => {
          return (
            <Container key={index}>
              <ContainerSubtitle>{group?.name}</ContainerSubtitle>
              <Table>
                <tr>
                  <td>Temperature Unit</td>
                  <td>
                    <strong>{group?.temperatureUnit || '-'}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Pressure Unit</td>
                  <td>
                    <strong>{group?.pressureUnit || '-'}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Index Read Unit</td>
                  <td>
                    <strong>{group?.volumeUnit || '-'}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Flow Rate Unit</td>
                  <td>
                  <strong>{group?.correctedFlowUnit?.replace('3', '³') || '-'}</strong>
                  </td>
                </tr>
              </Table>
            </Container>
          );
        })}
      </Can>

      <FloatingButtons>
        <Can
          role={[
            'CompanyAdmin',
            'CompanyViewer',
            'DeviceViewer',
            'UtilityAdmin',
            'UtilityPowerUser',
            'admin',
          ]}
        >
          <FloatinButtonItem
            shape="round"
            onClick={() => setEditCompanyModalOpen(true)}
          >
            Edit Company
          </FloatinButtonItem>
        </Can>
        {!!deviceGroups.data?.getDeviceGroups.length && (
          <Can role={'DeviceGroupAdmin'}>
            <FloatinButtonItem
              shape="round"
              onClick={() => setEditDeviceGroupModalOpen(true)}
            >
              Edit Device Group Settings
            </FloatinButtonItem>
          </Can>
        )}
      </FloatingButtons>
    </>
  );
};

export default CompanySettings;
