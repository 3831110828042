import React, { useEffect, useState } from 'react';
import { Device, DeviceJobManagerMutation, DeviceJobManagerMutationVariables } from '../../../API';
import { useAuth } from '../../../contexts/Auth';
import { deviceJobManager } from '../../../graphql/mutations';
import Spinner from '../../Spinner/Spinner';
import Modal, { ModalOpeningProps } from '../Modal';


type Props = {
    deviceBeingPulled: string | null;
}

const PullDataModal: React.FC<ModalOpeningProps & Props> = ({ open, closeModalFunc, deviceBeingPulled }) => {

    const [pullingData, setPullingData] = useState(false);
    const { signedMutationRequest, signedQueryRequest } = useAuth();

    const pullDeviceData = async (deviceId: string) => {
        setPullingData(true);

        const data = await signedMutationRequest<
            DeviceJobManagerMutationVariables,
            {
                data: DeviceJobManagerMutation;
            }
        >(deviceJobManager, {
            operation: 'pullData',
            deviceId,
        });

        setPullingData(false);
    };

    useEffect(() => {
        if (open) pullDeviceData(deviceBeingPulled!);
    }, [open]);

    return <Modal
        closeModalFunc={closeModalFunc}
        open={open}
        title="Pull Data"
        description={`Pulling data for ${deviceBeingPulled}`}
    >
        {pullingData ? (
            <Spinner variant="primary" />
        ) : (
            <p>Job was created successfully</p>
        )}
    </Modal>;
}

export default PullDataModal;