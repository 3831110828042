import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const Label = styled.label`
  font-size: 16px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.black};
`;

export const Slider = styled.input`
  appearance: none;
    -webkit-appearance: none;
    background: red;
    border: 0px;
    height: 2px;
  width: 100%;
  &:disabled {
    &::-webkit-slider-runnable-track {
      appearance: none;
    -webkit-appearance: none;
      background: ${(props) => props.theme.colors.gray};
    }

    ::-moz-range-track {
      appearance: none;
    -webkit-appearance: none;
      background: ${(props) => props.theme.colors.gray};
    }

    &::-webkit-slider-thumb,  {
      appearance: none;
    -webkit-appearance: none;
      background: ${(props) => props.theme.colors.gray};
      cursor: default;
    }

    ::-moz-range-thumb {
      appearance: none;
    -webkit-appearance: none;
      background: ${(props) => props.theme.colors.gray};
      cursor: default;
    }
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    height: 2px;
    background: ${(props) => props.theme.colors.primaryText};
  }

  &::-webkit-slider-thumb{
    appearance: none;
    -webkit-appearance: none;
    background-color: ${(props) => props.theme.colors.primaryText};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: -11px 0;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    appearance: none;
    -webkit-appearance: none;
    background-color: ${(props) => props.theme.colors.primaryText};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: -11px 0;
    cursor: pointer;
  }

  &::-moz-range-track {
    -webkit-appearance: none;
    appearance: none;
    height: 2px;
    background: ${(props) => props.theme.colors.primaryText};
  }

`;

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const Thumb = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primaryText};
  cursor: pointer;

  transform: translate(0, -50%);
`;

export const ThumbContainer = styled.div`
  position: absolute;
  top: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ThumbNumber = styled.span`
  position: absolute;
  color: ${(props) => props.theme.colors.primaryText};
  width: 24px;
  text-align: center;
  font-size: 12px;
  top: 30px;
  width: auto;
`;
