
// Function to query devices from the API
// Use React Query
// https://react-query.tanstack.com/

import { QueryObserverOptions, QueryOptions, useQuery } from 'react-query';
import { GetCompaniesByGroupsQuery, GetCompaniesByGroupsQueryVariables, GetDeviceGroupsQuery, GetFirmwareQuery, GetFirmwareQueryVariables, GroupListForUsersQuery, GroupListForUsersQueryVariables, ListDevicesQuery, GetMetersetImageQuery, GetMetersetImageQueryVariables, ListDevicesQueryVariables, ListFirmwareQuery, ListFirmwareVersionQuery, ListFirmwareVersionQueryVariables, UserListQuery, UserListQueryVariables } from '../API';
import { useAuth } from '../contexts/Auth';
import { listFirmware } from '../graphql/graphql/queries';
import { getCompaniesByGroups, getDeviceGroups, getFirmware, groupListForUsers, listDevices, getMetersetImage, listFirmwareVersion, userList } from '../graphql/queries';
import API from '../services/classes/API';

import { GetDeviceShadowQuery, GetDeviceShadowQueryVariables, GetMeterDataQuery, GetMeterDataQueryVariables, GroupListQuery, ListCompaniesQuery } from '../API';
import { listCompanies } from '../graphql/graphql/queries';
import { getDeviceShadow, getMeterData, groupList } from '../graphql/queries';

export const defaultComponent = 'CCB';

type BaseQueryParams<TQuery, TVariables = any> = {
    query: string;
    variables: TVariables;
    key: string;
    additionalOptions?: QueryOptions<TQuery> & QueryObserverOptions<TQuery>;
    additionalKeys?: any[];
}

type CustomQueryParams<TQuery, TVariables = any> = {
    variables?: TVariables;
    additionalOptions?: QueryOptions<TQuery> & QueryObserverOptions<TQuery>;
}
function useBaseQuery<TQuery, TVariables = any>(
    { query, variables, key, additionalOptions, additionalKeys }: BaseQueryParams<TQuery, TVariables>,
) {
    const { logout } = useAuth();
    return useQuery<TQuery>({
        queryKey: [key, variables, ...(additionalKeys ?? [])],
        queryFn: fetchFunc,
        retry: false,
        staleTime: 1000 * 60,
        ...additionalOptions
    });

    async function fetchFunc() {
        try {
            const {
                data,
            } = await API.query<TVariables, TQuery>(query, {
                ...variables
            });

            return data;
        } catch (e: any) {
            if (e.networkError?.statusCode === 401) {
                logout();
            }

            throw new Error(e);
        }
    }
}

export const useListDevices = createCustomQuery<ListDevicesQuery, ListDevicesQueryVariables>({
    query: listDevices,
    key: 'listDevices'
});

export const useGetMetersetImage = createCustomQuery<GetMetersetImageQuery, GetMetersetImageQueryVariables>({
    query: getMetersetImage,
    key: 'getMetersetImage'
});

export const useGroupListForUsers = createCustomQuery<GroupListForUsersQuery, GroupListForUsersQueryVariables>({
    query: groupListForUsers,
    key: 'groupListForUsers'
});

export const useListFirmware = createCustomQuery<ListFirmwareQuery>({
    query: listFirmware,
    key: 'listFirmware'
});

export const useListFirmwareVersion = createCustomQuery<ListFirmwareVersionQuery, ListFirmwareVersionQueryVariables>({
    query: listFirmwareVersion,
    key: 'listFirmwareVersion',
});

export const useGetFirmware = createCustomQuery<GetFirmwareQuery, GetFirmwareQueryVariables>({
    query: getFirmware,
    key: 'getFirmware'
});

export const useGroupList = createCustomQuery<GroupListQuery>({
    query: groupList,
    key: 'groupList'
});

export const useListCompanies = createCustomQuery<ListCompaniesQuery>({
    query: listCompanies,
    key: 'listCompanies'
});

export const useDeviceShadow = createCustomQuery<GetDeviceShadowQuery, GetDeviceShadowQueryVariables>({
    query: getDeviceShadow,
    key: 'getDeviceShadow'
});

export const useMeterData = createCustomQuery<GetMeterDataQuery, GetMeterDataQueryVariables>({
    query: getMeterData,
    key: 'getMeterData'
});

export const useUserList = createCustomQuery<UserListQuery, UserListQueryVariables>({
    query: userList,
    key: 'userList'
});

export const useGetDeviceGroups = createCustomQuery<GetDeviceGroupsQuery>({
    query: getDeviceGroups,
    key: 'getDeviceGroups'
});

export const useGetCompaniesByGroups = createCustomQuery<GetCompaniesByGroupsQuery, GetCompaniesByGroupsQueryVariables>({
    query: getCompaniesByGroups,
    key: 'getCompaniesByGroups'
});

type CreateCustomQueryParams = {
    query: string;
    key: string;
}

function createCustomQuery<TQuery, TVariables = any>({ query, key }: CreateCustomQueryParams) {
    return ({ variables, additionalOptions }: CustomQueryParams<TQuery, TVariables> = {}) => useBaseQuery<TQuery, TVariables>(
        { query, variables: { ...variables! }, key, additionalOptions });
}