import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

export const Box = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  padding: 20px;
  padding-top: 10px;
  font-size: 12px;
  height: 100%;
`;

export const BoxItem = styled.div`
  padding: 0 4px;
  margin-bottom: 8px;

  /* &:nth-child(3n + 1) {
    padding-left: 0;
  }

  &:nth-child(3n) {
    padding-right: 0;
  } */
`;

export const Row = styled.div`
  margin-bottom: 5px;
  display: flex;

  @media screen and (min-width: ${(props) => breakpoints.lg}) {
    display: flex;
  }
`;

export const Col = styled.div`
  color: ${(props) => props.theme.colors.black};

  &:first-child {
    margin-right: 16px;
  }

  &:last-child {
    flex: 1;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #666;
`;

export const BoxTitle = styled.h3`
  font-size: 16px;
  line-height: 32px;
  font-weight: normal;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  &:empty {
    display: none;
  }
`;

export const MultipleValue = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 411px;

  & span:not(:last-child) {
    &::after {
      content: '';
      position: relative;

      height: 5px;
      width: 1px;
      z-index: 100;
      /* background-color: ${(props) => props.theme.colors.black}66; */
      display: inline-block;
      margin: 0 5px;
      transform: translateY(-25%);
    }
  }
`;

export const Value = styled.span`
  display: block;
  margin-bottom: 5px;
`;

export const BottomContentContainer = styled.div`
  margin-top: 16px;
`;
