import { Wrapper } from '@googlemaps/react-wrapper';
import React, { useEffect, useRef } from 'react';
import { AiOutlineReload } from 'react-icons/ai';
import { ImMap2 } from 'react-icons/im';
import Button from '../../../components/Button/Button';
import MapComponent from '../../../components/MapComponent/MapComponent';
import { DeviceWithBatteryLife } from '../DeviceTable/DeviceTable';
import { MapButtons, MapContainer, MapLeftButtons } from './style';

// import { Container } from './styles';
export type MapStates = {
    fullMap?: boolean;
    showingMap?: boolean;
    onShowingMapChange?: (showingMap: boolean) => void;
    dynamicData?: boolean;
};

type Props = {
    onCoordsChange: (coords: Coords) => void;
    devices: (DeviceWithBatteryLife | null)[];
    onDynamicDataChange: (dynamicData: boolean) => void;
    onChangeFullMap: (fullMap: boolean) => void;
} & MapStates

export type Coords = {
    latitudeHi?: number;
    longitudeLo?: number;
    latitudeLo?: number;
    longitudeHi?: number;
};

const DeviceMap: React.FC<Props> = ({ fullMap,
    showingMap,
    dynamicData,
    onCoordsChange,
    devices,
    onShowingMapChange,
    onDynamicDataChange,
    onChangeFullMap
}) => {
    const canFetchDevices = useRef(true);
    const mapContainerRef = useRef<HTMLDivElement>(null);

    const callback = () => {
        calculateAndSetMapContainerHeight();
    };

    useEffect(() => {
        callback();
        window.addEventListener('resize', callback);


        return () => {
            window.removeEventListener('resize', callback);
        };
    }, []);


    function calculateAndSetMapContainerHeight() {
        if (!mapContainerRef.current) return;

        const distanceFromTopToMapContainerTop =
            mapContainerRef.current.getBoundingClientRect().top;

        const distanceFromTopToContentBottom = document
            .querySelector('#root > div > div')
            ?.getBoundingClientRect().bottom;

        const contentPadding = window.getComputedStyle(
            document.querySelector('#root > div > div')!,
            null
        ).paddingBottom;

        const paddingToNum = parseFloat(
            contentPadding.slice(0, contentPadding.indexOf('p'))
        );

        const maxHeight =
            distanceFromTopToContentBottom! -
            paddingToNum -
            distanceFromTopToMapContainerTop!;

        mapContainerRef.current.style.height = maxHeight + 'px';
    }

    return <MapContainer ref={mapContainerRef} fullMap={fullMap} showingMap={showingMap}>
        <Wrapper apiKey={'AIzaSyBRFCf7Ok6tGtrAbcrCxKdB4iLRlseN8hc'}>
            <MapComponent
                onIdle={(map) => {
                    if (!dynamicData) return;

                    if (canFetchDevices.current) {
                        canFetchDevices.current = false;
                        const mapBounds = map.getBounds();
                        const northEastCoords = mapBounds?.getNorthEast();
                        const southWestCoords = mapBounds?.getSouthWest();
                        const coords = {
                            latitudeHi: northEastCoords?.lat(),
                            longitudeLo: southWestCoords?.lng(),
                            latitudeLo: southWestCoords?.lat(),
                            longitudeHi: northEastCoords?.lng(),
                        };
                        onCoordsChange(coords);
                    }
                }}
                onDragStart={() => {
                    canFetchDevices.current = true;
                }}
                onZoomChanged={() => {
                    canFetchDevices.current = true;
                }}
                devices={devices}
                dynamicMap={dynamicData}
            />
        </Wrapper>
        <MapButtons>
            <MapLeftButtons>
                <Button
                    startIcon={<ImMap2 />}
                    variant="light-primary"
                    onClick={() => { if (onShowingMapChange) onShowingMapChange(false) }}
                >
                    Hide map
                </Button>
                <Button
                    startIcon={
                        fullMap ? (
                            <img src="svgs/retract-arrows.svg" />
                        ) : (
                            <img src="svgs/expand-arrows.svg" />
                        )
                    }
                    variant="light-primary"
                    onClick={() => onChangeFullMap(!fullMap)}
                >
                    {fullMap ? 'Exit full map' : 'Full map'}
                </Button>
                <Button
                    startIcon={<AiOutlineReload />}
                    variant="light-primary"
                    onClick={() => onDynamicDataChange(!dynamicData)}
                >
                    Turn {dynamicData ? 'Off' : 'On'} Dynamic Map
                </Button>
            </MapLeftButtons>
        </MapButtons>
    </MapContainer>;
}

export default DeviceMap;