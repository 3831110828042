import isEqual from 'fast-deep-equal';
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { VscChromeClose } from "react-icons/vsc";
import Badge from "../Badge/Badge";
import Button, { ButtonProps } from "../Button/Button";
import FormRow from '../Forms/FormRow/FormRow';
import {
  Background,
  BottomContent,
  CloseButton,
  Content, Contents, Description, MainContent, ModalContainer,
  ModalHeader, PlusContent, Title, TitleContainer
} from "./style";

export type ModalOpeningProps = {
  open: boolean;
  closeModalFunc: (isOpen: boolean) => void;
};

type ModalButtonProps = ButtonProps & {
  text: string;
}

type Button = ModalButtonProps | ModalButtonProps[] | ModalButtonProps[][];

export type ModalButtonsProps = {
  onSubmitBtnClick?: Function;
  buttonLoading?: boolean;
  buttonDisabled?: boolean;
  button?: Button;
}

type BottomContent = React.ReactNode;

type ModalStepProps = {
  step: number;
  title: string;
  /** @deprecated use button prop instead */
  buttonText?: string;
  /** @deprecated use button prop instead */
  onSubmitBtnClick?: Function;
  /** @deprecated use button prop instead */
  bottomContent?: BottomContent;
  button?: Button;
}

export type ModalProps = ModalOpeningProps & ModalButtonsProps & {
  /** @deprecated use button prop instead */
  buttonText?: string;
  title: string;
  description?: string;
  /** @deprecated use button prop instead */
  bottomContent?: BottomContent;
  plusContent?: React.ReactNode;
  totalStep?: number;
  currentStep?: number;
  titleBtnText?: string;
  titleBtnProps?: ButtonProps;
  onTitleBtnTextClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export interface ModalContextI {
  increaseStep: () => void;
  decreaseStep: () => void;
  currentStep: number;
  setTitle: (title: string) => void;
  changeSubmitBtnFunc: (func: Function) => void;
  changeButtonText: (text: string) => void;
  setCurrentStep: (step: number) => void;
  setBottomContent: (content: BottomContent) => void;
  changeButton: (button?: Button) => void;
}

export const ModalContext = createContext<ModalContextI | null>(null);

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  children,
  open,
  buttonText,
  title,
  description,
  closeModalFunc,
  onSubmitBtnClick,
  buttonLoading,
  buttonDisabled = false,
  bottomContent,
  plusContent,
  totalStep = 2,
  currentStep,
  titleBtnText,
  titleBtnProps,
  onTitleBtnTextClick,
  button,
}) => {
  const [closing, setClosing] = useState(false);
  const [step, setStep] = useState(currentStep || 1)
  const [titleText, setTitleText] = useState(title);
  const [onSubmitBtnClickFunc, setOnSubmitBtnClickFunc] = useState(() => onSubmitBtnClick);
  const [btnText, setBtnText] = useState(buttonText)
  const [actualBottomContent, setActualBottomContent] = useState(bottomContent);
  const [isOpen, setIsOpen] = useState(open)
  const [actualButton, setActualButton] = useState(button)


  const closeModal = () => {
    setClosing(true);
  };

  const onAnimationEnd = () => {
    if (closing) {
      setClosing(false);
      setIsOpen(false);
      closeModalFunc(false);
    }
  };

  useEffect(() => {

    setOnSubmitBtnClickFunc(() => onSubmitBtnClick)

  }, [onSubmitBtnClick]);

  useEffect(() => {
    if (!open && isOpen) {
      closeModal()
    }
    if (open) {
      setIsOpen(true);
    };
  }, [open])


  const changeButton = useCallback((button?: Button) => {
    if (!isEqual(button, actualButton)) {
      setActualButton(button);

    }
  }, [actualButton])

  const increaseStep = useCallback(() => {
    if (step === totalStep) return;

    setStep(step => step + 1);
  }, [step, totalStep])

  if (!isOpen) return null;



  const decreaseStep = () => {
    setStep(step => step - 1);
  }

  const setCurrentStep = (step: number) => {
    setStep(step);
  }

  const changeSubmitBtnFunc = (func: Function) => {
    setOnSubmitBtnClickFunc(() => func);
  }

  const changeButtonText = (text: string) => {
    setBtnText(text);
  }

  const setTitle = (title: string) => setTitleText(title);

  const setBottomContent = (content: BottomContent) => {
    if (!isEqual(content || null, actualBottomContent || null)) {
      setActualBottomContent(content)
    }
  }

  const renderButtons = () => {

    const getProps = (button: ButtonProps) => {
      const btnDefaultProps: ButtonProps = {
        block: true,
      }

      return { ...btnDefaultProps, ...button };
    }

    if (!actualButton) {
      return null;
    }

    if (!Array.isArray(actualButton)) {
      return <Button {...getProps(actualButton)}>{actualButton?.text}</Button>;
    }

    if (Array.isArray(actualButton)) {
      return actualButton.map((btn, index) => {
        if (!Array.isArray(btn)) {
          return <Button {...getProps(btn)}>{btn?.text}</Button>;
        } else {
          return (
            <FormRow>
              {btn.map((_btn, index) => {
                return <Button {...getProps(_btn)}>{_btn?.text}</Button>;
              })}
            </FormRow>
          )
        }
      })
    }
  }


  return (
    <>
      <ModalContext.Provider value={{ changeButton, setBottomContent, setCurrentStep, setTitle, increaseStep, decreaseStep, currentStep: step, changeSubmitBtnFunc, changeButtonText }}>

        <ModalContainer closing={closing}>
          <ModalHeader>
            <CloseButton onClick={() => step === 1 ? closeModal() : decreaseStep()}>
              {step === 1 && <VscChromeClose />}
              {step !== 1 && <IoMdArrowBack />}
            </CloseButton>
            <TitleContainer>
              <Title>{titleText}</Title>
              {currentStep && (
                <Badge variant="success"> Step {step} of {totalStep} </Badge>
              )}
              {titleBtnText && <Button buttonStyle={{
                padding: '10px 16px'
              }} {...(titleBtnProps || { variant: 'light' })} onClick={onTitleBtnTextClick}>{titleBtnText}</Button>}
            </TitleContainer>
          </ModalHeader>
          <Description>{description || ""}</Description>
          <Contents>
            {plusContent && <PlusContent>{plusContent}</PlusContent>}
            <MainContent>
              <Content>{children}</Content>
              {(actualButton || actualBottomContent || buttonText) && (
                <>
                  <BottomContent>
                    {buttonText && (
                      <Button
                        block
                        type="button"
                        loading={buttonLoading}
                        onClick={() => { if (onSubmitBtnClickFunc) onSubmitBtnClickFunc() }}
                        disabled={buttonDisabled}
                      // variant={currentStep === 1 ? "light-primary" : "standard"}
                      >
                        {buttonText}
                      </Button>
                    )}
                    {actualBottomContent && <>{actualBottomContent}</>}

                    {actualButton && renderButtons()}
                  </BottomContent>
                </>
              )}
            </MainContent>
          </Contents>
        </ModalContainer>
        <Background
          onClick={closeModal}
          closing={closing}
          onAnimationEnd={onAnimationEnd}
        ></Background>

      </ModalContext.Provider>
    </>
  );
};

export const useModalContext = () => useContext(ModalContext) as ModalContextI;



export const ModalStep: React.FC<PropsWithChildren<ModalStepProps>> = ({ children, step, title, buttonText, onSubmitBtnClick, bottomContent, button }) => {

  const { currentStep, changeButton, setTitle, changeButtonText, changeSubmitBtnFunc, increaseStep, setBottomContent } = useModalContext();


  useEffect(() => {
    if (currentStep === step) {
      setTitle(title)
      changeButtonText(buttonText || '');
      changeSubmitBtnFunc(onSubmitBtnClick || increaseStep)
      setBottomContent(bottomContent);
      changeButton(button);
    }
  }, [currentStep, changeButton]);

  // useEffect(() => {
  //   changeButton(button);
  // }, [button]);

  if (currentStep != step) return null;

  return <>{children}</>

}

export default Modal;
