import { Role } from "../../types/UserRoles";
import UserSettingsUtils from "../../utils/UserSettingsUtils";

export default class User {

    private _groups: string[] = [];
    private _username: string;

    constructor(userObject: any) {
        if (!userObject) return;

        this.username = userObject.username;
        this._groups = userObject['cognito:groups'];


    }


    public get username(): string {
        return this._username;
    }
    public set username(value: string) {
        this._username = value;
    }

    public hasRole(roles: Role | Role[]): boolean {

        const allUserRoles = this.getRoles();

        if (typeof roles === 'string') {
            return allUserRoles.includes(roles);
        }

        if (typeof roles === 'object') {
            return roles.some(role => allUserRoles.includes(role));
        }

        return false;
    }

    public getGroups(): string[] {
        return this._groups;
    }

    public getRoles(): Role[] {

        return this._groups.map(group => group.split('/')[1] as Role);

    }

    public isMFAEnabled() {
        return Boolean(UserSettingsUtils.getItem('mfa-enabled'));
    }

    public isAdmin(): boolean {
        return this.getRoles().includes('admin');
    }

}