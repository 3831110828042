import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
export const Input = styled.input`
  position: relative;
  width: 18px;
  height: 18px;
  appearance: none;
  border: 2px solid ${(props) => props.theme.colors.primaryText};
  border-radius: 3px;
  cursor: pointer;

  &:checked {
    &::after {
      display: block;
    }
  }

  &:hover,
  &:checked {
    background-color: ${(props) => props.theme.colors.primaryText};
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    mask-image: url('/svgs/check-solid.svg');
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: cover;
    background-color: ${(props) => props.theme.colors.white};
    z-index: 5;
  }
`;
export const Label = styled.label`
  margin-left: 11px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.black};
`;

export const SubLabel = styled.span`
  font-size: 10px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.gray};
`;

export const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.danger};
  font-size: 12px;
`;
