import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Selector, TableColumn } from 'react-data-table-component';
import { BiPlusCircle } from 'react-icons/bi';
import { BsThreeDots } from 'react-icons/bs';
import { ImMap2 } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';
import { CSSObject, useTheme } from 'styled-components';
import { Device } from '../../../API';
import AssetsSearch from '../../../components/AssetsSearch/AssetsSearch';
import Button from '../../../components/Button/Button';
import Can from '../../../components/Can/Can';
import DataT from '../../../components/DataTable/DataTable';
import AdvancedSearchModal from '../../../components/Modal/AdvancedSearchModal/AdvancedSearchModal';
import EditColumnsModal from '../../../components/Modal/EditColumnsModal/EditColumnsModal';
import { useAuth } from '../../../contexts/Auth';
import {
  useGroupListForUsers,
  useListCompanies,
  useListDevices,
} from '../../../hooks/queries';
import DateUtils from '../../../utils/DateUtils';
import UserSettingsUtils from '../../../utils/UserSettingsUtils';
import { Coords, MapStates } from '../DeviceMap/DeviceMap';
import {
  DataContainer,
  DataOptions,
  DropdownItem,
  TableDropdown,
} from '../style';
import { DropdownBtn, TableButtons, TableWrapper } from './style';

export type DeviceWithBatteryLife = Device & {
  batteryLife?: number;
};

export type PossibleColumnsProps = {
  id: string;
  name: string;
  isVisible: boolean;
  style?: CSSObject;
  selector: Selector<DeviceWithBatteryLife>;
  center?: boolean;
};

type Props = {
  onInstallFirmware: (device: Device) => void;
  onDevicePull: (deviceId: string) => void;
  onDeviceExport: (device: Device) => void;
  onShadowDevice: (device: Device) => void;
  coords: Coords;
  onTextSearchChange: (text: string) => void;
  onDevicesChange: (devices: Device[]) => void;
  onDynamicDataChange: (dynamicData: any) => void;
} & MapStates;

const DeviceTable: React.FC<Props> = ({
  onInstallFirmware,
  onDevicePull,
  onDeviceExport,
  onShadowDevice,
  dynamicData,
  coords,
  fullMap,
  showingMap,
  onShowingMapChange,
  onDevicesChange,
  onDynamicDataChange,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState(
    UserSettingsUtils.getItem('search') || {}
  );
  const { refactoredUser, signedQueryRequest } = useAuth();

  const [page, setPage] = useState(1);
  const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);
  const [editColumnsModalOpen, setEditColumnsModalOpen] = useState(false);

  const [perPage, setPerPage] = useState(0);
  const [possibleColumns, setPossibleColumns] = useState<
    PossibleColumnsProps[]
  >([
    {
      id: 'deviceid',
      name: 'Device ID',
      selector: (row) => row.id || '',
      style: {
        fontWeight: 'bold',
        cursor: 'pointer',
      },
      isVisible: false,
    },
    {
      id: 'serialNo',
      name: 'BrightLync ID',
      selector: (row) => row.serialNo || '',
      style: {
        fontWeight: 'bold',
        cursor: 'pointer',
      },
      isVisible: false,
    },
    {
      id: 'meterid',
      name: 'Customer ID',
      isVisible: true,
      selector: (row) => row.meterId || '',
      style: {
        fontWeight: 'bold',
        cursor: 'pointer',
      },
    },
    {
      id: 'owner',
      name: 'Asset Owner',
      selector: (row) => row.user_device?.user?.email || 'Not attached',
      isVisible: false,
    },
    {
      id: 'group',
      name: 'Asset Group',
      selector: (row) => row.group?.name || 'Not attached',
      isVisible: false,
    },
    {
      id: 'utility',
      name: 'Utility',
      selector: (row) => row.group?.utility.name || 'Not attached',
      isVisible: false,
    },
    {
      id: 'company',
      name: 'Company',
      selector: (row) => row.group?.utility.company?.name || 'Not attached',
      isVisible: false,
    },
    {
      id: 'address',
      name: 'Address',
      isVisible: true,
      selector: (row) => row.address || '-',
      style: {
        cursor: 'pointer',
        width: 500,
      },
    },
    {
      id: 'dtm',
      name: 'Read date/time',
      selector: (row) =>
        row?.data?.dTm
          ? DateUtils.formatDateWithTmZn(
              row.data.dTm * 1000,
              row?.data?.TmZn,
              row?.data?.proto
            )
          : '-',
      isVisible: false,
    },
    {
      id: 'ingestiontime',
      name: 'Ingestion Time',
      selector: (row) =>
        _.has(row, 'data.ingestionTime')
          ? DateUtils.formatDateWithTmZn(
              row!.data!.ingestionTime,
              row?.data?.TmZn,
              row?.data?.proto
            )
          : '-',
      isVisible: false,
    },
    {
      id: 'fcv',
      name: 'Full Corrected Index Read',
      selector: (row) =>
        _.has(row, 'data.fCV')
          ? `${row.data?.fCV} ${row.group?.utility.company?.volumeUnit}`
          : '-',
      isVisible: false,
    },
    {
      id: 'fuv',
      name: 'Full Uncorrected Index Read',
      selector: (row) =>
        _.has(row, 'data.fUV')
          ? `${row.data?.fUV} ${row.group?.utility.company?.volumeUnit}`
          : '-',
      isVisible: false,
    },
    {
      id: 'cfavg',
      name: 'Average Uncorrected Flow Rate',
      selector: (row) =>
        _.has(row, 'data.cFAvg')
          ? `${row.data?.cFAvg} ${row.group?.utility.company?.correctedFlowUnit}`
          : '-',
      isVisible: false,
    },
    {
      id: 'maxpr',
      name: 'Max. Pressure',
      selector: (row) =>
        _.has(row, 'data.maxPr.v')
          ? `${row.data?.maxPr?.v} ${row.group?.utility.company?.pressureUnit}`
          : '-',
      isVisible: false,
    },
    {
      id: 'minfc',
      name: 'Min. Flow Corr.',
      selector: (row) =>
        _.has(row, 'data.minFC.v')
          ? `${row.data?.minFC?.v} ${row.group?.utility.company?.volumeUnit}`
          : '-',
      isVisible: false,
    },
    {
      id: 'maxtemp',
      name: 'Max. Temp.',
      selector: (row) =>
        _.has(row, 'data.maxTemp.v')
          ? `${row.data?.maxTemp?.v} ${row.group?.utility.company?.temperatureUnit}`
          : '-',
      isVisible: false,
    },
    {
      id: 'bv',
      name: 'Battery Remaining Life',
      selector: (row) => {
        if (!row.batteryLife) return '-';

        return `${row.batteryLife}%`;
      },
      isVisible: false,
    },
    {
      id: 'daycv',
      name: 'Corr. Index Read (Daily)',
      selector: (row) =>
        _.has(row, 'data.dayCV')
          ? `${row.data?.dayCV} ${row.group?.utility.company?.volumeUnit}`
          : '-',
      isVisible: false,
    },
    {
      id: 'dayuv',
      name: 'Uncorr. Index Read (Daily)',
      selector: (row) =>
        _.has(row, 'data.dayUV')
          ? `${row.data?.dayUV} ${row.group?.utility.company?.volumeUnit}`
          : '-',
      isVisible: false,
    },
    {
      id: 'alarms',
      name: 'Alarms',
      isVisible: true,
      selector: (row) => {
        const alarms = row?.messages?.reduce((currentAlarms, message) => {
          if (!message?.alarms) return currentAlarms;
          const combined = currentAlarms.concat(message?.alarms as any);
          return combined;
        }, []);

        return alarms?.length as any;
      },
    },
  ]);

  useEffect(() => {
    async function loadPages() {
      const { rows: storedPages } = await UserSettingsUtils.getPages(
        'userConfig'
      );
      setPage(storedPages || 1);
    }

    loadPages();
  }, []);

  useEffect(() => {
    async function loadPerPage() {
      const { perPage: storedPerPage } = await UserSettingsUtils.getPerPage(
        'userConfig'
      );
      setPerPage(storedPerPage || 10);
    }

    loadPerPage();
  });

  useEffect(() => {
    async function loadColumns() {
      const { columns: storedColumns } = await UserSettingsUtils.getColumns(
        'userConfig'
      );
      const parsedColumns = possibleColumns.map((item) => {
        const { id } = item;

        const findColumnStored = storedColumns.some(
          (stored: string) => stored === id
        );

        if (findColumnStored) {
          return {
            ...item,
            isVisible: true,
          };
        }

        return item;
      });
      setPossibleColumns(parsedColumns);
    }

    loadColumns();
  }, []);

  const columns: TableColumn<DeviceWithBatteryLife>[] = useMemo(
    () => [
      ...possibleColumns.map(({ name, selector, center, style, isVisible }) => {
        return {
          name,
          selector,
          center,
          style,
          omit: !isVisible,
        };
      }),
      {
        name: '',
        reorder: true,
        selector: (row, index) => {
          return (
            <Can
              key={row.id}
              role={[
                'CompanyAdmin',
                'UtilityAdmin',
                'DeviceGroupAdmin',
                'admin',
              ]}
            >
              <DropdownBtn
                dropdownPosition={index! > 4 ? 'top' : 'bottom'}
                renderContent={
                  <TableDropdown>
                    <Can
                      role={[
                        'CompanyAdmin',
                        'UtilityAdmin',
                        'DeviceGroupAdmin',
                        'admin',
                      ]}
                    >
                      <DropdownItem onClick={() => onInstallFirmware(row)}>
                        Install Firmware
                      </DropdownItem>
                    </Can>
                    <Can
                      role={[
                        'CompanyAdmin',
                        'UtilityAdmin',
                        'DeviceGroupAdmin',
                        'admin',
                      ]}
                    >
                      <DropdownItem onClick={() => onDevicePull(row.id)}>
                        Pull Data
                      </DropdownItem>
                    </Can>
                    <Can
                      role={[
                        'CompanyAdmin',
                        'UtilityAdmin',
                        'DeviceGroupAdmin',
                        'admin',
                      ]}
                    >
                      <DropdownItem
                        onClick={() => {
                          onDeviceExport(row);
                        }}
                      >
                        Export Asset Data
                      </DropdownItem>
                    </Can>
                    <Can role={'admin'}>
                      <DropdownItem onClick={() => onShadowDevice(row)}>
                        Asset Shadow
                      </DropdownItem>
                    </Can>
                  </TableDropdown>
                }
                shape="circle"
                variant="light"
                dropIcon={false}
              >
                <BsThreeDots />
              </DropdownBtn>
            </Can>
          ) as any;
        },
        center: true,
        compact: true,
        style: {
          '& button': {
            padding: '14px',
            fontSize: '12px',
            minWidth: '32px',
            minHeight: '32px',
          },
        },
      },
    ],
    [possibleColumns]
  );

  const userGroupsQuery = useGroupListForUsers({
    variables: {
      users: [refactoredUser?.username!],
    },
  });
  const userGroups = userGroupsQuery.data?.groupListForUsers[0]?.groups;
  const companies = useListCompanies();
  const allCompanies = companies.data?.listCompanies;

  const userGroupData = buildUserGroupData();
  const userCompanies = getUserCompanies();

  const handleRowClicked = (device: Device) => {
    navigate('/assets/' + device.id);
  };

  const handlePageChange = (page: number) => {
    UserSettingsUtils.savePage(page);
    setPage(page);
  };

  const handleChangeRowsPerPage = (perPage: number, page: number) => {
    UserSettingsUtils.saveAssetsPerPage(perPage);

    setPerPage(perPage);
  };

  const devices = useListDevices({
    variables: {
      date: moment().format('YYYY-M-D'),
      ...searchData,
      page,
      size: perPage,
      ...(!dynamicData ? undefined : coords),
    },
  });

  const deviceRows = devices?.data?.listDevices.rows || [];

  const callback = () => {
    calculateAndSetTableHeight();
  };

  const onSearch = (searchData: any) => {
    if (onDynamicDataChange) onDynamicDataChange(false);
    UserSettingsUtils.saveSearch({ ...searchData });

    setSearchData({ ...searchData });

    setAdvancedSearchModalOpen(false);
  };

  const onTextSearch = (searchData: any) => {
    if (onDynamicDataChange) onDynamicDataChange(false);
    UserSettingsUtils.saveSearch(searchData);

    setSearchData({ ...searchData });
  };

  useEffect(() => {
    onDevicesChange(deviceRows as DeviceWithBatteryLife[]);
    // (async () => {
    //     if (deviceRows.every((device) => !!device?.batteryLife)) return;

    //     const newDevices = await Promise.all(deviceRows.map(async (device: any) => {

    //       const { data } = await signedQueryRequest<GetBatteryLifeQuery>(getBatteryLife, {
    //         deviceId: device!.id,
    //       })

    //       return {
    //         ...device,
    //         batteryLife: data.getBatteryLife.percentage || '-',
    //       } as any;
    //     }));

    //     setDevices(newDevices);

    //   })()
    callback();
  }, [deviceRows]);

  useEffect(() => {
    callback();
    window.addEventListener('resize', callback);
    const userSettingsColumns = UserSettingsUtils.getItem('columns') || [];
    setPossibleColumns((columns) =>
      columns.map((column) => ({
        ...column,
        visible: userSettingsColumns.includes(column.id),
      }))
    );
    return () => {
      window.removeEventListener('resize', callback);
    };
  }, []);

  function calculateAndSetTableHeight() {
    const tableBody: HTMLTableSectionElement | null =
      document.querySelector('.rdt_TableBody');

    if (!tableBody) return;

    const distanceFromTopToTHeadBottom = document
      .querySelector('.rdt_TableHead')
      ?.getBoundingClientRect().bottom;

    const distanceFromTopToContentBottom = document
      .querySelector('#root > div > div')
      ?.getBoundingClientRect().bottom;

    const paginationSize =
      document.querySelector('.rdt_Pagination')?.scrollHeight!;

    const contentPadding = window.getComputedStyle(
      document.querySelector('#root > div > div')!,
      null
    ).paddingBottom;

    const paddingToNum = parseFloat(
      contentPadding.slice(0, contentPadding.indexOf('p'))
    );

    const maxHeight =
      distanceFromTopToContentBottom! -
      paddingToNum -
      paginationSize -
      distanceFromTopToTHeadBottom!;

    tableBody.style.height = maxHeight + 'px';
  }

  function buildUserGroupData() {
    if (!userGroups) return [];

    return userGroups!.map((group) => {
      const regexDivided = [
        ...group?.description?.matchAll(/(\w+):\s+(\w+)/g)!,
      ];

      const dividedToObject = regexDivided.reduce(
        (accumulator: any, currentValue) => {
          const name = currentValue[1];
          const value = currentValue[2];

          accumulator[name] = value;

          return accumulator;
        },
        {}
      );

      return {
        ...dividedToObject,
        ...group,
      };
    });
  }

  function getUserCompanies() {
    if (!allCompanies) return [];

    if (refactoredUser?.isAdmin()) return allCompanies;

    const userCompanies = allCompanies.filter((company) => {
      const { alias } = company!;

      const userHasThisCompany = userGroupData.some(
        (group) => group.company === alias
      );

      return userHasThisCompany;
    });

    return userCompanies;
  }

  return (
    <DataContainer fullMap={fullMap} showingMap={showingMap}>
      <AdvancedSearchModal
        open={advancedSearchModalOpen}
        closeModalFunc={setAdvancedSearchModalOpen}
        onSearch={onSearch}
        companies={userCompanies}
        loadingCompanies={companies.isLoading}
      />
      <EditColumnsModal
        open={editColumnsModalOpen}
        closeModalFunc={setEditColumnsModalOpen}
        possibleColumns={possibleColumns}
        setPossibleColumns={setPossibleColumns}
      />
      <DataOptions>
        <>
          <AssetsSearch
            dropdownData={[
              {
                label: 'Customer ID',
                name: 'meterId',
              },
              {
                label: 'Device ID',
                name: 'deviceId',
              },
              {
                label: 'Device Address',
                name: 'address',
              },
              {
                label: 'Company',
                name: 'company',
                omit: refactoredUser?.isAdmin()
                  ? false
                  : userCompanies.length <= 1,
              },
              {
                label: 'Utility',
                name: 'utility',
                omit: refactoredUser?.hasRole([
                  'admin',
                  'CompanyAdmin',
                  'UtilityAdmin',
                  'UtilityPowerUser',
                ]),
              },
              {
                label: 'Group',
                name: 'group',
              },
            ]}
            onSearch={onTextSearch}
          />
          <Button
            variant="light-primary"
            startIcon={<BiPlusCircle size={14} />}
            onClick={() => setAdvancedSearchModalOpen(true)}
          >
            Advanced Search
          </Button>
          <Button
            onClick={() => {
              setEditColumnsModalOpen(true);
            }}
            variant="light-primary"
          >
            Edit columns
          </Button>
        </>
      </DataOptions>
      <TableWrapper>
        { perPage && <DataT
          paginationDefaultPage={page}
          onRowClicked={handleRowClicked}
          pointerOnHover={true}
          columns={columns}
          data={deviceRows}
          progressPending={devices.isLoading}
          pagination
          paginationServer
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          paginationTotalRows={devices.data?.listDevices.count}
          paginationPerPage={perPage}
          customStyles={{
            rows: {
              style: {
                minHeight: '80px',
                flex: '1',
                maxHeight: 100,
                '&:nth-child(odd)': {
                  backgroundColor: theme.colors.neutral,
                },
                '&:nth-child(even)': {
                  backgroundColor: theme.colors.white,
                },
                border: 'none',
              },
            },
            table: {
              style: {
                '& div:first-child': {
                  overflow: 'visible !important',
                },
                '& .rdt_TableBody': {
                  overflow: 'auto',
                },
              },
            },
          }}
          defaultSortFieldId="alarms"
          defaultSortAsc={false}
        />}

        <TableButtons>
          {!showingMap && (
            <Button
              variant="light-primary"
              startIcon={<ImMap2 />}
              size="sm"
              onClick={() => {
                if (onShowingMapChange) onShowingMapChange(true);
              }}
            >
              Show Map
            </Button>
          )}
        </TableButtons>
      </TableWrapper>
    </DataContainer>
  );
};

export default DeviceTable;
