import styled from 'styled-components';
import Button from '../../../components/Button/Button';
import breakpoints from '../../../styles/breakpoints';

export const FormBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    width: 136px;
    height: 64px;
  }
`;

export const ContainerTitle = styled.div`
  margin: 64px 0 0px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    width: 100%;

    color: ${(props) => props.theme.colors.black};
  }

  > img {
    height: 100px;
    margin: 27px;
  }

  > div:nth-child(2) {
    > span {
      > b {
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

export const ContainerText = styled.div`
  display: flex;

  margin: 27px 0 3px 0;
`;

export const List = styled.ol`
  margin: 0 0 27px 0;
  list-style-position: inside;

  > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    width: 100%;

    color: ${(props) => props.theme.colors.black};
  }
`;

export const ContainerAuthentication = styled.div`
  display: flex;
  /* @media (max-width: ${breakpoints.md}) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  @media (max-width: ${breakpoints.lg}) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  } */


`;

export const GenerateQrCodeButton = styled(Button)`
  padding: 6px 8px;
  font-size: 12px;
  line-height: 20px;
  height: 32px;
  min-height: 32px;
  margin-top: 10px;
  margin-bottom: 20px;
`;
