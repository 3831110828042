import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";
import { ContainerProps } from "./Col";


export const Container = styled.div<ContainerProps>`

    width: calc(100% * ${props => props.xs || 12} / 12);
    /* flex: 1; */

    @media(min-width: ${breakpoints.sm}) {

        ${props => props.sm && `max-width: calc(100% * ${props.sm} / 12);`}

    }
    @media(min-width: ${breakpoints.md}) {

        ${props => props.md && `max-width: calc(100% * ${props.md} / 12);`}

    }
    @media(min-width: ${breakpoints.lg}) {

        ${props => props.lg && `max-width: calc(100% * ${props.lg} / 12);`}

    }
    @media(min-width: ${breakpoints.xl}) {

        ${props => props.xl && `max-width: calc(100% * ${props.xl} / 12);`}

    }
    @media(min-width: ${breakpoints.xxl}) {

        ${props => props.xxl && `max-width: calc(100% * ${props.xxl} / 12);`}

    }
    @media(min-width: ${breakpoints.md}) {

        ${props => props.md && `max-width: calc(100% * ${props.md} / 12);`}

    }
    @media(min-width: ${breakpoints.lg}) {

        ${props => props.lg && `max-width: calc(100% * ${props.lg} / 12);`}

    }
    @media(min-width: ${breakpoints.xl}) {

        ${props => props.xl && `max-width: calc(100% * ${props.xl} / 12);`}

    }
    @media(min-width: ${breakpoints.xxl}) {

        ${props => props.xxl && `max-width: calc(100% * ${props.xxl} / 12);`}

    }

`;
