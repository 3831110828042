import {
  MainTitleWrapper,
  OptionalTitleContentWrapper,
  Title,
  TitleContainer,
} from '../../components/MainLayout/style';

import { MdOutlineCloudDownload } from 'react-icons/md';

import { GeneralContainer, ModalButton } from './style';

import React, { useEffect, useRef, useState } from 'react';
import { Device } from '../../API';
import Can from '../../components/Can/Can';
import DeviceShadowModal from '../../components/Modal/DeviceShadowModal/DeviceShadowModal';
import ExportAssetModal from '../../components/Modal/ExportAssetModal/ExportAssetModal';
import InstallFirmwareModal from '../../components/Modal/InstallFirmwareModal/InstallFirmwareModal';
import PullDataModal from '../../components/Modal/PullDataModal/PullDataModal';
import NavTabs from '../../components/NavTabs/NavTabs';
import { useAuth } from '../../contexts/Auth';
import UserSettingsUtils from '../../utils/UserSettingsUtils';
import DeviceMap, { Coords } from './DeviceMap/DeviceMap';
import DeviceTable from './DeviceTable/DeviceTable';
import ExportBatteryLifeReportModal from '../../components/Modal/ExportBatteryLifeReportModal/ExportBatteryLifeReportModal';

type DeviceWithBatteryLife = Device & {
  batteryLife?: number;
};

const Devices: React.FC = () => {
  const canFetchDevices = useRef(true);

  const { signedQueryRequest, refactoredUser } = useAuth();
  const [fullMap, setFullMap] = useState(false);

  const [installFirmwareModalOpen, setInstallFirmwareModalOpen] =
    useState(false);
  const [devices, setDevices] = useState<Array<DeviceWithBatteryLife | null>>(
    []
  );
  const [onlyTable, setOnlyTable] = useState(true);

    const [exportBatteryLifeReportModalOpen, setExportBatteryLifeReportModalOpen] = useState(false);

  const [exportDeviceModalOpen, setExportDeviceModalOpen] = useState(false);
  const [devicesToBeExported, setDevicesToBeExported] = useState<
    Array<Device | null>
  >([]);

  const [allDevices, setAllDevices] = useState<Array<Device | null>>([]);

  const [devicesToInstallFirmwares, setDevicesToInstallFirmwares] = useState<
    Array<Device | null>
  >([]);

  const [deviceBeingPulled, setDeviceBeingPulled] = useState<string | null>(
    null
  );
  const [pullDataModalOpen, setPullDataModalOpen] = useState(false);

  const [deviceShadowModalOpen, setDeviceShadowModalOpen] = useState(false);
  const [deviceBeingShadowed, setDeviceBeingShadowed] = useState<Device | null>(
    null
  );

  const [searchData, setSearchData] = useState(
    UserSettingsUtils.getItem('search') || {}
  );

  const [coords, setCoords] = useState<Coords>({});

  const [dynamicMap, setDynamicMap] = useState(true);
  const [showingMap, setShowingMap] = useState(true);

  const changeFullMap = (fullMap: boolean) => {
    setShowingMap(true);
    setFullMap(fullMap);
  };

  const changeShowingMap = (showingMap: boolean) => {
    console.debug('Changing Map');
    setShowingMap(showingMap);
    if (!showingMap) {
      setFullMap(false);
    }
  };

  const downloadManual = async () => {
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', '/pdfs/Energy Array Device Manager - Customer Version.pdf');
    downloadAnchorNode.setAttribute('download', 'Energy Array Device Manager - Customer Version' + '.pdf');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const handleInstallFirmwareForDevice = async (
    device: Device | null = null
  ) => {
    if (device) {
      setDevicesToInstallFirmwares([device]);
    } else {
      setDevicesToInstallFirmwares([]);
    }

    setInstallFirmwareModalOpen(true);
  };

  const handleExportBatteryLifeReportBtn = () => {
    setExportBatteryLifeReportModalOpen(true);
  };

  const handleExportDevicesBtn = async (device?: Device) => {
    setDevicesToBeExported(device ? [device] : []);

    setExportDeviceModalOpen(true);
  };

  const onTextSearch = (searchData: any) => {
    setDynamicMap(false);
    UserSettingsUtils.saveSearch(searchData);

    setSearchData({ ...searchData });
  };

  useEffect(() => {
    setDevicesToBeExported([]);
    setDevicesToInstallFirmwares(allDevices);
  }, [allDevices]);

  useEffect(() => {
    if (!pullDataModalOpen) {
      setDeviceBeingPulled(null);
    }
  }, [pullDataModalOpen]);

  useEffect(() => {
    if (deviceBeingPulled) setPullDataModalOpen(true);
  }, [deviceBeingPulled]);

  useEffect(() => {
    if (!deviceShadowModalOpen) {
      setDeviceBeingShadowed(null);
    }
  }, [deviceShadowModalOpen]);

  useEffect(() => {
    if (deviceBeingShadowed) setDeviceShadowModalOpen(true);
  }, [deviceBeingShadowed]);

  return (
    <>
      <ExportBatteryLifeReportModal
        open={exportBatteryLifeReportModalOpen}
        closeModalFunc={setExportBatteryLifeReportModalOpen}
      />
      <ExportAssetModal
        devicesToBeExported={devicesToBeExported}
        open={exportDeviceModalOpen}
        closeModalFunc={setExportDeviceModalOpen}
      />
      <InstallFirmwareModal
        open={installFirmwareModalOpen}
        closeModalFunc={setInstallFirmwareModalOpen}
        devicesToInstallFirmwares={devicesToInstallFirmwares}
      />

      <PullDataModal
        open={pullDataModalOpen}
        closeModalFunc={setPullDataModalOpen}
        deviceBeingPulled={deviceBeingPulled}
      />

      <DeviceShadowModal
        open={deviceShadowModalOpen}
        closeModalFunc={setDeviceShadowModalOpen}
        deviceBeingShadowed={deviceBeingShadowed}
      />

      <TitleContainer>
        <MainTitleWrapper>
          <Title>Assets</Title>
        </MainTitleWrapper>
        <OptionalTitleContentWrapper>
          <Can
            role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}
          >
            <ModalButton
              startIcon={
                <img src="/svgs/battery-three-quarters.svg" width={14} height={14} />
              }
              variant="light-primary"
              onClick={() => handleExportBatteryLifeReportBtn()}
            >
              Export battery life report
            </ModalButton>
            <ModalButton
              startIcon={
                <img src="/svgs/compare-arrows.svg" width={14} height={14} />
              }
              variant="light-primary"
              onClick={() => handleExportDevicesBtn()}
            >
              Export all data
            </ModalButton>
            <ModalButton
              startIcon={<MdOutlineCloudDownload size={14} />}
              variant="light-primary"
              onClick={() => handleInstallFirmwareForDevice()}
            >
              Install firmware
            </ModalButton>
          </Can>

          <ModalButton
            startIcon={
              <img src="/svgs/book.svg" width={14} height={14} />
            }
            variant="light-primary"
            onClick={() => downloadManual()}
          >
            Download manual
          </ModalButton>
        </OptionalTitleContentWrapper>
      </TitleContainer>
      <NavTabs
        items={[
          {
            text: 'Inventory',
            to: '#',
            onClick: () => setOnlyTable(true),
            active: onlyTable,
          },
          {
            text: 'Map',
            to: '#',
            onClick: () => setOnlyTable(false),
            active: !onlyTable,
          },
        ]}
      />
      {!onlyTable && (
        <GeneralContainer fullMap={fullMap} showingMap={showingMap}>
          <DeviceMap
            onCoordsChange={setCoords}
            fullMap={fullMap}
            showingMap={showingMap}
            dynamicData={dynamicMap}
            onDynamicDataChange={setDynamicMap}
            onChangeFullMap={changeFullMap}
            devices={devices}
            onShowingMapChange={changeShowingMap}
          />
          <DeviceTable
            dynamicData={dynamicMap}
            onDevicesChange={setDevices}
            onTextSearchChange={onTextSearch}
            coords={coords}
            onShadowDevice={setDeviceBeingShadowed}
            onDeviceExport={handleExportDevicesBtn}
            onDevicePull={setDeviceBeingPulled}
            onInstallFirmware={handleInstallFirmwareForDevice}
            fullMap={fullMap}
            showingMap={showingMap}
            onDynamicDataChange={setDynamicMap}
            onShowingMapChange={changeShowingMap}
          />
        </GeneralContainer>
      )}
      {onlyTable && (
        <DeviceTable
          dynamicData={dynamicMap}
          onDevicesChange={setDevices}
          onTextSearchChange={onTextSearch}
          coords={coords}
          onShadowDevice={setDeviceBeingShadowed}
          onDeviceExport={handleExportDevicesBtn}
          onDevicePull={setDeviceBeingPulled}
          onInstallFirmware={handleInstallFirmwareForDevice}
          fullMap={fullMap}
          showingMap={true}
          onDynamicDataChange={setDynamicMap}
        />
      )}
    </>
  );
};

export default Devices;
