import styled from "styled-components";

type ButtonProps = {
    active: boolean;
}

export const Container = styled.div`

    display: flex;
    border-radius: ${props => props.theme.borderRadius};
    overflow: hidden;
    height: 100%;
    border: 1px solid ${props => props.theme.colors.primary};

`;

export const Button = styled.button<ButtonProps>`
    
    padding: 10px 16px;
    flex-grow: 1;
    border: none;
    background-color: ${props => props.active ? props.theme.colors.primary : props.theme.colors.white};
    color: ${props => props.active ? props.theme.colors.white : props.theme.colors.primary};
    font-size: 14px;
    line-height: 20px;

    
`;