import React, { useRef, useState } from "react";

import { Button, Container } from "./style";

export type ButtonItem = {
  label: string;
  value?: React.HTMLAttributes<HTMLInputElement>["defaultValue"];
  disabled?: boolean;
  onClick?: (e: any) => void;
  style?: React.CSSProperties;
};

type Props = {
  buttons: ButtonItem[];
  block?: boolean;
  initialSelectedIndex?: number;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
};

const ButtonsGroup: React.FC<Props> = ({ style, buttons, block, initialSelectedIndex = 0, onChange }) => {
  const [selectedBtnIndex, setSelectedBtnIndex] = useState(initialSelectedIndex);
  const inputRef = useRef<HTMLInputElement>(null);

  const onBtnClick = (
    index: number,
    value: React.HTMLAttributes<HTMLInputElement>["defaultValue"]
  ) => {
    if (selectedBtnIndex === index) return;

    if (inputRef.current && typeof value === "string") {
      inputRef.current.value = value;

      if (onChange) onChange(value);
    }

    setSelectedBtnIndex(index);
  };

  return (
    <>
      <Container
        style={{
          width: block ? "100%" : "fit-content",
          ...style
        }}
      >
        <input type="hidden" ref={inputRef} />
        {buttons.map(({ label, value, disabled }, index) => (
          <Button
            key={index}
            disabled={disabled}
            active={index === selectedBtnIndex}
            onClick={() => onBtnClick(index, value)}
            className={index === selectedBtnIndex ? "active" : ""}
          >
            {label}
          </Button>
        ))}
      </Container>
    </>
  );
};

export default ButtonsGroup;
