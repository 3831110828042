/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


// Development
// const awsmobile = {
//   aws_appsync_graphqlEndpoint:
//     'https://cunhwwjtzfhyxbixa7pri7se6i.appsync-api.us-east-1.amazonaws.com/graphql',
//   aws_appsync_region: 'us-east-1',
//   aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
//   aws_appsync_apiKey: 'da2-vkdxmij47zcwln4wcfueh2yx4y',
// };

// Production
const awsmobile = {
  aws_appsync_graphqlEndpoint:
    'https://hyngsxqjbrf3firbyzn3vcknbi.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-nxjozryuyzhhhggdc62evkeycm',
};

export default awsmobile;
