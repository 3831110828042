export default class DateUtils {

    static to(text: string): string {
        const toLowerCase = text.toLowerCase();
        const camelCase = toLowerCase.replaceAll(/ +([a-z])/g, (match, letter: string) => letter.toUpperCase());

        return camelCase;
    }

    static daysToMonths(days: string | number): number {
        if (typeof days !== 'number') {
            days = parseFloat(days);
        }

        return Math.ceil(days / 30);
    }

    static monthsToDays(months: string | number): number {
        if (typeof months !== 'number') {
            months = parseFloat(months);
        }

        return months * 30;
    }

    // epoch in ms
    static formatDateWithTmZn(epoch: number, TmZn: number = 0, proto: number = 0) {
        if (proto < 1) {
            // TmZn conversion should only be done when proto >= 1
            TmZn = 0;
        }

        let dateString = new Date(epoch + TmZn * 3600000).toUTCString();
        let offsetHours = Math.floor(TmZn);
        const offsetMinutes = (TmZn - offsetHours) * 60 // For cases like Newfoundland TZ (UTC-03:30)

        let isNegative = false;
        if (offsetHours < 0) {
            offsetHours *= -1;
            isNegative = true;
        }

        // Remove GMT (from the .toUTCString fn) and add the TmZn offset
        dateString = dateString.slice(0, -3);
        dateString += isNegative ? '-' : '+';
        dateString += offsetHours.toString().padStart(2, '0')
        dateString += ':';
        dateString += offsetMinutes.toString().padStart(2, '0');

        return dateString;
    }

}