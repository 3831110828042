import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
export const Input = styled.input`
  position: relative;
  width: 18px;
  height: 18px;
  appearance: none;
  border: 2px solid ${(props) => props.theme.colors.primaryText};
  border-radius: 50%;
  background-color: transparent;

  cursor: pointer;

  &:disabled {
    color: ${(props) => props.theme.colors.primaryText};
    background-color: ${(props) => props.theme.colors.lightGray};
    cursor: default;
    opacity: 0.1;

    & ~ label {
      color: ${(props) => props.theme.colors.primaryText};
      opacity: 0.1;
    }
  }

  &:checked {
    &::after {
      display: block;
    }
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
    width: 60%;
    height: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primaryText};
    z-index: 5;
  }
`;
export const Label = styled.label`
  margin-left: 11px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.black};
`;
