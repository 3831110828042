import styled from 'styled-components';

export const Title = styled.h1`


`;

export const Subtitle = styled.h2`

    & strong {
        font-weight: bold;
        color: ${props => props.theme.colors.primary}
    }

`;
