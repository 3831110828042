import { Link } from 'react-router-dom';
import styled from 'styled-components';

type NavItemProps = {
  active?: boolean;
  disabled?: boolean;
};

export const NavItem = styled.li<NavItemProps>`
  ${(props) =>
    props.active
      ? `background: linear-gradient(
      0deg,
      ${props.theme.colors.primary}B3,
      ${props.theme.colors.primary}B3
    ),
    linear-gradient(0deg, #000000, #000000);`
      : `background-color: ${props.theme.colors.primary};`}
  font-weight: ${(props) => (props.active ? 'bold' : 'lighter')};
  
  opacity: ${props => props.disabled ? '0.3' : '1'};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  border-radius: ${(props) => props.theme.borderRadius};
  margin-bottom: 10px;

  &:hover {
    background: linear-gradient(
        0deg,
        ${(props) => props.theme.colors.primary}B3,
        ${(props) => props.theme.colors.primary}B3
      ),
      linear-gradient(0deg, #000000, #000000);
  }
`;

export const ItemLink = styled(Link)`
  color: white;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 22px 24px;
  & img, svg {
    margin-right: 8px;
    font-size: 18px;
  }
`;

export const LinkText = styled.span``;
