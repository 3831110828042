import React, { useEffect, useRef, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';

import { useTheme } from 'styled-components';
import StringUtils from '../../../utils/StringUtils';
import Typography from '../../Typography/Typography';
import {
  Container,
  Label,
  Slider,
  SliderContainer,
  ThumbNumber,
} from './style';

export type RangeProps = {
  label?: string;
  name?: string;
  id?: string;
  error?: string;
  register?: UseFormRegister<any>;
  min: number;
  max: number;
  value?: number;
  disabled?: boolean;
  subLabel?: string;
  step?: number;

  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Range: React.FC<RangeProps> = ({
  label,
  error,
  register,
  name,
  id = StringUtils.toCamelCase(name || ''),
  min,
  max,
  value = 0,
  disabled = false,
  subLabel = '',
  step,
  onChange,
}) => {
  const sliderContainerRef = useRef<HTMLDivElement>(null);
  const thumbNumberRef = useRef<HTMLSpanElement>(null);
  const [currentValue, setCurrentValue] = useState(value.toString());
  const sliderRef = useRef<HTMLInputElement>(null);
  const { colors } = useTheme();

  const onSliderChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) onChange(e);

    changeInputValue(parseFloat(e.currentTarget.value));
  };

  const registerFunc = register
    ? register(name || '', {
        onChange: onSliderChange,
        value,
        disabled,
      })
    : {
        name,
        defaultValue: value,
      };

  const getThumbXPosition = (value: number): number => {
    const inputWidth = sliderContainerRef.current!.offsetWidth;

    const inputValue = currentValue;

    return ((value - min) / (max - min)) * (inputWidth - 12 - 12) + 12;
  };

  const changeInputValue = (newValue: number): void => {
    if (thumbNumberRef.current) {
      const inputWidth = sliderContainerRef.current!.offsetWidth;
      const newPosition = getThumbXPosition(newValue);
      thumbNumberRef.current.style.left = newPosition.toString() + 'px';
      thumbNumberRef.current.style.transform = `translateX(-${
        (100 * newPosition) / inputWidth
      }%)`;

      const decimalDigits = step?.toString().split('.')[1];

      thumbNumberRef.current.textContent = newValue.toFixed(
        decimalDigits ? decimalDigits.length : undefined
      );
      setCurrentValue(
        newValue.toFixed(decimalDigits ? decimalDigits.length : undefined)
      );
      // console.log(
      //   newValue.toFixed(decimalDigits ? decimalDigits.length : undefined)
      // );
    }
  };

  useEffect(() => {
    changeInputValue(value);

    if (value < min) {
      changeInputValue(min);
    }

    if (value > max) {
      changeInputValue(max);
    }
  }, [min, max, value]);

  return (
    <Container className="range-container">
      {label && <Label htmlFor={id}>{label}</Label>}
      {subLabel && (
        <Typography fontSize={12} marginBottom={25} color={colors.black + '66'}>
          {subLabel}
        </Typography>
      )}
      <SliderContainer className="slider-container" ref={sliderContainerRef}>
        <Slider
          data-testid="slider"
          ref={sliderRef}
          value={currentValue}
          id={id}
          type="range"
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          {...registerFunc}
        />
        {!disabled && (
          <ThumbNumber className="thumb-number" ref={thumbNumberRef}>
            123
          </ThumbNumber>
        )}
      </SliderContainer>
      {error && (
        <ErrorMessage
          style={{
            marginTop: 24,
          }}
        >
          {error}
        </ErrorMessage>
      )}
    </Container>
  );
};

export default Range;
