// tslint:disable
// this is an auto generated file. This will be overwritten

export const authenticateUser = `query AuthenticateUser($email: String!, $password: String!, $newPassword: String) {
  authenticateUser(email: $email, password: $password, newPassword: $newPassword)
}
`;
export const userList = `query UserList($username: String, $limit: Int, $page: Int) {
  userList(username: $username, limit: $limit, page: $page) {
    users {
      id
      username
    }
    count
  }
}
`;
export const groupListForUsers = `query GroupListForUsers($users: [String]) {
  groupListForUsers(users: $users) {
    id
    groups {
      name
      description
    }
  }
}
`;
export const userByUsername = `query UserByUsername($username: String!) {
  userByUsername(username: $username) {
    sub
    username
    email
    groups
    deviceId
  }
}
`;
export const groupList = `query GroupList {
  groupList {
    groups {
      name
      description
    }
  }
}
`;
export const getMeterData = `query GetMeterData(
  $fromDate: Long
  $toDate: Long
  $deviceId: String
  $frequency: String
) {
  getMeterData(
    fromDate: $fromDate
    toDate: $toDate
    deviceId: $deviceId
    frequency: $frequency
  ) {
    id
    data {
      deviceId
      time
      dayCV
      dayUV
      minPr
      maxPr
      minFC
      maxFC
      minTemp
      maxTemp
      bV
      ccbbV
      pofMF
      qMarg
      pr
      cF
      cV
      temp
    }
  }
}
`;
export const exportMeterData = `query ExportMeterData(
  $format: String!
  $fromDate: Long!
  $toDate: Long!
  $deviceIds: [String]!
  $frequency: String!
) {
  exportMeterData(
    format: $format
    fromDate: $fromDate
    toDate: $toDate
    deviceIds: $deviceIds
    frequency: $frequency
  ) {
    success
    message
  }
}
`;
export const exportEvents = `query ExportEvents(
  $format: String!
  $fromDate: Long!
  $toDate: Long!
  $deviceId: String!
) {
  exportEvents(
    format: $format
    fromDate: $fromDate
    toDate: $toDate
    deviceId: $deviceId
  ) {
    link
  }
}
`;
export const getDeviceGroups = `query GetDeviceGroups {
  getDeviceGroups {
    id
    name
    utility_id
    temperatureUnit
    pressureUnit
    volumeUnit
    voltageUnit
    correctedFlowUnit
    utility {
      id
      name
      company_id
      company {
        id
        name
        alias
        theme
        logo
        region
        temperatureUnit
        pressureUnit
        volumeUnit
        voltageUnit
        correctedFlowUnit
      }
    }
  }
}
`;
export const listDevices = `query ListDevices(
  $date: String
  $deviceId: String
  $meterId: String
  $address: String
  $owner: String
  $group: String
  $utility: String
  $company: String
  $latitudeLo: Float
  $latitudeHi: Float
  $longitudeLo: Float
  $longitudeHi: Float
  $page: Int
  $size: Int
) {
  listDevices(
    date: $date
    deviceId: $deviceId
    meterId: $meterId
    address: $address
    owner: $owner
    group: $group
    utility: $utility
    company: $company
    latitudeLo: $latitudeLo
    latitudeHi: $latitudeHi
    longitudeLo: $longitudeLo
    longitudeHi: $longitudeHi
    page: $page
    size: $size
  ) {
    rows {
      id
      device_group_id
      meterId
      serialNo
      group {
        id
        name
        utility_id
        utility {
          id
          name
          company_id
          company {
            id
            name
            alias
            theme
            logo
            region
            temperatureUnit
            pressureUnit
            volumeUnit
            voltageUnit
            correctedFlowUnit
          }
        }
      }
      user_device {
        user_id
        device_id
        user {
          id
          name
          email
        }
      }
      latitude
      longitude
      address
      messages {
        id
        time
        urgent
        dId
        status
        comment
        alarms {
          lmt
          threshold
          t
          type
          v
        }
      }
      thresholds {
        device_id
        threshold_key
        value
      }
      data {
        meterSize
        ingestionTime
        dTm
        TmZn
        proto
        bV
        ccbbV
        dayCV
        dayUV
        fUV
        fCV
        cFAvg
        maxFC {
          t
          v
        }
        maxPr {
          t
          v
        }
        maxTemp {
          t
          v
        }
        minFC {
          t
          v
        }
        minPr {
          t
          v
        }
        minTemp {
          t
          v
        }
        pofMF
        qMarg
      }
    }
    count
  }
}
`;
export const listUnassignedDevices = `query ListUnassignedDevices {
  listUnassignedDevices
}
`;
export const getCompaniesByGroups = `query GetCompaniesByGroups($groups: [String]) {
  getCompaniesByGroups(groups: $groups) {
    companies {
      id
      name
      alias
      theme
      logo
      region
      temperatureUnit
      pressureUnit
      volumeUnit
      voltageUnit
      correctedFlowUnit
    }
  }
}
`;
export const listFirmware = `query ListFirmware {
  listFirmware
}
`;
export const listFirmwareVersion = `query ListFirmwareVersion($component: String) {
  listFirmwareVersion(component: $component)
}
`;
export const getFirmware = `query GetFirmware($version: String) {
  getFirmware(version: $version) {
    name
    url
  }
}
`;
export const listCaCertificate = `query ListCaCertificate {
  listCaCertificate {
    key
    date
    name
  }
}
`;
export const listCompanies = `query ListCompanies {
  listCompanies {
    id
    name
    alias
    theme
    logo
    region
    temperatureUnit
    pressureUnit
    volumeUnit
    voltageUnit
    correctedFlowUnit
  }
}
`;
export const getDeviceShadow = `query GetDeviceShadow(
  $deviceId: String!
  $region: String!
  $prettify: Boolean
) {
  getDeviceShadow(deviceId: $deviceId, region: $region, prettify: $prettify)
}
`;
export const getAlarmComment = `query GetAlarmComment($alarmId: String!, $time: Long!) {
  getAlarmComment(alarmId: $alarmId, time: $time)
}
`;
