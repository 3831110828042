import styled from "styled-components";

export const PaddingContainer = styled.div`

    position: relative;
    margin-bottom: 24px;
    
    &::before {
        content: '';
        position: absolute;
        display: block;
        width: 1px;
        height: 100%;
        background-color: ${props => props.theme.colors.gray};
        z-index: 9999;
    }

    & > * {
        padding-left: 15px;
    }

`;