import styled from 'styled-components';

type InputProps = {
  error: boolean;
};

export const InputWrapper = styled.div<InputProps>`
  width: 100%;
  position: relative;
  border-radius: ${(props) => props.theme.borderRadius};
`;

export const Input = styled.textarea<InputProps>`
  border: none;
  resize: none;
  width: 100%;
  outline: none;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 12px;
  transition: all 100ms ease-in-out;
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.gray};

  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &:is(:not(:placeholder-shown), :focus) + label {
    font-size: 14px;
    top: 0;
    color: ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.primary};
  }

  &:is(:not(:placeholder-shown), :focus) {
    border-color: ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.primary};
  }
`;

export const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 13px;
  cursor: pointer;
  & > svg {
    transform: translate(0, -50%);
    color: ${(props) => props.theme.colors.primary};
    font-size: 20px;
  }
`;

export const InputContentContainer = styled.div``;

export const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.danger};
  font-size: 12px;
`;
