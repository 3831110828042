import styled, { css } from 'styled-components'

type DropdownLinkProps = {

    selected: boolean;

}

export const Container = styled.div`

    position: relative;
`;

export const Dropdown = styled.div`

    position: absolute;
    top: 100%;
    left: 0;
    padding: 16px 2px;
    border-radius: ${props => props.theme.borderRadius};
    border: 1px solid ${props => props.theme.colors.gray};
    width: 100%;
    z-index: 10;
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);


`;

const selectedStyle = css`

    background-color: #0C23401A;
    font-weight: bold;

    & > svg {

        display: block;

    }

`;

export const DropdownLink = styled.button<DropdownLinkProps>`

    padding: 8px 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;

    & > svg {
        display: none;
        font-size: 20px;
    }

    ${props => props.selected && selectedStyle}

    :hover {

      ${selectedStyle}

    }

`;

export const LinkText = styled.span`

    font-size: 14px;
    line-height: 24px;

`;
