import React, { PropsWithChildren } from 'react';

import { RadioG, Label } from './style';

type RadioGroupProps = {
  label: string;
};

const RadioGroup: React.FC<PropsWithChildren<RadioGroupProps>> = ({ children, label }) => {
  return (
    <RadioG>
      <Label>{label}</Label>
      {children}
    </RadioG>
  );
};

export default RadioGroup;
