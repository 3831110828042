import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import errorMessages from '../../config/errorMessages';
import ButtonsGroup from '../ButtonsGroup/ButtonsGroup';
import FormControl from '../Forms/FormControl/FormControl';
import { BottomButtons, Container, Picker, PickerButton } from '../Modal/AssetConfigurationModal/style';
import Typography from '../Typography/Typography';

export type OnOkBtnTimePickerParams = {
    date: Date;
    name: string;
}
export type OnOkBtnClickTimePicker = (params: OnOkBtnTimePickerParams) => void;

export type TimePickerCardProps = {
    open?: boolean;
    onClose?: () => void;
    onOkBtnClick?: OnOkBtnClickTimePicker;
    onCancelBtnClick?: () => void;
    name: string;
    showMinuteInput?: boolean;
    minMinute?: number;
    maxMinute?: number;
    minHour?: number;
    maxHour?: number;
    originalValue: string;
    forceMeridian?: boolean;
    forceMeridianValue?: string;
}

type FormData = {
    hours: string;
    minutes?: string;
}

const TimePickerCard: React.FC<TimePickerCardProps> = ({ open = true, onCancelBtnClick, showMinuteInput = false, originalValue = '09:00:00 AM', minMinute = 0, maxMinute = 59, minHour = 1, maxHour = 12, forceMeridian = false, forceMeridianValue, onOkBtnClick, name }) => {
    const schema = yup.object({
        minutes: yup.number().min(minMinute, errorMessages.minNumber(minMinute)).max(maxMinute),
        hours: yup.number().min(minHour, errorMessages.minNumber(minHour)).max(maxHour),
    });

    const timeComponents = originalValue.split(':');
    const originalHour = Number(timeComponents[0]);
    const originalMinute = Number(timeComponents[1]);
    
    const { trigger, register, getValues, setValue, formState: { errors } } = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    const [meridien, setMeridien] = useState(forceMeridian ? forceMeridianValue! : originalValue.includes('AM') ? 'AM' : 'PM');

    const { colors } = useTheme();

    const onHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const correctValue = (str: string) => {

            const num = Number(str);
            if (num > 12) return '12';

            return num.toString();
        }

        const correctedValue = correctValue(e.target.value)

        setValue(e.target.name as any, correctedValue);
    }

    const onMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const correctValue = (str: string) => {

            const num = Number(str);
            if (num > 59) return '59';

            return num.toString();
        }

        const correctedValue = correctValue(e.target.value)

        setValue(e.target.name as any, correctedValue);
    }

    const onButtonGroupChange = (value: string) => {
        setMeridien(value);
    }

    const onSuccessBtnClick = async () => {

        const transformInDate = (_hour: string, meridien: string, minute?: string,) => {

            let hour = parseInt(_hour);

            if (hour === 12) {
                hour = 0;
            }

            hour = meridien === 'PM' ? hour + 12 : hour;

            const date = new Date();
            date.setHours(hour);
            date.setMinutes(minutes ? parseInt(minutes) : 0);
            date.setSeconds(0)

            return date;
        }

        const hasNoErrors = await trigger();

        if (!hasNoErrors) return;

        const { hours, minutes } = getValues();


        const date = transformInDate(hours, meridien, minutes);

        if (onOkBtnClick) onOkBtnClick({ date, name });
    }

    if (!open) return null;

    return (

        <Container data-testid='time-picker-card'>

            <Typography color={colors.black + '66'} marginBottom={16} fontSize={12}>Enter time</Typography>

            <Picker>
                <FormControl error={errors.hours?.message} register={register} name='hours' onChange={onHourChange} min={minHour} max={maxHour} inputContainerStyle={{ width: 96 }} label='' type={'number'} value={originalHour} supportText='Hour' />
                {showMinuteInput && <FormControl register={register} error={errors.minutes?.message} name='minutes' min={minMinute} max={maxMinute} onChange={onMinuteChange} inputContainerStyle={{ width: 96 }} label='' type={'number'} value={originalMinute} supportText='Minute' />}
                <ButtonsGroup style={{
                    height: 48
                }} initialSelectedIndex={meridien === 'AM' ? 0 : 1} onChange={onButtonGroupChange}  buttons={[
                    {
                        label: 'AM',
                        value: 'AM',
                        disabled: forceMeridian,
                        
                    },
                    {
                        label: 'PM',
                        value: 'PM',
                        disabled: forceMeridian,
                    },
                ]} />
            </Picker>

            <BottomButtons>
                <PickerButton onClick={onCancelBtnClick}>Cancel</PickerButton>
                <PickerButton onClick={onSuccessBtnClick}>Ok</PickerButton>
            </BottomButtons>

        </Container>

    );

};

export default TimePickerCard;

