import React, { PropsWithChildren } from 'react';
import Typography from '../Typography/Typography';
import { PaddingContainer } from './style';

type Props = {
    title?: string;
}

const WithPadding: React.FC<PropsWithChildren<Props>> = ({ title, children }) => {
    return <>

        {title && <Typography fontWeight={700} fontSize={16} marginBottom={15} >{title}</Typography>}

        <PaddingContainer>
            {children}
        </PaddingContainer>
    </>;
}

export default WithPadding;