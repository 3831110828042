import { loadingContainer } from 'aws-amplify';

type Items =
  | 'perPage'
  | 'search'
  | 'columns'
  | 'mfa-enabled'
  | 'rows'
  | 'pages';

export default class UserSettingsUtils {
  static storageItemName: string = 'userConfig';

  static saveItem(key: Items, value: any) {
    const currentSettings = localStorage.getItem(
      UserSettingsUtils.storageItemName
    );
    const parsedSettings = currentSettings ? JSON.parse(currentSettings) : {};

    parsedSettings[key] = value;
    localStorage.setItem(
      UserSettingsUtils.storageItemName,
      JSON.stringify(parsedSettings)
    );
  }

  static getItem(key: Items) {
    const items = localStorage.getItem(UserSettingsUtils.storageItemName);

    if (!items) return null;

    const parsedItems = JSON.parse(items);

    return parsedItems[key];
  }

  static saveAssetsPerPage(perPage: number) {
    UserSettingsUtils.saveItem('perPage', perPage);
  }

  static saveColumns(columns: string[]) {
    UserSettingsUtils.saveItem('columns', columns);
  }

  static getColumns(columns: any) {
    const column = localStorage.getItem(columns);

    if (column) {
      const columnParsed = JSON.parse(column);

      return columnParsed;
    }
    return null;
  }

  static saveRows(rows: number) {
    UserSettingsUtils.saveItem('rows', rows);
  }

  static savePage(page: number) {
    UserSettingsUtils.saveItem('rows', page);
  }

  static getPages(pages: any) {
    const page = localStorage.getItem(pages);

    if (page) {
      const pageParsed = JSON.parse(page);

      return pageParsed;
    }
    return null;
  }

  static getPerPage(perPage: any) {
    const perPages = localStorage.getItem(perPage);

    if (perPages) {
      const pageParsed = JSON.parse(perPages);

      return pageParsed;
    }
    return null;
  }

  static saveSearch(search: any) {
    UserSettingsUtils.saveItem('search', search);
  }

  static clearSettings() {
    localStorage.removeItem(UserSettingsUtils.storageItemName);
  }
}
