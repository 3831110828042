import styled, { keyframes } from "styled-components";
import breakpoints from "../../styles/breakpoints";

type ModalProps = {
  closing: boolean;
};

const fadeIn = keyframes`

    from {
        background-color: transparent;
    }
    
    to {
        background-color: rgba(0, 0, 0, 0.2);

        
    }

`;

const fadeOut = keyframes`

      from {
        background-color: rgba(0, 0, 0, 0.2);
      }
      
      to {
        background-color: transparent;
      }

`;

const openModal = keyframes`


    from {
        transform: translate(100%);
        
    }
    
    to {
        transform: translate(0);
    }

`;

const closeModal = keyframes`

    from {
        transform: translate(0);
    }
    
    to {
        transform: translate(100%);
    }
    

`;

export const Background = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  animation: ${(props) => (props.closing ? fadeOut : fadeIn)} ease-in-out 500ms;
  animation-fill-mode: both;
`;

export const CloseButton = styled.button`
  outline: 0;
  background-color: transparent;
  border: none;
  font-size: 14px;
  cursor: pointer;
  display: inherit;
  margin-right: 21px;
  color: ${(props) => props.theme.colors.black};
`;

export const ModalContainer = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
  height: 100%;
  padding: 40px 40px 24px 40px;
  background-color: ${(props) => props.theme.colors.white};
  height: 100vh;
  display: flex;
  flex-direction: column;
  animation: ${(props) => (props.closing ? closeModal : openModal)} ease-in-out
    500ms;
  animation-fill-mode: ${(props) => (props.closing ? 'forwards' : 'backwards')};

  @media screen and (max-width: ${(props) => breakpoints.md}) {
    max-width: 100%;
    padding: 30px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h2`
  display: inline-block;
  font-size: 24px;
  line-height: 40px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.black};
`;

export const Description = styled.h3`
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 400;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.lightBlack};
`;

export const Content = styled.div`
  flex-grow: 1;
  max-height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 100%;
  padding: 0 5px;

  display: inline-block;
`;

export const BottomContent = styled.div`
  width: 100%;
  align-self: flex-end;
`;

export const Contents = styled.div`
  display: inline-flex;
  gap: 10px;
  flex: 1;

  overflow: scroll;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  width: 440px;
`;

export const PlusContent = styled.div`
  flex: 1;
`;

export const ModalDescription = styled.p`
  color: ${(props) => props.theme.colors.gray};
  width: 408px;
  height: 48px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
`;
