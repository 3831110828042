import React, { PropsWithChildren } from 'react';

import { TextMessage } from './style';

type Props = {
  style?: React.CSSProperties;
}

const ErrorMessage: React.FC<PropsWithChildren<Props>> = ({ children, style }) => {
  return <TextMessage style={style}>{children} </TextMessage>;
};

export default ErrorMessage;
