import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Device, UpdateDeviceShadowMutation, UpdateDeviceShadowMutationVariables } from '../../../API';
import errorMessages from '../../../config/errorMessages';
import { useAuth } from '../../../contexts/Auth';
import { updateDeviceShadow } from '../../../graphql/mutations';
import { useDeviceShadow } from '../../../hooks/queries';
import Button from '../../Button/Button';
import Checkbox from '../../Forms/Checkbox/Checkbox';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import TextArea from '../../Forms/TextArea/TextArea';
import Spinner from '../../Spinner/Spinner';
import Modal, { ModalOpeningProps } from '../Modal';

type Props = {
    deviceBeingShadowed?: Device | null;
}
const deviceShadowSchema = yup.object({
    payload: yup.string().required(errorMessages.required),
});
const DeviceShadowModal: React.FC<ModalOpeningProps & Props> = ({ open, closeModalFunc, deviceBeingShadowed }) => {

    const {
        register,
        getValues,
        trigger,
        formState: { errors },
        setValue,
    } = useForm<UpdateDeviceShadowMutationVariables>({
        resolver: yupResolver(deviceShadowSchema),
    });

    const [prettify, setPrettify] = useState(false);
    const deviceShadow = useDeviceShadow({
        variables: {
            deviceId: deviceBeingShadowed?.id!,
            region: deviceBeingShadowed?.group?.utility.company?.region!,
            prettify,
        }
    });

    const { signedMutationRequest } = useAuth();
    const [updatingDeviceShadow, setUpdatingDeviceShadow] = useState(false);

    const handleUpdateShadowSubmit = async () => {
        const hasNoErrors = await trigger();

        if (!hasNoErrors) return;

        const { payload } = getValues();

        setUpdatingDeviceShadow(true);

        await signedMutationRequest<
            UpdateDeviceShadowMutationVariables,
            {
                data: UpdateDeviceShadowMutation;
            }
        >(updateDeviceShadow, {
            deviceId: deviceBeingShadowed?.id!,
            payload: JSON.parse(payload),
            region: deviceBeingShadowed?.group?.utility.company?.region!,
        });
        setUpdatingDeviceShadow(false);
    };

    const exportDeviceShadow = () => {

        const { payload } = getValues();

        var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(JSON.parse(payload)));
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute('download', 'Device Shadow Data' + '.json');
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();

    };

    useEffect(() => {
        if (deviceShadow.data?.getDeviceShadow) {
            setValue('payload', JSON.stringify(JSON.parse(deviceShadow.data?.getDeviceShadow), null, 4));
        }
    }, [deviceShadow.data?.getDeviceShadow]);

    return <Modal
        closeModalFunc={closeModalFunc}
        open={open}
        title="Device Shadow"
        description={`Shadow of ${deviceBeingShadowed?.id} device`}
        bottomContent={
            <>
                <FormGroup>
                    <Button block loading={updatingDeviceShadow} onClick={exportDeviceShadow}>Export Data</Button>
                </FormGroup>
                <Button block loading={updatingDeviceShadow} onClick={handleUpdateShadowSubmit}>Update Shadow</Button>
            </>}
    >
        <form>
            <FormGroup>
                {deviceShadow.isLoading && <Spinner variant="primary" />}
                {!deviceShadow.isLoading && (
                    <TextArea
                        register={register}
                        error={errors.payload?.message}
                        rows={10}
                        label=""
                        id="payload"
                        name="payload" />
                )}
            </FormGroup>
            <Checkbox
                register={register}
                name="prettify"
                label="Pretty view"
                id="prettify"
                onChange={(e) => {
                    setPrettify(e.target.checked);
                }}
            />
        </form>
    </Modal>;
};

export default DeviceShadowModal;