import { ButtonShapes, ButtonVariants } from '../Button/Button';

import {
  DropdownBtn,
  DropdownContent,
  DropdownWrapper,
  DropIcon,
} from './style';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';

type DropdownPosition = 'top' | 'bottom';

type DropdownButtonProps = {
  variant?: ButtonVariants;
  shape?: ButtonShapes;
  renderContent: React.ReactNode;
  dropIcon?: boolean;
  zIndex?: number;
  dropdownPosition?: DropdownPosition;
};

const DropdownButton: React.FC<PropsWithChildren<DropdownButtonProps>> = ({
  children,
  variant,
  renderContent,
  shape,
  dropIcon = true,
  zIndex,
  dropdownPosition
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const callback = (e: any) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (!path) return;
      const clickedInDropdown = path.includes(dropdownContentRef.current!);

      if (!clickedInDropdown) {
        setDropdownOpen(false);
      }
    };

    document.body.addEventListener('click', callback);

    return () => {
      document.body.removeEventListener('click', callback);
    };
  }, []);

  return (
    <DropdownWrapper>
      <DropdownBtn
        onClick={(e) => {
          e.stopPropagation();
          setDropdownOpen((dropdownOpen) => !dropdownOpen);
        }}
        shape={shape}
        variant={variant}
      >
        {children}
        {dropIcon === true && <DropIcon />}
      </DropdownBtn>
      {dropdownOpen && (
        <DropdownContent dropdownPosition={dropdownPosition} ref={dropdownContentRef} zIndex={zIndex || 10}>
          {renderContent}
        </DropdownContent>
      )}
    </DropdownWrapper>
  );
};

export default DropdownButton;
