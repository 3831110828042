export default class Auth {

    public static getToken(): string | null {

        const userConfig = localStorage.getItem('userConfig');
        const userToken = JSON.parse(userConfig!)
            ? JSON.parse(userConfig!)['access-token']
            : null;

        return userToken;

    }

}