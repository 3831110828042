import React from 'react';
import Spinner from '../Spinner/Spinner';

import { Container } from './style';

type Props = {
    enabled: boolean;
}

const LoadingModal: React.FC<Props> = ({ enabled }) => {
    if (!enabled) return null;

    return <Container>

        <Spinner variant='primary' />

    </Container>;
}

export default LoadingModal;