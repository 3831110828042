import styled from "styled-components";
import { MapStates } from "./DeviceMap";

export const MapButtons = styled.div`
  position: absolute;
  display: flex;
  bottom: 5px;
  justify-content: space-between;
  padding: 0 5px;

  & button {
    padding: 10px 16px;
    font-size: 12px;
    line-height: 20px;
  }
`;


export const MapLeftButtons = styled.div`
  display: none;

  button {
    margin-right: 8px;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: flex;
  }
`;

export const MapContainer = styled.div<MapStates>`
  position: relative;
  border-radius: ${(props) => props.theme.borderRadius};
  transition: all 300ms;
  overflow: hidden;
  width: 100%;
  height: 300px;
  margin-bottom: 30px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    ${(props) => (props.fullMap ? 'width: 100%;' : 'width: 50%;')}
    ${(props) => !props.showingMap && 'width: 0;'}
    ${(props) => (props.showingMap ? 'opacity: 1;' : 'opacity: 0;')}
    height: initial;
    margin-bottom: 0;
  }
`;