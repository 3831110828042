import { QueryObserverOptions, QueryOptions, useMutation } from "react-query";
import { UpdateCompanyMutation, UpdateCompanyMutationVariables, UpdateDeviceShadowMutation, UpdateDeviceShadowMutationVariables } from "../API";
import { useAuth } from "../contexts/Auth";
import { updateCompany, updateDeviceShadow } from "../graphql/mutations";
import API from "../services/classes/API";

type CreateCustomMutationParams = {
    mutation: string;
}

type CustomMutationParams<TQuery, TVariables = any> = {
    variables?: TVariables;
    additionalOptions?: QueryOptions<TQuery> & QueryObserverOptions<TQuery>;
}

type BaseMutationParams<TQuery, TVariables = any> = {
    mutation: string;
    variables: TVariables;
    additionalOptions?: QueryOptions<TQuery> & QueryObserverOptions<TQuery>;
    additionalKeys?: any[];
}

function useBaseMutation<TQuery, TVariables = any>(
    { mutation }: BaseMutationParams<TQuery, TVariables>,
) {
    const { logout } = useAuth();
    return useMutation(mutateFunc);

    async function mutateFunc(variables: TVariables) {
        try {
            const {
                data,
            } = await API.mutate<TVariables, TQuery>(mutation, {
                ...variables
            });

            return data;
        } catch (e: any) {
            if (e.networkError?.statusCode === 401) {
                logout();
            }

            throw new Error(e);
        }
    }
}

export const useUpdateDeviceShadow = createCustomMutation<UpdateDeviceShadowMutation, UpdateDeviceShadowMutationVariables>({
    mutation: updateDeviceShadow,
});

export const useUpdateCompany = createCustomMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>({
    mutation: updateCompany,
})

function createCustomMutation<TMutation, TVariables = any>({ mutation }: CreateCustomMutationParams) {
    return ({ variables, additionalOptions }: CustomMutationParams<TMutation, TVariables> = {}) => useBaseMutation<TMutation, TVariables>(
        { mutation, variables: { ...variables! }, additionalOptions });
}