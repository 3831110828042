// tslint:disable
// this is an auto generated file. This will be overwritten

export const createUser = `mutation CreateUser(
  $email: String!
  $deviceId: String
  $groups: [String]!
) {
  createUser(
    email: $email
    deviceId: $deviceId
    groups: $groups
  )
}
`;
export const enableMfa = `mutation EnableMfa($password: String!) {
  enableMfa(password: $password) {
    success
  }
}
`;
export const disableMfa = `mutation DisableMfa($challengeAnswer: String!) {
  disableMfa(challengeAnswer: $challengeAnswer) {
    success
  }
}
`;
export const importUsers = `mutation ImportUsers($file: String!) {
  importUsers(file: $file) {
    success
  }
}
`;
export const addToGroup = `mutation AddToGroup($id: String!, $username: String!, $group: String!) {
  addToGroup(id: $id, username: $username, group: $group)
}
`;
export const removeFromGroup = `mutation RemoveFromGroup($id: String!, $username: String!, $group: String!) {
  removeFromGroup(id: $id, username: $username, group: $group)
}
`;
export const addDevice = `mutation AddDevice(
  $deviceId: String!
  $groupId: String!
  $meterId: String!
  $latitude: String
  $longitude: String
  $address: String
) {
  addDevice(
    deviceId: $deviceId
    groupId: $groupId
    meterId: $meterId
    latitude: $latitude
    longitude: $longitude
    address: $address
  ) {
    id
    device_group_id
    meterId
    serialNo
    group {
      id
      name
      utility_id
      utility {
        id
        name
        company_id
        company {
          id
          name
          alias
          theme
          logo
          region
          temperatureUnit
          pressureUnit
          volumeUnit
          voltageUnit
          correctedFlowUnit
        }
      }
    }
    user_device {
      user_id
      device_id
      user {
        id
        name
        email
      }
    }
    latitude
    longitude
    address
    messages {
      id
      time
      urgent
      dId
      status
      comment
      alarms {
        lmt
        threshold
        t
        type
        v
      }
    }
    thresholds {
      device_id
      threshold_key
      value
    }
    data {
      ingestionTime
      dTm
      TmZn
      proto
      bV
      ccbbV
      dayCV
      dayUV
      fUV
      fCV
      cFAvg
      maxFC {
        t
        v
      }
      maxPr {
        t
        v
      }
      maxTemp {
        t
        v
      }
      minFC {
        t
        v
      }
      minPr {
        t
        v
      }
      minTemp {
        t
        v
      }
      pofMF
      qMarg
    }
  }
}
`;
export const changeUserDevice = `mutation ChangeUserDevice($userId: String!, $deviceId: String!) {
  changeUserDevice(userId: $userId, deviceId: $deviceId) {
    success
  }
}
`;
export const updateDeviceGroup = `mutation UpdateDeviceGroup(
  $id: String!
  $temperatureUnit: String
  $pressureUnit: String
  $volumeUnit: String
  $voltageUnit: String
  $correctedFlowUnit: String
) {
  updateDeviceGroup(
    id: $id
    temperatureUnit: $temperatureUnit
    pressureUnit: $pressureUnit
    volumeUnit: $volumeUnit
    voltageUnit: $voltageUnit
    correctedFlowUnit: $correctedFlowUnit
  ) {
    success
  }
}
`;
export const updateCompany = `mutation UpdateCompany(
  $id: String!
  $theme: String
  $logo: String
  $type: String
  $temperatureUnit: String
  $pressureUnit: String
  $volumeUnit: String
  $voltageUnit: String
  $correctedFlowUnit: String
) {
  updateCompany(
    id: $id
    theme: $theme
    logo: $logo
    type: $type
    temperatureUnit: $temperatureUnit
    pressureUnit: $pressureUnit
    volumeUnit: $volumeUnit
    voltageUnit: $voltageUnit
    correctedFlowUnit: $correctedFlowUnit
  ) {
    success
  }
}
`;
export const updateAlertStatus = `mutation UpdateAlertStatus($id: String!, $time: Long!, $status: String!) {
  updateAlertStatus(id: $id, time: $time, status: $status) {
    success
  }
}
`;
export const updateAlertComment = `mutation UpdateAlertComment($id: String!, $time: Long!, $status: String!) {
  updateAlertComment(id: $id, time: $time, status: $status) {
    success
  }
}
`;
export const setThresholds = `mutation SetThresholds($deviceIds: [String]!, $thresholds: [ThresholdValue]!) {
  setThresholds(deviceIds: $deviceIds, thresholds: $thresholds) {
    success
  }
}
`;
export const deviceJobManager = `mutation DeviceJobManager(
  $deviceId: String
  $operation: String!
  $region: String
  $version: String
  $files: [Firmware]
  $ca: String
  $component: String
  $deviceIds: [String]
) {
  deviceJobManager(
    deviceId: $deviceId
    operation: $operation
    region: $region
    version: $version
    files: $files
    ca: $ca
    component: $component
    deviceIds: $deviceIds
  ) {
    success
    message
  }
}
`;
export const forgotPassword = `mutation ForgotPassword($username: String!) {
  forgotPassword(username: $username) {
    success
  }
}
`;
export const confirmForgotPassword = `mutation ConfirmForgotPassword(
  $code: String!
  $username: String!
  $password: String!
) {
  confirmForgotPassword(code: $code, username: $username, password: $password) {
    success
  }
}
`;
export const verifyUserEmail = `mutation VerifyUserEmail($code: String!) {
  verifyUserEmail(code: $code) {
    success
  }
}
`;
export const removeUser = `mutation RemoveUser($username: String!) {
  removeUser(username: $username) {
    success
    message
  }
}
`;
export const updateDeviceShadow = `mutation UpdateDeviceShadow(
  $deviceId: String!
  $region: String!
  $payload: String!
) {
  updateDeviceShadow(deviceId: $deviceId, region: $region, payload: $payload) {
    success
  }
}
`;
export const updateAlarmComment = `mutation UpdateAlarmComment(
  $alarmId: String!
  $time: Long!
  $comment: String!
) {
  updateAlarmComment(alarmId: $alarmId, time: $time, comment: $comment) {
    success
  }
}
`;

export const removeDevice = /* GraphQL */ `
  mutation RemoveDevice($deviceId: String!) {
    removeDevice(deviceId: $deviceId) {
      id
    }`;
