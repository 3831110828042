import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import FormGroup from '../../components/Forms/FormGroup/FormGroup';
import { useUnprotectedLayout } from '../../components/UnprotectedRouteLayout/UnprotectedRouteLayout';

import { Title, Subtitle } from './style';

const CheckEmail: React.FC = () => {
  const navigate = useNavigate();
  const { setBackgroundImagePath, setSideImagePath } = useUnprotectedLayout();

  useEffect(() => {
    setSideImagePath!('/images/password-lock.jpg');
    setBackgroundImagePath!('/images/earth.jpg');
  }, []);
  return (
    <>
      <Title>Password Reset</Title>
      <Subtitle>
        Your password has been successfully reset. Click bellow to log in.
      </Subtitle>
      <FormGroup>
        <Button block onClick={() => navigate('/auth/login')}>
          Log in
        </Button>
      </FormGroup>
    </>
  );
};

export default CheckEmail;
