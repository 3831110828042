import styled from 'styled-components';
import Button from '../Button/Button';
import { BiChevronDown } from 'react-icons/bi';

type DropdownPosition = 'top' | 'bottom';

type DropdownContentProps = {
  zIndex: number;
  dropdownPosition?: 'top' | 'bottom';
};

export const DropdownWrapper = styled.div`
  position: relative;
`;

export const DropdownBtn = styled(Button)`
  height: 100%;
`;

const bottomOrTop = (position: DropdownPosition): string => {

  switch (position) {
    case 'top':
      return 'top: 0';
    case 'bottom':
      return 'bottom: 0';
    default:
      return 'top: 0';
  }

}

const translateY = (position: DropdownPosition): string => {

  switch (position) {
    case 'top':
      return '-100%';
    case 'bottom':
      return '100%';
    default:
      return '-100%';
  }

}

export const DropdownContent = styled.div<DropdownContentProps>`
  position: absolute;
  ${props => bottomOrTop(props.dropdownPosition || 'bottom')};
  right: 0;
  transform: translate(0, ${props => translateY(props.dropdownPosition || 'bottom')});
  z-index: ${(props) => props.zIndex};
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: ${(props) => props.theme.borderRadius};
`;

export const DropIcon = styled(BiChevronDown)`
  color: ${(props) => props.theme.colors.primary};
  font-size: 13px;
  margin-left: 13px;
  margin-right: 0 !important;
`;
