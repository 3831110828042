import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  SetThresholdsMutation,
  SetThresholdsMutationVariables,
} from '../../../API';
import { useAuth } from '../../../contexts/Auth';
import { setThresholds } from '../../../graphql/mutations';
import DoubleRange, { DoubleRangeProps } from '../../Forms/DoubleRange/DoubleRange';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import Range from '../../Forms/_Range/Range';
import Modal, { ModalOpeningProps } from '../Modal';
import DateUtils from '../../../utils/DateUtils';

type Props = {
  deviceIds: Array<string | null>;
  defaultValues: ThresholdsFormType;
};

export type ThresholdsFormType = {
  minPr?: string;
  maxPr?: string;
  minFC?: string;
  maxFC?: string;
  minTemp?: string;
  maxTemp?: string;
  bV?: string;
  ccbbV?: string;
  pofMF?: string;
  bL?: string;
};

const SetThresholdsModal: React.FC<ModalOpeningProps & Props> = ({
  closeModalFunc,
  open,
  deviceIds,
  defaultValues,
}) => {
  const { register, getValues, setValue } = useForm<ThresholdsFormType>()

  const [settingThresholds, setSettingThresholds] = useState(false);

  const { signedMutationRequest } = useAuth();

  const formRef = useRef<HTMLFormElement>(null);
  const thresholds = buildThresholdsData();

  const onSetThresholdsSubmit = async () => {
    const data = getValues();

    const dataEntries = Object.entries(data);
    const formattedEntries = dataEntries.map(([key, value]) => {
      return {
        key,
        value : key === 'bL'
          ? DateUtils.monthsToDays(value)
          : value,
      };
    });

    console.log(data);

    setSettingThresholds(true);
    const response = await signedMutationRequest<
      SetThresholdsMutationVariables,
      {
        data: SetThresholdsMutation;
      }
    >(setThresholds, {
      deviceIds: [...deviceIds],
      thresholds: formattedEntries as any,
    });

    setSettingThresholds(false);
    closeModalFunc(false);
  };

  function buildThresholdsData(): DoubleRangeProps[] {
    console.log(defaultValues)

    const data: DoubleRangeProps[] = [
      {
        label: 'Min - Max Pressure',
        name1: 'minPr',
        name2: 'maxPr',
        min: 0,
        max: 2030.53,
        step: 0.01,
        value1: parseFloat(defaultValues.minPr || '0'),
        value2: parseFloat(defaultValues.maxPr || '2030.53'),
      },
      {
        label: 'Min - Max Corrected Flow',
        name1: 'minFC',
        name2: 'maxFC',
        min: 0,
        max: 1920,
        value1: parseFloat(defaultValues.minFC || '0'),
        value2: parseFloat(defaultValues.maxFC || '1920'),
      },
      {
        label: 'Min - Max Temperature',
        name1: 'minTemp',
        name2: 'maxTemp',
        min: 0,
        max: 161.6,
        step: 0.1,
        value1: parseFloat(defaultValues.minTemp || '0'),
        value2: parseFloat(defaultValues.maxTemp || '161.6'),
      },
    ]

    return data;
  }

  useEffect(() => {
    const entries = Object.entries(defaultValues);
    const formattedEntries = entries.map(([key, value]) => {
      return {
        key,
        value,
      };
    });

    for (const entry of formattedEntries) {
      const key = entry.key as keyof ThresholdsFormType;
      const value = entry.value.toString();

      setValue(key, key === 'bL' ? DateUtils.daysToMonths(value).toString() : value)
    }

    if (formRef.current) {
      const inputSliderContainer =
        formRef.current.querySelector<HTMLDivElement>('.slider-container')!;

      formRef
        .current!.querySelectorAll('.range-container')
        .forEach((container) => {
          if (!container) return;

          const input = container.querySelector<HTMLInputElement>('input')!;

          const inputWidth = inputSliderContainer.offsetWidth;
          const inputName = input.name;
          const min = parseInt(input.min);
          const max = parseInt(input.max);
          console.log(input.value)
          const inputValue = formattedEntries.find(
            (entry) => entry.key === inputName
          )?.value || input.value;

          const thumbXPosition =
            ((parseInt(inputValue) - min) / (max - min)) *
            (inputWidth - 12 - 12) +
            12;
          console.log('Looping');
          const thumbContainer =
            container.querySelector<HTMLDivElement>('.thumb-number')!;
          console.log(container);

          thumbContainer.style.left = `${thumbXPosition - 24 / 2}px`;
          thumbContainer.textContent = inputValue;
        });
    }
  }, [defaultValues]);

  return (
    <Modal
      title="Set Thresholds"
      description="Please use the sliders below to set your thresholds."
      onSubmitBtnClick={onSetThresholdsSubmit}
      buttonText="Save Changes"
      buttonLoading={settingThresholds}
      closeModalFunc={closeModalFunc}
      open={open}
    >
      <form ref={formRef}>
        {/* {thresholds.map((threshold) => (
          <FormGroup key={threshold.name1}>
            <DoubleRange
              id1={threshold.name1}
              id2={threshold.name2}
              label={threshold.label}
              step={threshold.step}
              min={threshold.min}
              max={threshold.max}
              name1={threshold.name1}
              name2={threshold.name2}
              value1={threshold.value1}
              value2={threshold.value2}
              register1={register}
              register2={register}
            />
          </FormGroup>
        ))} */}

        {/* <FormGroup>
                <Range
                    id="adEMbatteryVoltage"
                    label="AdEM Battery Voltage"
                    min={0}
                    max={100}
                    value={50}
                    register={register}
                    name="bV"
                />
            </FormGroup>
            <FormGroup>
                <Range
                    id="brightLyncbatteryVoltage"
                    label="BrightLync Battery Voltage"
                    min={0}
                    max={100}
                    value={50}
                    register={register}
                    name="ccbbV"
                />
            </FormGroup> */}
        <FormGroup>
          <Range
            id="minPressure"
            label="Minimum Pressure"
            min={0.00}
            value={defaultValues.minPr ? parseFloat(defaultValues.minPr) : 0.00}
            max={2030.53}
            step={0.01}
            register={register}
            name="minPr"
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="maxPressure"
            label="Max Pressure"
            min={0.00}
            value={defaultValues.maxPr ? parseFloat(defaultValues.maxPr) : 2030.53}
            max={2030.53}
            step={0.01}
            register={register}
            name="maxPr"
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="minCorrectedFlow"
            label="Minimum Corrected Flow"
            min={0}
            value={defaultValues.minFC ? parseFloat(defaultValues.minFC) : 0}
            max={1920}
            register={register}
            name="minFC"
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="maxCorrectedFlow"
            label="Maximum Corrected Flow"
            min={0}
            value={defaultValues.maxFC ? parseFloat(defaultValues.maxFC) : 1920}
            max={1920}
            register={register}
            name="maxFC"
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="minTemperature"
            label="Minimum Temperature"
            min={-40.0}
            value={defaultValues.minTemp ? parseFloat(defaultValues.minTemp) : -40.0}
            max={161.6}
            step={0.1}
            register={register}
            name="minTemp"
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="MaxTemp"
            label="Maximum Temperature"
            min={0.0}
            value={defaultValues.maxTemp ? parseFloat(defaultValues.maxTemp) : 161.6}
            max={161.6}
            step={0.1}
            register={register}
            name="maxTemp"
          />
        </FormGroup>


        <FormGroup>
          <Range
            id="percentageMaxFlow"
            label="Percentage of Max Flow"
            min={0}
            value={defaultValues.pofMF ? parseFloat(defaultValues.pofMF) : 50}
            max={50}
            register={register}
            name="pofMF"
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="batteryLifeMonths"
            label="Remaining Months of Battery Life"
            min={0}
            value={defaultValues.bL ? DateUtils.daysToMonths(defaultValues.bL) : 12}
            max={60}
            register={register}
            name="bL"
          />
        </FormGroup>
      </form>
    </Modal>
  );
};

export default SetThresholdsModal;
