import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Company, GetDeviceGroupsQuery } from '../../../API';
import { useAuth } from '../../../contexts/Auth';
import Radio from '../../Forms/Radio/Radio';
import RadioGroup from '../../Forms/RadioGroup/RadioGroup';
import Modal from '../Modal';
import { updateDeviceGroup } from '../../../graphql/mutations';

type Props = {
  closeModalFunc: (isOpen: boolean) => void;
  open: boolean;
  deviceGroups: GetDeviceGroupsQuery['getDeviceGroups'];
  fetchDeviceGroups: () => void;
}

const EditDeviceGroupSettingsModal: React.FC<Props> = ({ closeModalFunc, open, deviceGroups, fetchDeviceGroups }) => {

  const [updatingDeviceGroup, setUpdatingDeviceGroup] = useState(false)


  const { register, handleSubmit, getValues } =
    useForm<any>();

  const { signedMutationRequest } = useAuth();

  const deviceGroup = deviceGroups[0]

  const setDeviceGroupSettings = async () => {
    const formData = getValues();
    setUpdatingDeviceGroup(true);
    const data = await signedMutationRequest<
      any,
      any
    >(updateDeviceGroup, {
      ...formData,
      id: deviceGroup?.id!,
    });
    closeModalFunc(false);
    fetchDeviceGroups();
    setUpdatingDeviceGroup(false);
  };

  return <Modal title='Edit Device Group Settings' buttonText='Edit Device Group Settings' buttonLoading={updatingDeviceGroup} onSubmitBtnClick={setDeviceGroupSettings} description='' closeModalFunc={closeModalFunc} open={open}>
    <RadioGroup label="Set temperature unit">
      <Radio
        id="F"
        label="F"
        value="F"
        name="temperatureUnit"
        checked={deviceGroup?.temperatureUnit === 'F'}
        register={register}
      />
      <Radio
        id="C"
        value="C"
        label="C"
        name="temperatureUnit"
        register={register}
        checked={deviceGroup?.temperatureUnit === 'C'}
      />
    </RadioGroup>

    <RadioGroup label="Set pressure unit">
      <Radio
        id="KPA"
        label="KPA"
        value="KPA"
        name="pressureUnit"
        register={register}
        checked={deviceGroup?.pressureUnit === 'KPA'}
      />
      <Radio
        id="PSIA"
        value="PSIA"
        label="PSIA"
        name="pressureUnit"
        register={register}
        checked={deviceGroup?.pressureUnit === 'PSIA'}
      />
      <Radio
        id="PSIG"
        value="PSIG"
        label="PSIG"
        name="pressureUnit"
        register={register}
        checked={deviceGroup?.pressureUnit === 'PSIG'}
      />
      <Radio
        id="N/A"
        label="N/A"
        name="pressureUnit"
        register={register}
        checked={
          deviceGroup?.pressureUnit === 'N/A' || !deviceGroup?.pressureUnit
        }
      />
    </RadioGroup>

    <RadioGroup label="Set index read unit">
      <Radio
        id="FT3"
        value="FT3"
        label="FT3"
        name="volumeUnit"
        register={register}
        checked={deviceGroup?.volumeUnit === 'FT3'}
      />
      <Radio
        id="M3"
        value="M3"
        label="M3"
        name="volumeUnit"
        register={register}
        checked={deviceGroup?.volumeUnit === 'M3'}
      />
    </RadioGroup>

    <RadioGroup label="Set flow rate unit">
      <Radio
        id="M3/H"
        value="M3/H"
        label="M3/H"
        name="correctedFlowUnit"
        register={register}
        checked={deviceGroup?.correctedFlowUnit === 'M3/H'}
      />
      <Radio
        id="FT3/H"
        value="FT3/H"
        label="FT3/H"
        name="correctedFlowUnit"
        register={register}
        checked={deviceGroup?.correctedFlowUnit === 'FT3/H'}
      />
    </RadioGroup>

    <RadioGroup label="Set voltage unit">
      <Radio
        id="V"
        value="V"
        label="V"
        name="voltageUnit"
        register={register}
        checked={deviceGroup?.voltageUnit === 'V'}
      />
    </RadioGroup>
  </Modal>;
}

export default EditDeviceGroupSettingsModal;